export enum PartTypecol {
    atw = 'ATW',
    tw = 'TW',
    aar = 'AAR',
    fr = 'FR',
}

export class PartType {
    constructor(
        public readonly partTypecol: PartTypecol,
        public readonly partNumber: string,
        public readonly partTypeName: string,
        public readonly is_valid: Boolean
    ) {}
    static fromJson(json: any): PartType {
        return new PartType(json.part_typecol, json.part_number, json.part_type_name, json.is_valid);
    }
}
