import React from 'react';
import { FlexContainer } from '../layout/Flex';
import { backgroundColor, primary } from '../../style/colors';
import Translation from '../translation/Translation';

export const ProductLogo: React.FC<any> = () => {
    return (
        <a href="/" style={{ display: 'inline-block', textDecoration: 'none', color: backgroundColor }}>
            <FlexContainer alignItems="center" dataTestid="appLogo">
                <h3
                    style={{
                        fontFamily: 'VWAG TheAntiqua',
                        background: primary,
                        zIndex: 2,
                        height: '24px',
                    }}
                >
                    <Translation i18nKey={'application.project'} />
                </h3>
                <div
                    style={{
                        border: '3px solid white',
                        height: '32px',
                        width: '32px',
                        position: 'relative',
                        left: '-21px',
                        zIndex: 1,
                        boxSizing: 'border-box',
                        transform: 'matrix(-1, 0, 0, 1, 0, 0)',
                    }}
                />
                <div
                    style={{
                        background: primary,
                        height: '50px',
                        width: '16px',
                        position: 'relative',
                        left: '-45px',
                        zIndex: 1,
                    }}
                />
                <h3
                    style={{
                        position: 'relative',
                        left: '-30px',
                        fontFamily: 'VWAG TheSans',
                    }}
                >
                    <Translation i18nKey={'application.useCase'} />
                </h3>
            </FlexContainer>
        </a>
    );
};
