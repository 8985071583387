import { Machine } from '../../../domain/models/Machine';
import React, { useState } from 'react';
import { MachineInfo } from './MachineInfo';
import {
    GetMachineProductionStepsResponse,
    IMachineDetailsFilters,
    MachineProductionStep,
} from '../../../domain/services/ProductionPlantsService';
import Breadcrumbs from '../../base/breadcrumbs';
import { GridCol, GridRow } from '../../base/layout/Grid';
import spacing from '../../style/spacing';
import { useTranslation } from 'react-i18next';
import Tabs from '../../base/tab';
import { Container } from '../../base/layout/Container';
import { Body1, H3 } from '../../base/typography/textStyles';
import Translation from '../../base/translation/Translation';
import { MachineDetailsFiltersForm } from './MachineDetailsFiltersForm';
import { ISorting } from '../../../domain/hooks/useMachineProductionSteps';
import { ProductionStepsTable } from './productionSteps/ProductionStepsTable';
import { MachineDetailAnalyses } from './MachineDetailAnalyses';
import Kapp from '../../../domain/models/Kapp';
import { grey800 } from '../../style/colors';
import { Box } from '@mui/material';
import { Tag } from '../../base/filtering/filtersGroups/TextFieldWithTags';
import { GroupuiLoadingSpinner } from '@group-ui/group-ui-react';
import { defaultDates, loadLastTimeFrame, storeLastTimeFrame } from '../../../domain/utils/getDates';

export interface IMachineDetailsProps {
    machine: Machine;
    isLoadingProductionSteps: boolean;
    filters: IMachineDetailsFilters;
    productionStepsPage: GetMachineProductionStepsResponse | null;
    total: number;
    offset: number;
    limit: number;
    onSearchClicked: (filters: IMachineDetailsFilters) => void;
    sorting: ISorting | null;
    setSorting: (column: string) => void;
    onProductionStepClicked: (productionStep: MachineProductionStep) => void;
    onNextPageClicked: () => void;
    onPrevPageClicked: () => void;
    setPage: (page: number) => void;
    kappPage: Kapp | null;
    onKappFilterChange: (tids: string[]) => void;
    kappFilters: IMachineDetailsFilters;
    isKappLoading: boolean;
}

const Header = ({
    machine,
    activeTab,
    setActiveTab,
    productionStepsPage,
    onNextPageClicked,
    onPrevPageClicked,
    setPage,
    hasKappData,
}: {
    machine: Machine;
    activeTab: string;
    setActiveTab: (e: string) => void;
    onNextPageClicked?: () => void;
    onPrevPageClicked?: () => void;
    setPage?: (page: number) => void;
    productionStepsPage?: GetMachineProductionStepsResponse | null;
    hasKappData: boolean;
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Breadcrumbs
                steps={[
                    {
                        label: 'machines.breadcrumbTitle',
                        href: '/machines',
                    },
                    {
                        label: `${machine.nr}`,
                    },
                ]}
            />

            {onNextPageClicked && productionStepsPage && onPrevPageClicked && setPage ? (
                <MachineInfo
                    machine={machine}
                    productionStepsPage={productionStepsPage}
                    onNextPageClicked={onNextPageClicked}
                    onPrevPageClicked={onPrevPageClicked}
                    setPage={setPage}
                />
            ) : (
                <H3 noGutter={true}>
                    <Translation i18nKey="machineDetails.machineNumber" values={{ machineNumber: machine.nr }} />
                </H3>
            )}
            <Tabs
                onGroupuiTabChange={(e) => setActiveTab(e.detail.tab)}
                activeTabId={activeTab}
                noPadding={true}
                tabs={[
                    { id: 'firstTabId', label: t('machineDetails.producedParts') },
                    { id: 'secondTabId', label: t('machineDetails.analysis.title'), disabled: !hasKappData },
                ]}
            ></Tabs>
        </>
    );
};

export const MachineDetails: React.FC<IMachineDetailsProps> = ({
    machine,
    isLoadingProductionSteps,
    productionStepsPage = null,
    filters,
    total,
    offset,
    limit,
    onSearchClicked,
    sorting,
    setSorting,
    onProductionStepClicked,
    onNextPageClicked,
    onPrevPageClicked,
    setPage,
    kappPage,
    onKappFilterChange,
    kappFilters,
    isKappLoading,
}) => {
    const [activeTab, setActiveTab] = useState('firstTabId');

    const tableProps = {
        isLoadingProductionSteps,
        rows: productionStepsPage ? productionStepsPage.productionSteps : [],
        onNextPageClicked,
        onPrevPageClicked,
        total,
        limit,
        offset,
        sorting,
        setSorting,
        setPage,
    };

    const [innerFilters, setInnerFilters] = useState({
        selectedTimeFrame: loadLastTimeFrame(defaultDates.timeFrame),
        ...filters,
    });

    const onFieldValueChange = (fieldName: string) => {
        return (value: any) => {
            const newInnerFilters: any = { ...innerFilters };
            newInnerFilters[fieldName] = value;
            setInnerFilters(newInnerFilters);
            if (fieldName === 'selectedTimeFrame') {
                storeLastTimeFrame(value);
            }
        };
    };

    if (activeTab === 'firstTabId') {
        return (
            <Container>
                <GroupuiLoadingSpinner
                    displayed={isKappLoading}
                    severity="none"
                    background="transparent"
                ></GroupuiLoadingSpinner>

                <GridRow noPadding style={{ minHeight: '100vh' }}>
                    <GridCol xs={3} xl={2} noPadding={true}>
                        <MachineDetailsFiltersForm
                            filters={filters}
                            isLoading={isLoadingProductionSteps}
                            onSearchClicked={onSearchClicked}
                            innerFilters={innerFilters}
                            onFieldValueChange={onFieldValueChange}
                        />
                    </GridCol>
                    <GridCol
                        xs={9}
                        xl={10}
                        style={{
                            paddingLeft: spacing(4),
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '0px',
                        }}
                    >
                        <Header
                            productionStepsPage={productionStepsPage}
                            machine={machine}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            onNextPageClicked={onNextPageClicked}
                            onPrevPageClicked={onPrevPageClicked}
                            setPage={setPage}
                            hasKappData={!!kappPage}
                        />

                        <Body1 noGutter={true} style={{ color: grey800, marginBottom: spacing(1) }}>
                            <Translation i18nKey="machineDetails.selected_tids" />
                        </Body1>
                        <Box>
                            {kappFilters.selectedTids.map((data, index) => {
                                let label = data.split('_')[0];

                                return (
                                    <Tag
                                        data={label}
                                        key={index + data}
                                        handleDelete={() =>
                                            onKappFilterChange(innerFilters.selectedTids.filter((tid) => (tid = data)))
                                        }
                                        margin={spacing(0)}
                                    />
                                );
                            })}
                        </Box>

                        <ProductionStepsTable
                            {...tableProps}
                            isLoading={isLoadingProductionSteps}
                            onTidClicked={onKappFilterChange}
                            tids={kappFilters.selectedTids}
                        />
                    </GridCol>
                </GridRow>
            </Container>
        );
    } else {
        return (
            <Container>
                <Header machine={machine} setActiveTab={setActiveTab} activeTab={activeTab} hasKappData={!!kappPage} />

                {kappPage ? <MachineDetailAnalyses kappPage={kappPage} /> : <></>}
            </Container>
        );
    }
};
