import React from 'react';
import { TableCell, TableRow } from '../../../base/table/Table';
import Translation from '../../../base/translation/Translation';
import ISorting from '../../../../domain/interfaces/ISorting';
import { primary } from '../../../style/colors';
import { Machine } from '../../../../domain/models/Machine';
import { EOLStatus } from '../../eolVerifications/EolVerificationsTable/EolVerificationsTableCells';

export const MachineRow = ({
    machine,
    onClick,
    dataTestid,
}: {
    machine: Machine;
    onClick: () => void;
    dataTestid?: string;
}) => {
    const handleClick = () => {
        let text: string | undefined;
        text = window.getSelection()?.toString();
        if (text !== undefined && text.length > 0) {
            return;
        }
        onClick();
    };

    const machinesWithData = ['9414304', '9413543', '9413542'];

    return (
        <TableRow dataTestid={dataTestid} onClick={handleClick} style={{ cursor: 'pointer' }}>
            <TableCell>{machine.nr}</TableCell>
            <TableCell>{machine.manufacturer}</TableCell>
            <TableCell>{machine.workStep}</TableCell>
            <TableCell alignment="center">
                <EOLStatus status={machinesWithData.includes(machine.nr) ? 1 : 0} tooltipPrefix="machines" />
            </TableCell>
        </TableRow>
    );
};
export const MachineHeader = ({
    sorting,
    setSorting,
}: {
    sorting: ISorting | null;
    setSorting: (column: string) => void;
}) => {
    const getColumnOrder = (column: String) => {
        if (sorting === null || column !== sorting.column) {
            return 'unsorted';
        }
        return sorting.direction === 'asc' ? 'up' : 'down';
    };
    return (
        <>
            <TableRow header style={{ color: primary }}>
                <TableCell
                    sortable={true}
                    order={getColumnOrder('machine_number')}
                    onClick={() => setSorting('machine_number')}
                >
                    <Translation i18nKey="machines.tableRow.nr" />
                </TableCell>
                <TableCell
                    sortable={true}
                    order={getColumnOrder('manufacturer')}
                    onClick={() => setSorting('manufacturer')}
                >
                    <Translation i18nKey="machines.tableRow.manufacturer" />
                </TableCell>
                <TableCell sortable={true} order={getColumnOrder('work_step')} onClick={() => setSorting('work_step')}>
                    <Translation i18nKey="machines.tableRow.workStep" />
                </TableCell>
                <TableCell alignment="center">
                    <Translation i18nKey="machines.tableRow.analyzable" />
                </TableCell>
            </TableRow>
        </>
    );
};
