import React from 'react';
import EOLVerification from '../../../domain/models/EOLVerification';
import { Part } from '../../../domain/models/Part';
import { Product } from '../../../domain/models/Product';
import { Container } from '../../base/layout/Container';
import { Divider } from '../../base/layout/Divider';
import { GridCol, GridRow } from '../../base/layout/Grid';
import Translation from '../../base/translation/Translation';
import { Body1, H3 } from '../../base/typography/textStyles';
import { toDateString, toDateTimeString } from '../../base/utils';
import spacing from '../../style/spacing';
import { ComponentsList } from './ComponentsList';
import { EOLVerificationsList } from './EOLVerificationsList';
import Breadcrumbs from '../../base/breadcrumbs';
import { FlexContainer } from '../../base/layout/Flex';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Header = ({ product }: { product: Product }) => {
    const { t } = useTranslation();
    return (
        <>
            <Breadcrumbs
                steps={[
                    {
                        label: 'eolVerifications.breadcrumbTitle',
                        href: '/eolVerifications',
                    },
                    {
                        label: t('eolVerifications.productIdBreadcrumb', { product_pid: product.pid }),
                    },
                ]}
            />
            <FlexContainer alignItems="baseline">
                <H3 noGutter={true}>
                    <Translation i18nKey="productDetails.pid" values={{ pid: product.pid }} />
                </H3>

                {product.date && (
                    <Body1 noGutter={true} style={{ marginLeft: spacing(2) }}>
                        <Translation
                            i18nKey="productDetails.produced"
                            values={{ date: toDateString(moment(product.date)) }}
                        />
                    </Body1>
                )}

                <Body1 style={{ marginLeft: spacing(2) }}>
                    <Translation
                        i18nKey="productDetails.lastUpdate"
                        values={{ timestamp: toDateTimeString(moment(product.etlTimestamp)) }}
                    />
                </Body1>
            </FlexContainer>

            <Divider style={{ marginBottom: spacing(3), marginTop: spacing(3) }} />
        </>
    );
};

export interface IProductDetailsProps {
    product: Product;
    verifications: EOLVerification[] | [];
    onPartClicked?: (part: Part) => void;
    selectedVerificationId?: string;
}

const ProductDetails: React.FC<IProductDetailsProps> = ({
    product,
    verifications,
    selectedVerificationId = undefined,
}) => (
    <Container>
        <Header product={product} />
        <GridRow justifyContent="space-between" noPadding>
            <GridCol noPadding s={8} style={{ display: 'flex', flexDirection: 'column' }}>
                <EOLVerificationsList verifications={verifications} selectedVerificationId={selectedVerificationId} />
            </GridCol>
            <GridCol s={4} style={{ paddingLeft: spacing(3) }}>
                <ComponentsList pid={product.pid} partHistory={product.partHistory} />
            </GridCol>
        </GridRow>
    </Container>
);
export default ProductDetails;
