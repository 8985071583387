import { Part } from '../../../domain/models/Part';
import Translation from '../../base/translation/Translation';
import React from 'react';
import { ProductionStep } from '../../../domain/models/ProductionStep';
import { Table, TableCell, TableRow } from '../../base/table/Table';
import { Machine } from '../../../domain/models/Machine';
import { primary } from '../../style/colors';
import Card from '../../base/Card';
import { GroupuiLink } from '@group-ui/group-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const ProductionStepTableRow = ({
    idx,
    total,
    part,
    step,
}: {
    idx: number;
    total: number;
    part: Part;
    step: ProductionStep;
}) => {
    const toDateTime = (timestamp: Date) => `${timestamp.toLocaleDateString()}, ${timestamp.toLocaleTimeString()}`;
    const getMachineRedirectUrl = (machine: Machine, step: ProductionStep, partIdOnMachine: string) => {
        const fromDate = new Date(step.timestamp);
        fromDate.setMinutes(0, 0, 0);

        const toDate = moment(new Date(step.timestamp)).add(1, 'hour').toDate();
        toDate.setMinutes(0, 0, 0);

        return `/machines/${
            machine.nr
        }?date_from=${fromDate.toISOString()}&date_to=${toDate.toISOString()}&tid=${partIdOnMachine}`;
    };
    return (
        <TableRow>
            <TableCell>{part.part_type_name}</TableCell>
            <TableCell>{toDateTime(step.timestamp)}</TableCell>
            <TableCell>
                <Link style={{ textDecoration: 'none' }} to={getMachineRedirectUrl(step.machine, step, part.tid)}>
                    <GroupuiLink>{step.machine.nr}</GroupuiLink>
                </Link>
            </TableCell>
            <TableCell>{step.machine.manufacturer}</TableCell>
            <TableCell>{step.machine.workStep}</TableCell>
        </TableRow>
    );
};

const ProductionStepTableHeader = () => (
    <TableRow style={{ color: primary }}>
        <TableCell>
            <Translation i18nKey="partDetails.eventsLog.partType" />
        </TableCell>
        <TableCell>
            <Translation i18nKey="partDetails.eventsLog.date" />
        </TableCell>
        <TableCell>
            <Translation i18nKey="partDetails.eventsLog.machine" />
        </TableCell>
        <TableCell>
            <Translation i18nKey="partDetails.eventsLog.manufacturer" />
        </TableCell>
        <TableCell>
            <Translation i18nKey="partDetails.eventsLog.workStep" />
        </TableCell>
    </TableRow>
);

// TODO: Add the events from the subcomponents and merge them in the timeline
export const PartEventsLog = ({ part, partChildren = [] }: { part: Part; partChildren?: Part[] }) => {
    const toStepWithPart = (part: Part, step: ProductionStep) => ({
        part: part,
        step: step,
    });

    const toStepsWithPart = (part: Part) => part.steps.map((step) => toStepWithPart(part, step));

    const steps = toStepsWithPart(part);
    partChildren.forEach((child) => {
        steps.unshift(...toStepsWithPart(child));
    });

    return (
        <Card height={'100%'}>
            <Table columns={5} header={true}>
                <ProductionStepTableHeader />
                {steps
                    .filter((step) => step.step.derivedFromLabel === 0)
                    .filter(
                        (step) =>
                            step.step.source === 'zenon' ||
                            (step.step.source !== 'zenon' &&
                                !steps.some(
                                    (step2) =>
                                        step2.step.machine.nr === step.step.machine.nr && step2.step.source === 'zenon'
                                ))
                    )
                    .sort((a, b) => (a.step.timestamp > b.step.timestamp ? 1 : -1))
                    .map((step, idx) => (
                        <ProductionStepTableRow
                            idx={idx}
                            total={steps.length}
                            step={step.step}
                            part={step.part}
                            key={idx}
                        />
                    ))}
            </Table>
        </Card>
    );
};
