import React from 'react';
import { INumberOfEOLVerificationsSpread } from '../../../../domain/services/EngineService';
import spacing from '../../../style/spacing';
import { Input } from '../../inputs/Input';
import { FlexContainer } from '../../layout/Flex';
import { FiltersGroup } from './FiltersGroup';

interface INumberOfEOLVerificationsFilterGroupProps {
    numberOfEOLVerificationsSpread: INumberOfEOLVerificationsSpread;
    onNumberOfEOLVerificationsChange: (newValue: INumberOfEOLVerificationsSpread) => void;
}

export const NumberOfEOLVerificationsFilterGroup: React.FC<INumberOfEOLVerificationsFilterGroupProps> = ({
    numberOfEOLVerificationsSpread,
    onNumberOfEOLVerificationsChange,
}) => {
    const handleChange = (event: string, min_max: string) => {
        let value: string | null = event.length === 0 ? null : event;
        onNumberOfEOLVerificationsChange({ ...numberOfEOLVerificationsSpread, [min_max]: value });
    };

    return (
        <FiltersGroup title="enginesFilter.eolVerifications.title">
            <FlexContainer>
                <Input
                    style={{ marginRight: spacing(3) }}
                    onValueChange={(e) => handleChange(e, 'min')}
                    value={numberOfEOLVerificationsSpread?.min ? numberOfEOLVerificationsSpread.min.toString() : ''}
                    placeholder="min"
                    type="number"
                    dataTestid="verifications-min-input"
                />
                <Input
                    onValueChange={(e) => handleChange(e, 'max')}
                    value={numberOfEOLVerificationsSpread?.max ? numberOfEOLVerificationsSpread.max.toString() : ''}
                    placeholder="max"
                    type="number"
                    dataTestid="verifications-max-input"
                />
            </FlexContainer>
        </FiltersGroup>
    );
};
