import IOthersProps from '../IOthersProps';
import { IDataTestProps } from '../IDataTestProps';
import React from 'react';
import { GroupuiInput } from '@group-ui/group-ui-react';
import { emptyCallback } from '../utils';
import IStyledProps from '../IStyledProps';

// Todo: Make sure to remove this whenever GroupUI exposes the props through the module.
export interface GroupUIInputProxyProps {
    /**
     * description of the input-field, only shown if __variant__ is not set
     */
    description?: string;

    /**
     * label of the input-field
     */
    label?: string;

    /**
     * sets input-field to readonly
     */
    immutable?: boolean;

    /**
     * a pattern which will be covered by validity
     */
    pattern?: string;
}

export interface IInputProps extends GroupUIInputProxyProps, IOthersProps, IDataTestProps, IStyledProps {
    /**
     * Called when the value changes,
     * the value is passed as parameter
     * @param value
     */
    onValueChange?: (value: any) => void;

    /**
     * Called when the HTML 'change' event happens,
     * the event is passed as parameter
     * @param evt
     */
    onChange?: (evt: any) => void;

    /**
     * value of the input-field
     */
    value?: string;

    /**
     * name of the input element to support forms
     */
    name?: string;

    /**
     * sets the maximum amount of characters of the input-field
     */
    maxlength?: number;

    /**
     * placeholder of the input-field<br> ❗❗ applicable only to: VWAG, MAN
     */
    placeholder?: string;

    /**
     * specifies that an input must be filled out before submitting the form
     */
    required?: boolean;

    disabled?: boolean;
}

export const Input: React.FC<IInputProps> = ({
    onValueChange = emptyCallback,
    onChange = emptyCallback,
    dataTestid = '',
    style = {},
    ...others
}) => {
    const onInput = (evt: any) => {
        const newValue = evt.target.value;
        onValueChange(newValue);
        onChange(evt);
    };

    return (
        <div style={{ marginBottom: '10px', ...style }}>
            <GroupuiInput data-testid={dataTestid} onInput={onInput} {...others} />
        </div>
    );
};
