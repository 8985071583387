import { GroupuiHeadline, GroupuiText } from '@group-ui/group-ui-react';
import React from 'react';
import IStyledProps from '../IStyledProps';
import IChildrenProps from '../IChildrenProps';
import spacing from '../../style/spacing';
import { IDataTestProps } from '../IDataTestProps';
import { grey800 } from '../../style/colors';

type Weight = 'regular' | 'light' | 'bold' | 'italic';

type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2' | 'caption';

interface ITypography extends IStyledProps, IChildrenProps, IDataTestProps {
    variant?: TypographyVariant;

    serif?: boolean;

    weight?: Weight;

    noGutter?: boolean;

    paragraph?: boolean;
}

const Typography: React.FC<ITypography> = ({ noGutter = false, variant = 'h1', dataTestid = undefined, ...props }) => {
    const groupUIProps = {
        style: {
            marginBottom: noGutter ? '0px' : spacing(2),
            ...props.style,
        },
        heading: variant,
        ...props,
    };

    if (variant === 'body1' || variant === 'body2' || variant === 'caption') {
        return (
            <GroupuiText data-testid={dataTestid} size={variant} {...groupUIProps}>
                {props.children}
            </GroupuiText>
        );
    }

    return (
        <GroupuiHeadline data-testid={dataTestid} {...groupUIProps} style={{ color: grey800, ...groupUIProps.style }}>
            {props.children}
        </GroupuiHeadline>
    );
};

export const H1 = (props: ITypography) => <Typography variant="h1" weight="regular" {...props} />;

export const H2 = (props: ITypography) => <Typography variant="h2" weight="regular" {...props} />;

export const H3 = (props: ITypography) => <Typography variant="h3" weight="regular" {...props} />;

export const H4 = (props: ITypography) => <Typography variant="h4" weight="regular" {...props} />;

export const H5 = (props: ITypography) => <Typography variant="h5" weight="regular" {...props} />;

export const H6 = (props: ITypography) => <Typography variant="h6" weight="regular" {...props} />;

export const FilterHeader = ({ style = {}, ...props }) => (
    <p
        style={{
            fontSize: '0.875rem',
            fontWeight: 'bold',
            marginBottom: spacing(0.5),
            ...style,
        }}
        {...props}
    />
);

export const Body1 = (props: ITypography) => <Typography variant="body1" {...props} />;
export const Body2 = (props: ITypography) => <Typography variant="body2" {...props} />;
export const Caption = (props: ITypography) => <Typography variant="caption" {...props} />;
