import { Button } from '../../base/buttons/Button';
import React from 'react';
import { FlexContainer } from '../../base/layout/Flex';
import { Body1, H2, H4 } from '../../base/typography/textStyles';
import spacing from '../../style/spacing';
import { BarsProgress } from '../../base/loaders/BarsProgress';
import logo from './volkswagenag-grey.svg';
import Translation from '../../base/translation/Translation';
import { GroupuiLink } from '@group-ui/group-ui-react';
import { HELP_WIKI_URL } from '../../../domain/config/baseUrls';

export interface ILoginProps {
    loading?: boolean;
    onSignInClicked: () => void;
}

const Login: React.FC<ILoginProps> = ({ loading = false, onSignInClicked }) => {
    return (
        <FlexContainer direction="column" alignItems="center" style={{ height: '100vh', paddingTop: spacing(10) }}>
            <img src={logo} style={{ width: '300px', marginBottom: spacing(4) }} alt="Volkswagen Aktiengesellshaft" />

            <H2 noGutter={true}>EOL Verifications App</H2>
            {loading ? (
                <H4>
                    <Translation i18nKey="login.loading" />
                </H4>
            ) : (
                <H4>
                    <Translation i18nKey="login.welcomeBack" />
                </H4>
            )}
            <FlexContainer style={{ marginTop: spacing(4), minWidth: '300px' }} justifyContent="center">
                {loading ? (
                    <BarsProgress />
                ) : (
                    <div style={{ minWidth: '300px' }}>
                        <Button dataTestid="loginButton" fullwidth={true} onClick={onSignInClicked}>
                            <Translation i18nKey="global.logIn" />
                        </Button>
                    </div>
                )}
            </FlexContainer>
            <FlexContainer style={{ marginTop: spacing(4), minWidth: '300px' }} justifyContent="center">
                <Body1 noGutter={true}>
                    <GroupuiLink href={HELP_WIKI_URL}>
                        <Translation i18nKey="global.helpLogin" />
                    </GroupuiLink>
                </Body1>
            </FlexContainer>
        </FlexContainer>
    );
};

export default Login;
