import React from 'react';
import { GroupuiButton } from '@group-ui/group-ui-react';

import IOthersProps from '../IOthersProps';
import { IDataTestProps } from '../IDataTestProps';
import { ISizeProps } from '../ISizeProps';
import IClickableProps from '../IClickableProps';

export enum Type {
    button = 'button',
    submit = 'submit',
    reset = 'reset',
}

export enum Alignment {
    left = 'left',
    center = 'center',
    right = 'right',
}

type Variant = 'primary' | 'secondary' | 'tertiary';

// Todo: Make sure to remove this whenever GroupUI exposes the props through the module.
interface GroupUIButtonProxyProps {
    /**
     * deactivates the button
     */
    disabled?: boolean;
    /**
     * sets the button to fullwidth
     */
    fullwidth?: boolean;

    /**
     * activates an inverted presentation<br> ❗❗ applicable only to: VWAG, Audi
     */
    inverted?: boolean;

    /**
     * type of the button.<br><br> _Note:_ If the button is placed inside a form and the type is either 'reset' or 'submit' then it acts like a native reset/submit-button. Keep in mind that at the moment no groupui component will actively listen for these form-events. You have to listen for these events by yourself.
     */
    type?: Type;

    /**
     * variant of the button
     */
    variant?: Variant;

    /**
     * sets the alignment in the button
     */
    alignment?: Alignment;
}

export interface IButtonProps
    extends GroupUIButtonProxyProps,
        ISizeProps,
        IClickableProps,
        IOthersProps,
        IDataTestProps {}

export const Button: React.FC<IButtonProps> = ({ dataTestid = '', ...props }) => {
    // @ts-ignore TODO: Remove when Groupui exposes the types for the props
    return <GroupuiButton {...props} data-testid={dataTestid} />;
};
