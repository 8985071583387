import React, { CSSProperties, useState } from 'react';
import EOLVerification from '../../../domain/models/EOLVerification';
import { IconButton } from '../../base/buttons/IconButton';
import { ArrowDownIcon, ArrowUpIcon, CorrectIcon, ErrorIcon, WarningIcon } from '../../base/icons/icons';
import { FlexContainer, FlexGrow } from '../../base/layout/Flex';
import { Table, TableCell, TableRow } from '../../base/table/Table';
import Translation from '../../base/translation/Translation';
import { Body1, Caption, H3 } from '../../base/typography/textStyles';
import { toDateTimeString } from '../../base/utils';
import { black, negative, positive } from '../../style/colors';
import spacing from '../../style/spacing';
import Card from '../../base/Card';
import IStyledProps from '../../base/IStyledProps';
import { Trans } from 'react-i18next';
import { Border, ListItem, Size } from '../../base/lists/ListItem';
import moment from 'moment';

export const ErrorsTable = ({ verification }: { verification: EOLVerification }) => {
    if (verification.errors.length === 0) {
        return (
            <div
                style={{
                    marginLeft: spacing(1),
                    paddingBottom: spacing(2),
                }}
            >
                <Body1>
                    <Translation i18nKey="productDetails.noErrorsInThisVerification" />
                </Body1>
            </div>
        );
    }

    const columnsCount = 12;
    const widths = [];
    for (let i = 0; i < columnsCount; i++) {
        widths.push('auto');
    }

    const errorTextWidth = 300;

    widths[2] = `${errorTextWidth}px`;
    widths[3] = '100px';

    const textWrapStyle: CSSProperties = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        width: `${errorTextWidth * 0.9}px`,
        whiteSpace: 'nowrap',
    };

    return (
        <div style={{ overflowX: 'auto', marginTop: spacing(2) }}>
            <Table
                columns={columnsCount}
                header={true}
                size="s"
                scrollX={true}
                widths={widths.join(' ')}
                style={{ padding: 0, margin: 0 }}
            >
                <TableRow header>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.errorCode" />
                    </TableCell>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.errorNr" />
                    </TableCell>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.errorText" />
                    </TableCell>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.state" />
                    </TableCell>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.instrument" />
                    </TableCell>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.location" />
                    </TableCell>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.parameter" />
                    </TableCell>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.channel" />
                    </TableCell>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.position" />
                    </TableCell>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.value" />
                    </TableCell>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.limit" />
                    </TableCell>
                    <TableCell>
                        <Translation i18nKey="productDetails.tableHeader.unit" />
                    </TableCell>
                </TableRow>
                {verification.errors.map((error, idx) => (
                    <TableRow size="small" key={idx}>
                        <TableCell>{error.error_type_number_specific}</TableCell>
                        <TableCell>
                            {idx + 1}/{verification.errors.length}
                        </TableCell>
                        <TableCell>
                            <div style={textWrapStyle}>{error.error_type_text}</div>
                        </TableCell>
                        <TableCell>{error.mode_name}</TableCell>
                        <TableCell>{error.instrument_name}</TableCell>
                        <TableCell>{error.location_name}</TableCell>
                        <TableCell>{error.parameter_name}</TableCell>
                        <TableCell>{error.channel_name}</TableCell>
                        <TableCell>{error.error_position}</TableCell>
                        <TableCell>{error.error_value}</TableCell>
                        <TableCell>{error.error_limit}</TableCell>
                        <TableCell>{error.unit_name}</TableCell>
                    </TableRow>
                ))}
            </Table>
        </div>
    );
};

export const EOLVerificationListItem = ({
    verification,
    selected = false,
    startOpen = false,
}: {
    verification: EOLVerification;
    selected?: boolean;
    startOpen?: boolean;
}) => {
    const [open, setOpen] = useState(startOpen || selected);
    const leftMarginStyle = { marginLeft: spacing(2), display: 'flex' };
    return (
        <ListItem key={verification.id} size={Size.s} border={open ? Border.none : Border.bottom}>
            <FlexContainer justifyContent="flex-start" alignItems="center" fullWidth={true}>
                <Body1 style={leftMarginStyle} weight={'bold'} noGutter={true}>
                    {verification.productType}
                </Body1>

                <Body1 weight={'bold'} style={leftMarginStyle} noGutter={true}>
                    {toDateTimeString(moment(verification.date))}
                </Body1>

                <Body1 weight={'bold'} style={leftMarginStyle} noGutter={true}>
                    <Trans
                        i18nKey="productDetails.eolVerificationsList.station"
                        values={{ stationName: verification.station }}
                    />
                </Body1>

                <StatusBadge style={leftMarginStyle} status={verification.status} />
                {verification.errors.length > 0 && (
                    <div style={leftMarginStyle}>
                        <WarningIcon style={{ color: negative }} />
                        &nbsp;
                        <Body1 noGutter={true}>
                            <Trans
                                i18nKey="productDetails.eolVerificationsList.errorsCount"
                                values={{ errorsCount: verification.errors.length }}
                            />
                        </Body1>
                    </div>
                )}

                <FlexGrow flexGrow={1}>
                    {verification.errors.length > 0 && (
                        <FlexContainer alignItems="center" justifyContent="flex-end" fullWidth={true}>
                            <IconButton style={leftMarginStyle} onClick={() => setOpen(!open)} size={Size.l}>
                                {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                            </IconButton>
                        </FlexContainer>
                    )}
                </FlexGrow>
            </FlexContainer>
            {open && <ErrorsTable verification={verification} />}
        </ListItem>
    );
};

interface IStatusBadgeProps extends IStyledProps {
    status: number;
}

export const StatusBadge: React.FC<IStatusBadgeProps> = ({ status, style }) => {
    if (status === 0) {
        return (
            <>
                <ErrorIcon style={{ ...style, color: negative }} />
                &nbsp;
                <Body1 noGutter={true}>
                    <Trans i18nKey="productDetails.eolVerificationsList.nio" />
                </Body1>
            </>
        );
    }
    if (status === 1) {
        return (
            <>
                <CorrectIcon style={{ ...style, color: positive }} />
                &nbsp;
                <Body1 noGutter={true}>
                    <Trans i18nKey="productDetails.eolVerificationsList.io" />
                </Body1>
            </>
        );
    } else {
        return (
            <>
                <ErrorIcon style={{ ...style, color: black }} />
                &nbsp;
                <Body1 noGutter={true}>
                    <Trans i18nKey="productDetails.eolVerificationsList.unknown" />
                </Body1>
            </>
        );
    }
};

const EmptyVerificationsList = () => (
    <FlexContainer alignItems="center" justifyContent="center" fullWidth={true} style={{ minHeight: '500px' }}>
        <Translation i18nKey="productDetails.noVerificationsYet" />
    </FlexContainer>
);

const Title = ({
    lastVerificationStatus,
    verificationsNumber,
}: {
    lastVerificationStatus: number;
    verificationsNumber: number;
}) => (
    <>
        <FlexContainer alignItems={'center'}>
            <H3 noGutter style={{ display: 'flex' }}>
                <Translation i18nKey="productDetails.verification" />
                &nbsp;
            </H3>
            <StatusBadge status={lastVerificationStatus} />
        </FlexContainer>
        {verificationsNumber > 0 ? (
            <Caption>
                <Translation i18nKey="productDetails.latestAttemptOutOf" values={{ howMany: verificationsNumber }} />
            </Caption>
        ) : (
            <Caption />
        )}
    </>
);

export const EOLVerificationsList = ({
    selectedVerificationId = undefined,
    verifications = [],
}: {
    selectedVerificationId?: string;
    verifications: EOLVerification[];
}) => {
    const lastVerificationStatus = verifications.length > 0 ? verifications[0].status : -1;
    const verificationsNumber = verifications.length;

    if (verifications.length === 0) {
        return (
            <>
                <Title lastVerificationStatus={lastVerificationStatus} verificationsNumber={verificationsNumber} />
                <EmptyVerificationsList />
            </>
        );
    }

    return (
        <>
            <Title lastVerificationStatus={lastVerificationStatus} verificationsNumber={verificationsNumber} />
            <Card height="100%">
                {verifications.map((verification, index) => (
                    <EOLVerificationListItem verification={verification} key={index} />
                ))}
            </Card>
        </>
    );
};
