import React, { useEffect, useState } from 'react';
import spacing from '../../../style/spacing';
import { Checkbox } from '../../inputs/Checkbox';
import { FlexContainer } from '../../layout/Flex';
import { List } from '../../lists/List';
import { ListItem, Size } from '../../lists/ListItem';
import Translation from '../../translation/Translation';
import { Body1 } from '../../typography/textStyles';
import { FiltersGroup } from './FiltersGroup';
import Tag from '../../tag';
import { Input } from '../../inputs/Input';
import { useTranslation } from 'react-i18next';
import {
    EOLVerificationsCountByProductTypeDTO,
    EOLVerificationsProductDistributionStats,
} from '../../../../domain/models/stats/EOLVerificationsStats';
import { machineLearningEngineType } from '../../../../domain/config/values';

interface IProductTypesFiltersGroupProps {
    productDistributionStats: EOLVerificationsProductDistributionStats | null;
    selectedProductTypes: string[];
    onSelectedProductTypesChanged: (selectedProductTypes: string[]) => void;
}

const SelectableListItem: React.FC<any> = ({ selected, children, onClick }) => (
    <ListItem size={Size.s} onClick={onClick} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
        <FlexContainer>{children}</FlexContainer>
    </ListItem>
);

const ProductTypesFiltersGroup: React.FC<IProductTypesFiltersGroupProps> = ({
    productDistributionStats,
    selectedProductTypes,
    onSelectedProductTypesChanged,
}) => {
    const addEngineTypeIfSelectedButZeroResults = (
        stats: EOLVerificationsProductDistributionStats | null,
        engineTypes: string[]
    ) => {
        if (stats === null) return [];
        let arr: EOLVerificationsCountByProductTypeDTO[] = stats.byType;
        for (let i = 0; i < engineTypes.length; i++) {
            let found = false;
            for (let j = 0; j < arr.length; j++) {
                if (arr[j].type === engineTypes[i]) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                arr.push(new EOLVerificationsCountByProductTypeDTO(0, engineTypes[i]));
            }
        }
        return arr;
    };
    const productTypes: EOLVerificationsCountByProductTypeDTO[] = addEngineTypeIfSelectedButZeroResults(
        productDistributionStats,
        selectedProductTypes
    );

    const { t } = useTranslation();
    const [productTypeSearch, setProductTypeSearch] = useState<string | undefined>(undefined);

    const onProductTypeSearchInputChange = (productTypeSearch: string) => {
        setProductTypeSearch(productTypeSearch);
    };

    const onProductTypeClicked = (productType: EOLVerificationsCountByProductTypeDTO, value: boolean) => {
        if (value) {
            const newSelectedProductTypes = [...selectedProductTypes, productType.type];
            onSelectedProductTypesChanged(newSelectedProductTypes);
        } else {
            const newSelectedProductTypes = selectedProductTypes.filter((pType) => pType !== productType.type);
            onSelectedProductTypesChanged(newSelectedProductTypes);
            if (productType.type !== machineLearningEngineType) setSelectAll(false);
        }
    };

    const [selectAll, setSelectAll] = useState(true);
    const allProductTypesClicked = (selectAll: boolean) => {
        if (selectAll) {
            setSelectAll(false);
            onSelectedProductTypesChanged([]);
        } else {
            setSelectAll(true);
            onSelectedProductTypesChanged(
                productDistributionStats
                    ? productDistributionStats?.byType
                          .map((engineType) => engineType.type)
                          .filter(
                              (engineType) =>
                                  engineType !== machineLearningEngineType ||
                                  selectedProductTypes.includes(machineLearningEngineType)
                          )
                          .map(String)
                    : []
            );
        }
    };

    useEffect(() => {
        allProductTypesClicked(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const productCountByType = (type: string): number | null => {
        if (productTypes === undefined) return null;
        const value = productTypes.find((stat) => stat.type === type)?.total;
        return value !== undefined ? value : null;
    };

    const allProductsCount = (): number | null => {
        if (productDistributionStats === null) return null;
        const value = productDistributionStats.total;
        return value !== undefined ? value : null;
    };

    const total = allProductsCount();

    const filteredProductTypes = productTypes?.filter((productType) => {
        if (productTypeSearch === undefined) {
            return true;
        }
        return productType.type.toLowerCase().includes(productTypeSearch?.toLowerCase());
    });

    const sortedProductTypes = [
        ...filteredProductTypes.filter(({ type }) => type !== machineLearningEngineType),
        ...filteredProductTypes.filter(({ type }) => type === machineLearningEngineType),
    ];

    return (
        <FiltersGroup title="productsFilter.type.productType">
            <Input
                value={productTypeSearch}
                onValueChange={onProductTypeSearchInputChange}
                type="text"
                icon="search-24"
                iconPosition="left"
                placeholder={t('home.filter.searchProductPlaceholder')}
            />
            <List>
                <SelectableListItem selected={selectAll} onClick={() => allProductTypesClicked(selectAll)}>
                    <Checkbox noGutter={true} checked={selectAll} key={`all-${selectAll}`} />
                    <Body1 noGutter={true} style={{ marginLeft: spacing(1), marginRight: spacing(2) }}>
                        <Translation i18nKey={'home.ProductsTypeDistribution.allEngineTypes'} />
                    </Body1>
                    <Tag dataTestid={`Home.Filters.ProductTypeAllCount`}>{total}</Tag>
                </SelectableListItem>

                {sortedProductTypes.map((productTyp, idx) => {
                    const selected = selectedProductTypes.includes(productTyp.type);
                    const count = productCountByType(productTyp.type);
                    return (
                        <SelectableListItem
                            key={`${idx}-${selected}`}
                            selected={selected}
                            onClick={() => onProductTypeClicked(productTyp, !selected)}
                        >
                            <Checkbox
                                dataTestid={`Home.Filters.ProductType${productTyp.type}CheckBox`}
                                noGutter={true}
                                checked={selected}
                                key={`${idx}-${selected}`}
                            />
                            <Body1
                                dataTestid={`Home.Filters.ProductType${productTyp.type}`}
                                noGutter={true}
                                style={{ marginLeft: spacing(1), marginRight: spacing(2) }}
                            >
                                {productTyp.type}
                            </Body1>
                            <Tag dataTestid={`Home.Filters.ProductType${productTyp.type}Count`}>
                                {count ? count : 0}
                            </Tag>
                        </SelectableListItem>
                    );
                })}
            </List>
        </FiltersGroup>
    );
};

export default ProductTypesFiltersGroup;
