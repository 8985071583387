import { useEffect, useState } from 'react';
import { EolErrorResponse, EolErrorService } from '../services/EolErrorService';

export const useEolErrors = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<EolErrorResponse[]>([]);

    useEffect(() => {
        setLoading(true);
        if (data.length === 0) {
            const eolErrorService = new EolErrorService();
            eolErrorService.getUniqueErrorValues().then((response) => {
                setData(response);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [data.length]);

    return { loading, data };
};
