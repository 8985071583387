import React from 'react';
import { IButtonProps } from './Button';
import './IconButton.css';
import { ISizeProps } from '../ISizeProps';
import IStyledProps from '../IStyledProps';

export interface IIconButtonProps extends IButtonProps, ISizeProps, IStyledProps {
    children?: any;
    onClick?: (evt?: any) => void;
}

export const IconButton: React.FC<IIconButtonProps> = ({
    children = null,
    size = 'm',
    style = {},
    onClick = () => {},

    ...props
}) => {
    const width: any = {
        xs: '8px',
        s: '16px',
        text: '1.5rem',
        m: '24px',
        l: '32px',
        xl: '64px',
    };

    const height: any = {
        xs: '8px',
        s: '16px',
        text: '1.5rem',
        m: '24px',
        l: '32px',
        xl: '64px',
    };

    return (
        <div
            onClick={onClick}
            style={{
                width: width[size],
                height: height[size],
                borderRadius: width[size],
            }}
            className="IconButton"
            {...props}
        >
            {children}
        </div>
    );
};
