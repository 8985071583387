import { useEffect, useState } from 'react';
import { Machine } from '../models/Machine';
import { IMachinesFilter } from '../services/MachinesService';
import ProductionPlantsService from '../services/ProductionPlantsService';

export interface ISorting {
    column: String | null;
    direction: String | null;
}
export interface UseMachinesState {
    machines: Machine[] | null;
    filters: IMachinesFilter;
    offset: number;
    limit: number;
    total: number;
    loading: boolean;
    sorting: ISorting | null;
    getNextPage: () => void;
    getPreviousPage: () => void;
    setFilters: (filters: IMachinesFilter) => void;
    setSorting: (sort: ISorting) => void;
    setPage: (page: number) => void;
}

/**
 * Custom hook
 * Downloads a page of Parts given the filters, offset and limit.
 * Returns handlers for navigating between the pages and updating the filters.
 * @param initialFilters
 * @param initialOffset
 * @param limit
 * @param contextId
 */

export const useMachines: (
    initialFilters: IMachinesFilter,
    currentSorting: ISorting | null,
    initialOffset?: number,
    limit?: number,
    contextId?: string
) => UseMachinesState = (
    initialFilters: IMachinesFilter,
    currentSorting: ISorting | null,
    initialOffset: number = 0,
    limit: number = 20
) => {
    const [offset, setOffset] = useState(initialOffset);
    const [total, setTotal] = useState(0);
    const [machines, setMachines] = useState<Machine[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [filters, innerSetFilters] = useState(initialFilters);
    const [sorting, setSortingProps] = useState<ISorting | null>(currentSorting);

    useEffect(() => {
        setLoading(true);
        const productionPlantsService = new ProductionPlantsService();
        productionPlantsService.getMachines(filters, offset, limit, sorting).then((response) => {
            setTotal(response.total);
            setMachines(response.machines);
            setLoading(false);
        });
    }, [offset, limit, filters, sorting]);

    const getNextPage = () => {
        setOffset(offset + limit);
    };

    const getPreviousPage = () => {
        setOffset(Math.max(offset - limit, 0));
    };

    const setFilters = (filters: IMachinesFilter) => {
        innerSetFilters(filters);
        setOffset(0);
    };

    const setSorting = (sort: ISorting) => {
        setSortingProps(sort);
    };

    const setPage = (page: number) => {
        setOffset((page - 1) * limit);
    };

    return {
        machines,
        filters,
        offset,
        limit,
        total,
        loading,
        sorting,
        getNextPage,
        getPreviousPage,
        setFilters,
        setSorting,
        setPage,
    };
};
