import React from 'react';
import { useTranslation } from 'react-i18next';
import { CorrectIcon, ErrorIcon } from '../../../base/icons/icons';
import Tooltip from '../../../base/tooltip/tooltip';
import { negative, positive, warning } from '../../../style/colors';

export const EnginesStatus = ({ status }: { status: number }) => {
    const { t } = useTranslation();

    if (status === 2) {
        return (
            <Tooltip tooltipMessage={t('engines.tooltip.neutral')}>
                <CorrectIcon style={{ color: warning }} />
            </Tooltip>
        );
    }
    if (status === 1) {
        return (
            <Tooltip tooltipMessage={t('engines.tooltip.success')}>
                <CorrectIcon style={{ color: positive }} />
            </Tooltip>
        );
    }
    if (status === 0) {
        return (
            <Tooltip tooltipMessage={t('engines.tooltip.failed')}>
                <ErrorIcon style={{ color: negative }} />
            </Tooltip>
        );
    } else {
        return (
            <Tooltip tooltipMessage={t('engines.tooltip.undefined')}>
                <ErrorIcon style={{ color: 'black' }} />
            </Tooltip>
        );
    }
};
