import { MenuItem, Select } from '@mui/material';
import { TFunction as TranslateFunction } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeFrame } from '../../../../domain/utils/timeFrames';
import spacing from '../../../style/spacing';
import { DatePicker } from '../../inputs/DatePicker';
import { TimePicker } from '../../inputs/TimePicker';
import IStyledProps from '../../IStyledProps';
import { FlexContainer } from '../../layout/Flex';
import Translation from '../../translation/Translation';
import { Body1, Caption, H5, FilterHeader } from '../../typography/textStyles';
import {
    loadTimezoneFromAppSettings,
    toDateString,
    toDateStringGerman,
    toDateTimeStringGerman,
    toTimeString,
} from '../../utils';
import moment from 'moment';

interface ITimeFramesSelectProps extends IStyledProps {
    onSelectChange: (evt: any) => void;
    timeFrame: TimeFrame;
    timeFrames: any;
    t: TranslateFunction;
    title: string;
    disabled?: boolean;
}

const TimeFramesSelect: React.FC<ITimeFramesSelectProps> = ({
    onSelectChange,
    timeFrame,
    t,
    timeFrames,
    style,
    title,
    disabled = false,
}) => {
    return (
        <div style={style}>
            <FilterHeader>
                <Translation i18nKey={title} />
            </FilterHeader>

            <Select
                style={{ height: '40px' }}
                disabled={disabled}
                fullWidth={true}
                native={false}
                variant={'outlined'}
                onChange={onSelectChange}
                renderValue={(value) => (
                    <FlexContainer alignItems="center">
                        <Body1 noGutter={true}>{t(timeFrames[value as string]?.name)}</Body1>
                    </FlexContainer>
                )}
                value={timeFrame.value}
            >
                {Object.keys(timeFrames).map((key) => (
                    <MenuItem value={key} key={key}>
                        <H5>{t(timeFrames[key].name)}</H5>
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

interface ICustomDatesPicker extends IStyledProps {
    onTimeFrameUpdate: (customTimeFrame: TimeFrame) => void;
    timeFrame: TimeFrame;
    t: TranslateFunction;
    hasCustomTime: boolean;
    disabled?: boolean;
}

const CustomTimeFramePicker: React.FC<ICustomDatesPicker> = ({
    onTimeFrameUpdate,
    timeFrame,
    t,
    style,
    hasCustomTime,
    disabled = false,
}) => {
    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
    const [toDate, setToDate] = useState<moment.Moment | null>(null);
    return (
        <div style={{ marginBottom: spacing(3) }}>
            <DatePicker
                disabled={disabled}
                dataTestid={'EolVerificationsFiltersForm-ProductionDate-FromDatePicker'}
                label={t('productsFilter.date.from')}
                value={toDateString(moment(timeFrame.fromDate).tz(loadTimezoneFromAppSettings().value))}
                maxDate={toDateStringGerman(
                    new Date(Math.min(toDate?.unix()! * 1000 || new Date().getTime(), new Date().getTime()))
                )}
                onDateChange={(newDateValue: string) => {
                    const newDateFromUTC = moment.tz(
                        newDateValue +
                            ' ' +
                            toTimeString(moment(timeFrame.fromDate).tz(loadTimezoneFromAppSettings().value)),
                        'YYYY-MM-DD HH:mm',
                        loadTimezoneFromAppSettings().value
                    );

                    newDateFromUTC !== null && onTimeFrameUpdate(new TimeFrame(newDateFromUTC.utc(), timeFrame.toDate));
                    setFromDate(newDateFromUTC);
                }}
            >
                <span slot="label">
                    <Translation i18nKey={'datePicker.from'} />
                </span>
            </DatePicker>
            {hasCustomTime ? (
                <TimePicker
                    disabled={disabled}
                    data-testid={'EolVerificationsFiltersForm-ProductionDate-FromTimePicker'}
                    value={toTimeString(moment(timeFrame.fromDate).tz(loadTimezoneFromAppSettings().value))}
                    onValueChange={(newTimeValue: string) => {
                        const newDateFromUTC = moment.tz(
                            toDateString(moment(timeFrame.fromDate).tz(loadTimezoneFromAppSettings().value)) +
                                ' ' +
                                newTimeValue,
                            'YYYY-MM-DD HH:mm',
                            loadTimezoneFromAppSettings().value
                        );
                        newDateFromUTC !== null &&
                            onTimeFrameUpdate(new TimeFrame(newDateFromUTC.utc(), timeFrame.toDate));
                    }}
                />
            ) : (
                <> </>
            )}

            <DatePicker
                disabled={disabled}
                style={{ marginTop: spacing(2) }}
                data-testid={'EolVerificationsFiltersForm-ProductionDate-ToDatePicker'}
                label={t('productsFilter.date.to')}
                value={toDateString(moment(timeFrame.toDate).tz(loadTimezoneFromAppSettings().value))}
                minDate={toDateStringGerman(fromDate)}
                maxDate={toDateStringGerman(new Date())}
                onDateChange={(newDateValue: string) => {
                    const newDateToUTC = moment.tz(
                        newDateValue +
                            ' ' +
                            toTimeString(moment(timeFrame.toDate).tz(loadTimezoneFromAppSettings().value)),
                        'YYYY-MM-DD HH:mm',
                        loadTimezoneFromAppSettings().value
                    );
                    newDateToUTC !== null && onTimeFrameUpdate(new TimeFrame(timeFrame.fromDate, newDateToUTC.utc()));
                    setToDate(newDateToUTC);
                }}
            >
                <span slot="label">
                    <Translation i18nKey={'datePicker.to'} />
                </span>
            </DatePicker>
            {hasCustomTime ? (
                <TimePicker
                    disabled={disabled}
                    data-testid={'EolVerificationsFiltersForm-ProductionDate-ToTimePicker'}
                    value={toTimeString(moment(timeFrame.toDate).tz(loadTimezoneFromAppSettings().value))}
                    onValueChange={(newTimeValue: string) => {
                        const newDateToUTC = moment.tz(
                            toDateString(moment(timeFrame.toDate).tz(loadTimezoneFromAppSettings().value)) +
                                ' ' +
                                newTimeValue,
                            'YYYY-MM-DD HH:mm',
                            loadTimezoneFromAppSettings().value
                        );

                        newDateToUTC !== null &&
                            onTimeFrameUpdate(new TimeFrame(timeFrame.fromDate, newDateToUTC.utc()));
                    }}
                />
            ) : (
                <> </>
            )}
        </div>
    );
};

interface IDateIntervalFiltersGroupProps {
    label: string;
    onTimeFrameChange: (timeFrame: TimeFrame) => void;
    timeFrame: TimeFrame;
    timeFrames: any;
    hasCustomTime: boolean;
    disabled?: boolean;
}

const DateIntervalFiltersGroup: React.FC<IDateIntervalFiltersGroupProps> = ({
    label,
    timeFrame,
    timeFrames,
    onTimeFrameChange,
    hasCustomTime,
    disabled = false,
}) => {
    const { t } = useTranslation();
    const [isCustom, setIsCustom] = useState(timeFrame.name === 'timeFrames.custom');

    const onSelectChange = (evt: any) => {
        if (evt.target.value !== 'custom') {
            setIsCustom(false);
            onTimeFrameChange(timeFrames[evt.target.value as string]);
        } else {
            setIsCustom(true);
            const newTimeFrame = new TimeFrame(timeFrame.fromDate, timeFrame.toDate);
            onTimeFrameChange(newTimeFrame);
        }
    };

    const onCustomTimeFrameUpdate = (newCustomTimeFrame: TimeFrame) => {
        if (isCustom) {
            onTimeFrameChange(newCustomTimeFrame);
        }
    };
    return (
        <>
            <div style={{ marginBottom: spacing(3) }}>
                <TimeFramesSelect
                    onSelectChange={onSelectChange}
                    timeFrame={timeFrame}
                    timeFrames={timeFrames}
                    t={t}
                    title={label}
                    disabled={disabled}
                />

                {!isCustom && (
                    <div>
                        <Caption>
                            <Translation
                                i18nKey={'timeFrames.timeInterval'}
                                values={{
                                    fromDate: toDateTimeStringGerman(timeFrame.fromDate),
                                    toDate: toDateTimeStringGerman(timeFrame.toDate),
                                }}
                            />
                        </Caption>
                    </div>
                )}
            </div>

            {isCustom && (
                <CustomTimeFramePicker
                    disabled={disabled}
                    t={t}
                    style={{ marginTop: spacing(1) }}
                    onTimeFrameUpdate={onCustomTimeFrameUpdate}
                    timeFrame={timeFrame}
                    hasCustomTime={hasCustomTime}
                />
            )}
        </>
    );
};

export default DateIntervalFiltersGroup;
