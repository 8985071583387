import { useEffect, useState } from 'react';
import KappService from '../services/MachinesService';
import Kapp from '../models/Kapp';
import { IMachineDetailsFilters } from '../services/ProductionPlantsService';

export interface useKappState {
    kappPage: Kapp | null;
    filters: IMachineDetailsFilters;
    loading: boolean;
    setFilters: (filters: IMachineDetailsFilters) => void;
}

/**
 * Custom hook
 * Downloads a page of products given the filters, offset and limit.
 * Returns handlers for navigating between the pages and updating the filters.
 * @param initialFilters
 * @param contextId
 */

export const useKapp: (
    initialFilters: IMachineDetailsFilters,
    machineNumber: string,
    contextId?: string
) => useKappState = (initialFilters: IMachineDetailsFilters, initalMachineNumber: string) => {
    const [kappPage, setKappPage] = useState<Kapp | null>(null);
    const [loading, setLoading] = useState(false);
    const [filters, innerSetFilters] = useState(initialFilters);
    const machineNumber = initalMachineNumber;

    useEffect(() => {
        setLoading(true);
        new KappService().getKappData(filters, machineNumber).then((response) => {
            setKappPage(response.kapp);
            setLoading(false);
        });
    }, [filters, machineNumber]);

    const setFilters = (filters: IMachineDetailsFilters) => {
        innerSetFilters(filters);
    };

    return {
        kappPage,
        filters,
        loading,
        setFilters,
    };
};
