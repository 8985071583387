import { ReactFlowUiElement } from './ReactFlowUiElement';

export class UiContainer {
    constructor(public uiElements: ReactFlowUiElement[]) {}

    static fromJson(json: any): UiContainer {
        let uiElements = [];
        if (json.uiElements) {
            uiElements = json.uiElements.map((elements: ReactFlowUiElement) => ReactFlowUiElement.fromJson(elements));
        }
        return new UiContainer(uiElements);
    }
}
