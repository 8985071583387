import { useEffect, useState } from 'react';
import { PartType } from '../models/PartType';
import PartsService from '../services/PartsService';

export interface UsePartTypesState {
    partTypes: PartType[];
    loading: boolean;
    error: Error | null;
}

/**
 * Custom hook
 * Downloads the list of part types
 */
export const usePartTypes: () => UsePartTypesState = () => {
    const [partTypes, setPartTypes] = useState<PartType[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        const partService = new PartsService();
        partService
            .getPartTypes()
            .then((response) => {
                setPartTypes(response);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return {
        partTypes,
        loading,
        error,
    };
};
