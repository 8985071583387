import React, { useEffect, useMemo, useState } from 'react';
import AuthService, { User } from '../../../domain/security/AuthService';
import Login from './Login';
import UnAuthorized from './UnAuthorized';
import { useNavigate, useLocation } from 'react-router-dom';

export const UserContext = React.createContext<User | null>(null);

const WithAuthorization: React.FC<any> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    // useMemo prevents useEffect to be called continuously due to the authService dependency
    const authService = useMemo(() => new AuthService(), []);

    useEffect(() => {
        authService.onAuthChange(
            (user: User) => {
                setUser(user);
                setLoading(false);
            },
            () => {
                setUser(null);
            },
            (route: string) => {
                navigate(route);
            }
        );

        authService
            .getCurrentAuthenticatedUser()
            .then((user: User) => {
                setUser(user);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [authService, navigate]);

    const onSignInClicked = () => {
        const route = location.pathname + location.search;
        setLoading(true);
        // 'vw-stable-login-kums'
        authService.startOauthSignIn(route).then();
    };

    // The user is not logged in
    if (user === null) {
        return <Login onSignInClicked={onSignInClicked} loading={loading} />;
    }

    const onSignOutClicked = () => {
        authService.oAuthSignOut();
    };

    // The user is not authorized to see the application
    if (!user.isAuthorizedUser) {
        return <UnAuthorized onSignOutClicked={onSignOutClicked} />;
    }

    // The user is logged in and authorized, then return whatever got passed as children
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default WithAuthorization;
