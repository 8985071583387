export class Machine {
    constructor(
        public nr: string,
        public workStep: string,
        public manufacturer: string,
        public source?: string,
        public numberOfSections?: number[]
    ) {}

    static fromJson(json: any): Machine {
        return new Machine(
            json.nr || json.machine_number,
            json.work_step,
            json.manufacturer,
            json.source ? json.source : null,
            json.number_of_sections ? json.number_of_sections : null
        );
    }
}
