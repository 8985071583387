import React from 'react';
import Translation from '../translation/Translation';
import { GroupuiTopNavigation, GroupuiTopNavigationItem } from '@group-ui/group-ui-react';
import { useLocation, Link } from 'react-router-dom';

const NavigationBar: React.FC = () => {
    const location = useLocation();

    const menu = [
        {
            id: 'home',
            active: location.pathname === '/',
            route: '/',
            label: 'sideMenu.home',
        },
        {
            id: 'verifications',
            active:
                location.pathname === '/eolVerifications' ||
                location.pathname.includes('/verification') ||
                (location.pathname.includes('/products') && !location.pathname.includes('/parts')),
            route: '/eolVerifications',
            label: 'sideMenu.eolVerifications',
        },
        {
            id: 'engine',
            active: location.pathname === '/engines',
            route: '/engines',
            label: 'sideMenu.engines',
        },
        {
            id: 'machines',
            active: location.pathname.startsWith('/machines'),
            route: '/machines',
            label: 'sideMenu.machines',
        },
        {
            id: 'parts',
            active: location.pathname === '/parts' || location.pathname.includes('/parts'),
            route: '/parts',
            label: 'sideMenu.parts',
        },
    ];

    return (
        <GroupuiTopNavigation size="l">
            {menu.map((item) => (
                <Link to={item.route} style={{ textDecoration: 'none' }}>
                    <GroupuiTopNavigationItem key={item.id} active={item.active}>
                        <Translation i18nKey={item.label} />
                    </GroupuiTopNavigationItem>
                </Link>
            ))}
        </GroupuiTopNavigation>
    );
};

export default NavigationBar;
