import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Kapp from '../../../domain/models/Kapp';
import Card from '../../base/Card';
import { Checkbox } from '../../base/inputs/Checkbox';
import { GridCol, GridRow } from '../../base/layout/Grid';
import Tabs from '../../base/tab';
import Translation from '../../base/translation/Translation';
import { Caption, H3, H4 } from '../../base/typography/textStyles';
import { greyDividerColor, primary } from '../../style/colors';
import spacing from '../../style/spacing';
import { AdditionalInformation } from './AdditionalInformation';
import { EChartsMultipleBar } from '../../base/diagrams/multipleBarChart';
import Box from '@mui/material/Box';

interface IMachineDetailAnalysesProps {
    kappPage: Kapp;
}
interface IStepExplanationProps {
    text: string;
    boxStyle: any;
    checked: boolean;
    color: string;
    setChecked: (value: boolean) => void;
}

const StepExplanation = ({ boxStyle = {}, text, checked, setChecked, color }: IStepExplanationProps) => {
    const explanationTextStyle = {
        marginLeft: spacing(2),
        maxWidth: '280px',
    };

    const explanationRowStyle = {
        width: '380px',
    };

    return (
        <GridRow noPadding={true} alignItems="flex-start" style={explanationRowStyle}>
            {/* <StepBox style={boxStyle} /> */}
            <GridCol xs={8} xl={1} noPadding={true}>
                <Checkbox checked={checked} onClick={() => setChecked(!checked)}></Checkbox>
            </GridCol>
            <GridCol xs={8} xl={10} noPadding={true}>
                <Caption style={explanationTextStyle}>{text}</Caption>
            </GridCol>
            <GridCol xs={8} xl={1} noPadding={true}>
                <Box sx={{ width: 24, height: 24, backgroundColor: color, marginLeft: 1 }} />
            </GridCol>
        </GridRow>
    );
};

const Header = ({ activeTab, setActiveTab }: { activeTab: string; setActiveTab: (e: string) => void }) => {
    const { t } = useTranslation();

    return (
        <div style={{ marginLeft: spacing(1) }}>
            <Tabs
                onGroupuiTabChange={(e) => setActiveTab(e.detail.tab)}
                activeTabId={activeTab}
                noPadding={true}
                tabs={[
                    { id: 'firstTabId', label: t('machineDetails.analysis.common.title') },
                    { id: 'secondTabId', label: t('machineDetails.analysis.workpiece.title') },
                    { id: 'thirdTabId', label: t('machineDetails.analysis.technology_tooling.title') },
                    { id: 'forthTabId', label: t('machineDetails.analysis.technology_process.title') },
                    { id: 'fifthsTabId', label: t('machineDetails.analysis.corrections.title') },
                ]}
            ></Tabs>
        </div>
    );
};

export const MachineDetailAnalyses: React.FC<IMachineDetailAnalysesProps> = ({ kappPage }) => {
    const [activeTab, setActiveTab] = useState('firstTabId');
    const [orderAnalysisIndex, setOrderAnalysisIndex] = useState<number>(0);
    const [isMeanChecked, setMeanChecked] = useState<boolean>(true);
    const [isMaxChecked, setMaxChecked] = useState<boolean>(true);

    const handleChange = (event: SelectChangeEvent) => {
        setOrderAnalysisIndex(event.target.value as unknown as number);
    };

    const { t } = useTranslation();
    const channelNumberMapping: { [key: string]: string } = {
        '0': 'WS-Achse',
        '1': 'WS-Achse',
        '2': 'WS-Achse',
        '4': 'WZ-Achse',
    };

    const channelTypeMapping: { [key: string]: string } = {
        AE: 'Drehzahl',
        IEPE: 'Festlager',
    };

    return (
        <>
            <GridRow xs={6} xl={6}>
                <Caption>
                    <Translation
                        i18nKey="machineDetails.analysis.machine_type"
                        values={{ machine_type: kappPage.source }}
                    />
                </Caption>
                <Caption style={{ marginLeft: spacing(1) }}>
                    <Translation i18nKey="machineDetails.analysis.machine_tid" values={{ tid: kappPage.tid }} />
                </Caption>
                <Caption style={{ marginLeft: spacing(1) }}>
                    <Translation
                        i18nKey="machineDetails.analysis.version_plc"
                        values={{ version_plc: kappPage.common.version_plc }}
                    />
                </Caption>
                <Caption style={{ marginLeft: spacing(1) }}>
                    <Translation
                        i18nKey="machineDetails.analysis.last_change"
                        values={{ last_change: kappPage.common.last_change }}
                    />
                </Caption>
                <Caption style={{ marginLeft: spacing(1) }}>
                    <Translation i18nKey="machineDetails.analysis.ip" values={{ ip: kappPage.common.ip_adress }} />
                </Caption>
                <Caption style={{ marginLeft: spacing(1) }}>
                    <Translation
                        i18nKey="machineDetails.analysis.access_level"
                        values={{ access_level: kappPage.common.accesslevel }}
                    />
                </Caption>
            </GridRow>

            <Card style={{ marginBottom: spacing(3) }}>
                <GridRow>
                    <GridCol xs={9} xl={8} noPadding={true}>
                        <H4 style={{ marginLeft: spacing(1) }} noGutter={true}>
                            <Translation i18nKey="machineDetails.analysis.frequency_analysis" />
                        </H4>
                    </GridCol>
                    <GridCol xs={3} xl={4} noPadding={true}>
                        <H4 style={{ marginLeft: spacing(3) }}>
                            <Translation
                                i18nKey="machineDetails.analysis.machineNumber"
                                values={{ machineNumber: kappPage.machine }}
                            />
                        </H4>
                    </GridCol>
                </GridRow>
                <GridRow style={{ marginTop: spacing(2) }}>
                    <GridCol xs={9} xl={8} noPadding={true}>
                        <EChartsMultipleBar
                            isMeanChecked={isMeanChecked}
                            isMaxChecked={isMaxChecked}
                            orderAnalysisIndex={orderAnalysisIndex}
                            data={[kappPage]}
                            style={{ height: '300px' }}
                        ></EChartsMultipleBar>
                    </GridCol>
                    <GridCol xs={3} xl={4} noPadding={true}>
                        <div style={{ marginLeft: spacing(3) }}>
                            <StepExplanation
                                checked={isMeanChecked}
                                setChecked={setMeanChecked}
                                text={t('machineDetails.analysis.mean_amplitude')}
                                boxStyle={{ backgroundColor: primary }}
                                color="#80B0C8"
                            />
                            <StepExplanation
                                checked={isMaxChecked}
                                setChecked={setMaxChecked}
                                text={t('machineDetails.analysis.max_amplitude')}
                                boxStyle={{ backgroundColor: greyDividerColor }}
                                color="#BDC88A"
                            />
                        </div>
                        <div style={{ marginLeft: spacing(3), marginTop: spacing(1) }}>
                            <Caption weight="bold" style={{ color: 'primary' }}>
                                <Translation i18nKey="machineDetails.analysis.channel" />
                            </Caption>
                            <FormControl variant="standard" fullWidth={true}>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={orderAnalysisIndex as unknown as string}
                                    onChange={handleChange}
                                    variant="outlined"
                                    style={{ fontSize: 12, color: 'grey' }}
                                >
                                    {kappPage.order_analysis.map((orderAnalysis, indx) => (
                                        <MenuItem style={{ fontSize: 12, color: 'grey' }} value={indx}>{`${
                                            channelTypeMapping[orderAnalysis.channel_type.toString()]
                                        } ${channelNumberMapping[orderAnalysis.channel.toString()]} (${
                                            orderAnalysis.channel_type
                                        } ch${orderAnalysis.channel})`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <GridRow>
                            <GridCol xs={5} xl={5}>
                                <Caption>
                                    <Translation i18nKey="machineDetails.analysis.channel_type" />
                                </Caption>
                                <Caption>
                                    <Translation i18nKey="machineDetails.analysis.base_frequenz" />
                                </Caption>
                                <Caption>
                                    <Translation i18nKey="machineDetails.analysis.filename_rawdata" />
                                </Caption>
                            </GridCol>
                            <GridCol xs={7} xl={7}>
                                <Caption> {kappPage.order_analysis[orderAnalysisIndex].channel_type} </Caption>
                                <Caption> {kappPage.order_analysis[orderAnalysisIndex].base_frequency} </Caption>
                                <Caption> {kappPage.order_analysis[orderAnalysisIndex].filename} </Caption>
                            </GridCol>
                        </GridRow>
                    </GridCol>
                </GridRow>
            </Card>

            <Card>
                <H3 style={{ marginLeft: spacing(1), marginBottom: spacing(1) }} noGutter={true}>
                    <Translation i18nKey="machineDetails.analysis.general_data" />
                </H3>
                <Header activeTab={activeTab} setActiveTab={setActiveTab} />
                <AdditionalInformation activeTab={activeTab} kappPage={kappPage} />
            </Card>
        </>
    );
};
