import React from 'react';
import { useTranslation } from 'react-i18next';
import { CorrectIcon, ErrorIcon } from '../../../base/icons/icons';
import Tooltip from '../../../base/tooltip/tooltip';
import { negative, positive } from '../../../style/colors';

export const EOLStatus = ({ status, tooltipPrefix }: { status: number; tooltipPrefix: string }) => {
    const { t } = useTranslation();

    if (status === 0) {
        return (
            <Tooltip tooltipMessage={t(`${tooltipPrefix}.tooltip.failed`)}>
                <ErrorIcon style={{ color: negative }} />
            </Tooltip>
        );
    }
    if (status === 1) {
        return (
            <Tooltip tooltipMessage={t(`${tooltipPrefix}.tooltip.success`)}>
                <CorrectIcon style={{ color: positive }} />
            </Tooltip>
        );
    } else {
        return (
            <Tooltip tooltipMessage={t(`${tooltipPrefix}.tooltip.undefined`)}>
                <ErrorIcon style={{ color: 'black' }} />
            </Tooltip>
        );
    }
};
