import { Button } from '../../base/buttons/Button';
import React from 'react';
import { FlexContainer } from '../../base/layout/Flex';
import { Body1, H3 } from '../../base/typography/textStyles';
import spacing from '../../style/spacing';
import logo from './volkswagenag-grey.svg';
import Translation from '../../base/translation/Translation';
import { GroupuiLink } from '@group-ui/group-ui-react';
import { HELP_WIKI_URL } from '../../../domain/config/baseUrls';

interface ILoginProps {
    onSignOutClicked: () => void;
}

const UnAuthorized: React.FC<ILoginProps> = ({ onSignOutClicked }) => {
    return (
        <FlexContainer direction="column" alignItems="center" style={{ height: '100vh', paddingTop: spacing(10) }}>
            <img src={logo} style={{ width: '300px', marginBottom: spacing(4) }} alt="Volkswagen Aktiengesellshaft" />

            <H3 noGutter={true}>
                <Translation i18nKey="unauthorized.message" />
            </H3>
            <Body1 noGutter={true}>
                <Translation i18nKey="unauthorized.explanation" />
            </Body1>

            <FlexContainer style={{ marginTop: spacing(4), minWidth: '300px' }} justifyContent="center">
                <div style={{ minWidth: '300px' }}>
                    <Button fullwidth={true} onClick={onSignOutClicked}>
                        <Translation i18nKey="global.logOut" />
                    </Button>
                </div>
            </FlexContainer>
            <FlexContainer style={{ marginTop: spacing(4), minWidth: '300px' }} justifyContent="center">
                <Body1 noGutter={true}>
                    <GroupuiLink href={HELP_WIKI_URL}>
                        <Translation i18nKey="global.helpLogin" />
                    </GroupuiLink>
                </Body1>
            </FlexContainer>
        </FlexContainer>
    );
};

export default UnAuthorized;
