import React from 'react';
import { TableCell, TableRow } from '../../../base/table/Table';
import Translation from '../../../base/translation/Translation';
import { toDateStringGerman } from '../../../base/utils';
import ISorting from '../../../../domain/interfaces/ISorting';
import { primary } from '../../../style/colors';
import { FilledInfoIcon, LinkIcon } from '../../../base/icons/icons';
import Tooltip from '../../../base/tooltip/tooltip';
import { useTranslation } from 'react-i18next';
import { Part } from '../../../../domain/models/Part';
import spacing from '../../../style/spacing';
import { Link } from 'react-router-dom';
import { SZ_ONLINE_URL } from '../../../../domain/config/baseUrls';

export const PartRow = ({ part, onClick, dataTestid }: { part: Part; onClick: () => void; dataTestid?: string }) => {
    const { t } = useTranslation();

    const handleClick = () => {
        let text: string | undefined;
        text = window.getSelection()?.toString();
        if (text !== undefined && text.length > 0) {
            return;
        }
        onClick();
    };

    const onPartClicked = (part: Part) => {
        if (['Rotor', 'Stator'].includes(part.part_type_name)) {
            return `${SZ_ONLINE_URL}/part/${encodeURIComponent(part.tid)}`;
        } else {
            return `/products/${part.current_pid}/parts/${part.tid}`;
        }
    };

    return (
        <TableRow dataTestid={dataTestid} onClick={handleClick} style={{ cursor: 'pointer' }}>
            <TableCell>{part.tid}</TableCell>
            <TableCell>{part.part_type_name}</TableCell>
            <TableCell>{toDateStringGerman(part.production_date)}</TableCell>
            <TableCell alignment="center">
                <Tooltip tooltipMessage={t('parts.linktip')}>
                    <Link
                        to={onPartClicked(part)}
                        target={['Rotor', 'Stator'].includes(part.part_type_name) ? '_blank' : ''}
                    >
                        <LinkIcon style={{ color: primary }} />
                    </Link>
                </Tooltip>
            </TableCell>
            <TableCell>
                <div style={{ float: 'left', marginRight: spacing(2) }}>{part.current_pid}</div>

                {part.pids.length >= 2 || (part.pids.length >= 1 && part.current_pid == null) ? (
                    <div style={{ overflow: 'hidden' }}>
                        {
                            <Tooltip
                                variant="secondary"
                                style={{ whiteSpace: 'pre-wrap' }}
                                tooltipMessage={`${
                                    part.current_pid == null
                                        ? t('parts.wasInAnOldEngine', {
                                              elements: '\n\u2022 ' + [part.pids.join('\n\u2022 ')],
                                          })
                                        : t('parts.morePidsTip', {
                                              elements:
                                                  '\nPID: ' +
                                                  [part.pids.filter((pid) => pid !== part.current_pid).join('\nPID: ')],
                                          })
                                }`}
                            >
                                <FilledInfoIcon style={{ color: primary }} />
                            </Tooltip>
                        }
                    </div>
                ) : (
                    <></>
                )}
            </TableCell>
        </TableRow>
    );
};
export const PartHeader = ({
    sorting,
    setSorting,
}: {
    sorting: ISorting | null;
    setSorting: (column: string) => void;
}) => {
    const getColumnOrder = (column: String) => {
        if (sorting === null || column !== sorting.column) {
            return 'unsorted';
        }
        return sorting.direction === 'asc' ? 'up' : 'down';
    };
    return (
        <>
            <TableRow header style={{ color: primary }}>
                <TableCell sortable={true} order={getColumnOrder('tid')} onClick={() => setSorting('tid')}>
                    <Translation i18nKey="parts.tid" />
                </TableCell>
                <TableCell sortable={true} order={getColumnOrder('part_type')} onClick={() => setSorting('part_type')}>
                    <Translation i18nKey="parts.type" />
                </TableCell>
                <TableCell
                    sortable={true}
                    order={getColumnOrder('production_date')}
                    onClick={() => setSorting('production_date')}
                >
                    <Translation i18nKey="parts.date" />
                </TableCell>
                <TableCell alignment="center">
                    <Translation i18nKey="parts.link" />
                </TableCell>
                <TableCell>
                    <Translation i18nKey="parts.pid" />
                </TableCell>
            </TableRow>
        </>
    );
};
