import React from 'react';
import { IInputProps, Input } from './Input';
import { toTimeString } from '../utils';

export interface ITimePickerProps extends IInputProps {}

export const TimePicker: React.FC<ITimePickerProps> = ({ value = '', ...props }) => {
    const formatValue: (value: string | Date | null) => string = (value: string | Date | null): string => {
        if (value == null) return '';

        if (value instanceof Date) {
            return toTimeString(value);
        } else {
            return value;
        }
    };

    return <Input value={formatValue(value)} type="time" {...props} />;
};
