import { EOLVerificationsStats } from '../../../../domain/models/stats/EOLVerificationsStats';
import { useTranslation } from 'react-i18next';
import { StatistikCard } from './StatistikCard';

export const EOLVerificationTop5Errors = ({
    stats,
    getEolErrorTypeUrl,
}: {
    stats: EOLVerificationsStats;
    getEolErrorTypeUrl: (eolErrorCode: number) => string;
}) => {
    const { t } = useTranslation();

    return (
        <StatistikCard
            title="home.EOLVerificationEolErrors.top5Errors"
            subTitle="home.EOLVerificationEolErrors.top5ErrorsExplanation"
            pieData={stats.failedByErrorType.map((stat) => ({
                name: `Fehler ${stat.errorTypeCode}`,
                subName: stat.errorTypeText,
                value: stat.total,
                redirectUrl: getEolErrorTypeUrl(stat.errorTypeCode as unknown as number),
                toolTipText: t('home.EOLVerificationEolErrors.top5ErrorsLinkTooltip', {
                    errorCode: stat.errorTypeCode,
                }),
            }))}
            pieChartTitle="home.EOLVerificationEolErrors.top5ErrorsTitle"
            pieChartSubTitle="home.EOLVerificationEolErrors.top5ErrorsSubTitle"
            total={stats.failedByErrorType.map((a) => a.total).reduce((a, b) => a + b, 0)}
            dataTestid="top5EolErrorsPiechart"
        />
    );
};
