import React from 'react';
import { H6 } from '../../typography/textStyles';
import spacing from '../../../style/spacing';
import Translation from '../../translation/Translation';
import IStyledProps from '../../IStyledProps';
import IChildrenProps from '../../IChildrenProps';

interface IFiltersGroupProps extends IStyledProps, IChildrenProps {
    title: string;
}

export const FiltersGroup: React.FC<IFiltersGroupProps> = ({ title, children, style = {} }: IFiltersGroupProps) => (
    <div style={{ marginBottom: spacing(3), ...style }}>
        <H6>
            <Translation i18nKey={title} />
        </H6>
        {children}
    </div>
);
