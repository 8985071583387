import React from 'react';
import { GroupuiCard } from '@group-ui/group-ui-react';

const Card: React.FC<any> = ({ children, style, elevation = 4, height = undefined }) => (
    <GroupuiCard elevation={elevation} style={style} height={height}>
        {children}
    </GroupuiCard>
);

export default Card;
