import IStyledProps from '../IStyledProps';
import IOthersProps from '../IOthersProps';
import React from 'react';

export interface IContainerProps extends IStyledProps, IOthersProps {}

const containerStyle = { padding: '0px', textAlign: 'start' };

export const Container: React.FC<IContainerProps> = ({ style = {}, ...others }) => (
    <div {...others} style={{ ...containerStyle, ...style }} />
);
