import { GroupuiDivider } from '@group-ui/group-ui-react';
import React from 'react';
import IOthersProps from '../IOthersProps';
import IStyledProps from '../IStyledProps';

interface IDividerProps extends IStyledProps, IOthersProps {}

export const Divider: React.FC<IDividerProps> = ({ style, ...others }) => {
    return <GroupuiDivider {...others} style={{ ...style }} />;
};
