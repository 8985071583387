import { EOL_ERRORS_URL } from '../config/baseUrls';
import authorizedFetch from '../security/authorizedFetch';

export interface EolErrorResponse {
    error_id: number;
    error_number_specific: number;
    error_text: string;
}

export class EolErrorService {
    getUniqueErrorValues(): Promise<EolErrorResponse[]> {
        return new Promise((resolve, reject) => {
            authorizedFetch(EOL_ERRORS_URL)
                .then((response) => response.json())
                .then((data) => resolve(data));
        });
    }
}
