import { GroupuiLink } from '@group-ui/group-ui-react';
import React from 'react';
import spacing from '../../style/spacing';
import { Divider } from './Divider';
import mainLogo from '../icons/logo/01_VWGC_Logo_RGB.png';
import { HELP_WIKI_URL } from '../../../domain/config/baseUrls';
import Translation from '../translation/Translation';
import { useTranslation } from 'react-i18next';

const footerStyle = {
    left: '0',
    bottom: '0',
    width: '100%',
    backgroundColor: 'white',
};
const dividerStyle = {
    margin: spacing(2),
    height: spacing(4),
};

const Footer = () => {
    const { i18n } = useTranslation();
    return (
        <footer
            style={{ ...footerStyle, textAlign: 'center', position: 'relative', paddingTop: '1em', height: '255px' }}
        >
            <div
                style={{
                    marginRight: spacing(6),
                    marginLeft: spacing(6),
                    paddingTop: spacing(5),
                    paddingBottom: spacing(3),
                }}
            >
                <Divider />
                <div
                    style={{
                        height: spacing(7),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <GroupuiLink target="blank" style={{ fontSize: '14px' }} quiet={true} href={HELP_WIKI_URL}>
                        <Translation i18nKey="footer.help" />
                    </GroupuiLink>
                    <Divider vertical={true} style={{ ...dividerStyle }}></Divider>

                    <GroupuiLink target="blank" style={{ fontSize: '14px' }} quiet={true} href={'/LicenseNotes.html'}>
                        <Translation i18nKey="footer.license_frontend" />
                    </GroupuiLink>
                    <Divider vertical={true} style={{ ...dividerStyle }}></Divider>

                    <GroupuiLink
                        target="blank"
                        style={{ fontSize: '14px' }}
                        quiet={true}
                        href={
                            'https://volkswagen-net.de/wikis/pages/viewpage.action?pageId=3056930332#Datenschutzerkl%C3%A4rung,Nutzungsbedingungen,CookieRichtlinien,LizenzbedingungenDritter-Datenschutzerkl%C3%A4rung'
                        }
                    >
                        <Translation i18nKey="footer.data_privacy_policy" />
                    </GroupuiLink>
                    <Divider vertical={true} style={{ ...dividerStyle }}></Divider>

                    <GroupuiLink
                        target="blank"
                        style={{ fontSize: '14px' }}
                        quiet={true}
                        href={
                            'https://volkswagen-net.de/wikis/pages/viewpage.action?pageId=3056930332#Datenschutzerkl%C3%A4rung,Nutzungsbedingungen,CookieRichtlinien,LizenzbedingungenDritter-Nutzungsbedingungen'
                        }
                    >
                        <Translation i18nKey="footer.terms_of_use" />
                    </GroupuiLink>
                    <Divider vertical={true} style={{ ...dividerStyle }}></Divider>

                    <GroupuiLink
                        target="blank"
                        style={{ fontSize: '14px' }}
                        quiet={true}
                        href={'https://www.volkswagenag.com/de/meta/provider-identification.html'}
                    >
                        <Translation i18nKey="footer.imprint" />
                    </GroupuiLink>
                    <Divider vertical={true} style={{ ...dividerStyle }}></Divider>

                    <GroupuiLink
                        target="blank"
                        style={{ fontSize: '14px' }}
                        quiet={true}
                        href={'http://www.volkswagenag.com/hinweis'}
                    >
                        <Translation i18nKey="footer.whistleblower_system" />
                    </GroupuiLink>
                    <Divider vertical={true} style={{ ...dividerStyle }}></Divider>

                    <GroupuiLink
                        target="blank"
                        style={{ fontSize: '14px' }}
                        quiet={true}
                        href={`/cookie_policy_${i18n.language === 'de' ? 'de' : 'en'}.pdf`}
                    >
                        <Translation i18nKey="footer.cookie_policy" />
                    </GroupuiLink>
                </div>

                <Divider />

                <img
                    src={mainLogo}
                    width="290px"
                    alt="Volkswagen Group Components Logo"
                    style={{ border: 'solid 0px', marginTop: spacing(-2), marginBottom: spacing(-6) }}
                />
            </div>
        </footer>
    );
};

export default Footer;
