import { GroupuiItem } from '@group-ui/group-ui-react';
import React from 'react';
import { Common, Correction, Process, Technology, Workpiece } from '../../../domain/models/Kapp';
import IStyledProps from '../../base/IStyledProps';
import { Container } from '../../base/layout/Container';
import Translation from '../../base/translation/Translation';
import { Body1 } from '../../base/typography/textStyles';
import { nodeSelectedColor } from '../../style/colors';
import spacing from '../../style/spacing';

export interface NavItemProps {
    name: string;
    level: number;
    setSelected: (name: string) => void;
    selected: string;
    identifier: string;
    index?: number;
}

const NavItem: React.FC<NavItemProps> = ({ name, level, setSelected, selected, identifier, index }) => {
    return (
        <>
            <GroupuiItem
                style={{
                    paddingTop: spacing(2),
                    paddingBottom: spacing(2),
                    paddingLeft: spacing(1 + level * 3),
                    paddingRight: spacing(4),
                    backgroundColor: selected === identifier ? nodeSelectedColor : 'white',
                }}
                onClick={() => setSelected(identifier)}
            >
                <Body1 weight={selected === identifier ? 'bold' : 'regular'} noGutter>
                    <Translation i18nKey={`machineDetails.analysis.${name}`} values={{ index: '' }} />
                </Body1>
            </GroupuiItem>
        </>
    );
};

export interface NavigationProps extends IStyledProps {
    object: Technology | Process | Workpiece | Correction[] | Common;
    setSelectedElement: (arg: any) => void;
    selectedElement: any;
}

const LeftNavigation: React.FC<NavigationProps> = ({ style, object, setSelectedElement, selectedElement }) => {
    const handleClick = (arg: string) => {
        setSelectedElement(arg);
    };

    if (object instanceof Technology) {
        return (
            <Container style={style}>
                <NavItem
                    name={'technology_tooling.title'}
                    level={0}
                    setSelected={handleClick}
                    selected={selectedElement['thirdTabId']}
                    identifier={`technology`}
                />
                {object.tools.map((item, index) => {
                    return (
                        <>
                            <NavItem
                                name={'technology_tooling.tools.title'}
                                level={1}
                                index={index}
                                setSelected={handleClick}
                                selected={selectedElement['thirdTabId']}
                                identifier={`technology.tools.${index}`}
                            />
                            <NavItem
                                name={'technology_tooling.tools.grinding_tool.title'}
                                level={2}
                                setSelected={handleClick}
                                selected={selectedElement['thirdTabId']}
                                identifier={`technology.tools.${index}.grinding_tool`}
                            />
                            <NavItem
                                name={'technology_tooling.tools.dressing_technology.title'}
                                level={2}
                                setSelected={handleClick}
                                selected={selectedElement['thirdTabId']}
                                identifier={`technology.tools.${index}.dressing_technology`}
                            />
                            {item.dressing_technology.dressing_steps.map((dressing_step, index1) => {
                                return (
                                    <NavItem
                                        name={'technology_tooling.tools.dressing_technology.dressing_step.title'}
                                        level={3}
                                        index={index1}
                                        setSelected={handleClick}
                                        selected={selectedElement['thirdTabId']}
                                        identifier={`technology.tools.${index}.dressing_technology.dressing_steps.${index1}`}
                                    />
                                );
                            })}
                        </>
                    );
                })}
                {object.processing_steps.map((item, index) => {
                    return (
                        <>
                            <NavItem
                                name={'technology_tooling.processing_steps.title'}
                                level={1}
                                index={index}
                                setSelected={handleClick}
                                selected={selectedElement['thirdTabId']}
                                identifier={`technology.processing_steps.${index}`}
                            />
                            {item.strokes.map((stroke, index1) => {
                                return (
                                    <NavItem
                                        name={'technology_tooling.processing_steps.stroke.title'}
                                        level={2}
                                        index={index1}
                                        setSelected={handleClick}
                                        selected={selectedElement['thirdTabId']}
                                        identifier={`technology.processing_steps.${index}.strokes.${index1}`}
                                    />
                                );
                            })}
                        </>
                    );
                })}
            </Container>
        );
    }

    if (object instanceof Process) {
        return (
            <Container style={style}>
                <NavItem
                    name={'technology_process.title'}
                    level={0}
                    setSelected={handleClick}
                    selected={selectedElement['forthTabId']}
                    identifier={`process`}
                />
                <NavItem
                    name={'technology_process.green_part_error.title'}
                    level={1}
                    setSelected={handleClick}
                    selected={selectedElement['forthTabId']}
                    identifier={`process.green_part_error`}
                />
                {object.grinding_process_steps.map((item, index) => {
                    return (
                        <>
                            <NavItem
                                name={'technology_process.grinding_process_steps.title'}
                                level={1}
                                index={index}
                                setSelected={handleClick}
                                selected={selectedElement['forthTabId']}
                                identifier={`process.grinding_process_steps.${index}`}
                            />
                            {item.grinding_process_strokes.map((stroke, index1) => {
                                return (
                                    <NavItem
                                        name={
                                            'technology_process.grinding_process_steps.grinding_process_strokes.title'
                                        }
                                        level={2}
                                        index={index1}
                                        setSelected={handleClick}
                                        selected={selectedElement['forthTabId']}
                                        identifier={`process.grinding_process_steps.${index}.grinding_process_strokes.${index1}`}
                                    />
                                );
                            })}
                        </>
                    );
                })}
            </Container>
        );
    }

    if (object instanceof Workpiece) {
        return (
            <Container style={style}>
                {object.gears.map((item, index) => {
                    return (
                        <>
                            <NavItem
                                name={'workpiece.title'}
                                level={0}
                                index={index}
                                setSelected={handleClick}
                                selected={selectedElement['secondTabId']}
                                identifier={`workpiece`}
                            />
                            <NavItem
                                name={'workpiece.gears.title'}
                                level={1}
                                index={index}
                                setSelected={handleClick}
                                selected={selectedElement['secondTabId']}
                                identifier={`workpiece.gears.${index}`}
                            />
                        </>
                    );
                })}
            </Container>
        );
    }

    if (object instanceof Common) {
        return (
            <Container style={style}>
                <NavItem
                    name={'common.title'}
                    level={0}
                    setSelected={handleClick}
                    selected={selectedElement['firstTabId']}
                    identifier={`common`}
                />
            </Container>
        );
    }
    if (object[0] instanceof Correction) {
        return (
            <Container style={style}>
                {object.map((item, index) => {
                    return (
                        <>
                            <NavItem
                                name={'corrections.title'}
                                level={0}
                                index={index}
                                setSelected={handleClick}
                                selected={selectedElement['fifthsTabId']}
                                identifier={`corrections.${index}`}
                            />
                            <NavItem
                                name={'corrections.axis_corrections.title'}
                                level={1}
                                index={index}
                                setSelected={handleClick}
                                selected={selectedElement['fifthsTabId']}
                                identifier={`corrections.${index}.axis_corrections`}
                            />
                        </>
                    );
                })}
            </Container>
        );
    } else {
        return <></>;
    }
};

export default LeftNavigation;
