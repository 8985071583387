import React, { useState } from 'react';
import { useStats } from '../../../domain/hooks/useStats';
import { Shift, SHIFT_TIME, TimeFrame } from '../../../domain/utils/timeFrames';
import Page from '../../base/layout/Page';
import Home from './Home';
import { IStatsFilters } from '../../../domain/services/StatsService';
import { loadLastTimeFrame, storeLastTimeFrame } from '../../../domain/utils/getDates';

const HomePage: React.FC<any> = () => {
    const innerTimeFrame = loadLastTimeFrame(new Shift(SHIFT_TIME.NIGHT));
    const [selectedProductTypes, setSelectedProductTypes] = useState<string[]>([]);
    const [selectedTestBenches, setSelectedTestBenches] = useState<string[]>([]);

    const { stats, filters, loading, setFilters } = useStats({
        fromDate: innerTimeFrame.fromDate,
        toDate: innerTimeFrame.toDate,
        selectedProductTypes: selectedProductTypes,
        selectedTestBenches: selectedTestBenches,
        exclude: true,
    });

    const [innerFilters, setInnerFilters] = useState<IStatsFilters>(filters);

    const onSelectedProductTypesChanged = (newSelectedProductTypes: string[]) => {
        setSelectedProductTypes(newSelectedProductTypes);
        setInnerFilters({ ...innerFilters, selectedProductTypes: newSelectedProductTypes });
    };

    const onSelectedTestBenchesChanged = (newSelectedTestBenches: string[]) => {
        setSelectedTestBenches(newSelectedTestBenches);
        setInnerFilters({ ...innerFilters, selectedTestBenches: newSelectedTestBenches });
    };

    const onTimeFrameChange = (newTimeFrame: TimeFrame) => {
        storeLastTimeFrame(newTimeFrame);
        setInnerFilters({ ...innerFilters, fromDate: newTimeFrame.fromDate, toDate: newTimeFrame.toDate });
    };

    // Callback for the search button, when clicked update the filters and trigger a new useStats
    const onSearchClicked = () => {
        setFilters(innerFilters);
    };

    // Callbacks for deep navigation.
    const getEolErrorTypeUrl = (eolErrorCode: number) => {
        // TODO: Use URLSearchParams class
        const queryParams = [
            `eolErrorCode=${eolErrorCode}`,
            `fromDate=${filters.fromDate.toISOString()}`,
            `toDate=${filters.toDate.toISOString()}`,
            `notPassed=1`,
            `passed=0`,
        ];
        filters.selectedProductTypes?.forEach((productType) => {
            queryParams.push(`productType=${productType}`);
        });
        filters.selectedTestBenches?.forEach((testBench) => {
            queryParams.push(`station=${testBench}`);
        });
        return `/eolVerifications?${queryParams.join('&')}`;
    };

    return (
        <Page title={'Home'} loading={loading}>
            {stats && (
                <Home
                    productDistributionStats={stats.verifications.productTypeDistribution}
                    selectedProductTypes={selectedProductTypes}
                    onSelectedProductTypesChanged={onSelectedProductTypesChanged}
                    testBenchDistributionStats={stats.verifications.testBenchDistribution}
                    selectedTestBenches={selectedTestBenches}
                    onSelectedTestBenchesChanged={onSelectedTestBenchesChanged}
                    timeFrame={innerTimeFrame}
                    onTimeFrameChange={onTimeFrameChange}
                    stats={stats}
                    filters={innerFilters}
                    isSearchDisabled={filters === innerFilters || !(innerFilters.fromDate <= innerFilters.toDate)}
                    getEolErrorTypeUrl={getEolErrorTypeUrl}
                    onSearchClicked={onSearchClicked}
                />
            )}
        </Page>
    );
};

export default HomePage;
