import React, { useState } from 'react';
import FiltersArea from '../../base/filtering/filtersArea/FiltersArea';
import TextFieldWithTags from '../../base/filtering/filtersGroups/TextFieldWithTags';
import { useTranslation } from 'react-i18next';
import { IMachinesFilter } from '../../../domain/services/MachinesService';

export interface IMachinesFiltersFormProps {
    productLines: string[];
    filters: IMachinesFilter;
    isLoading?: boolean;
    onSearchFiltersChange?: (filters: IMachinesFilter) => void;
    onSearchClicked?: (filters: IMachinesFilter) => void;
}

export const MachinesFiltersForm: React.FC<IMachinesFiltersFormProps> = ({
    productLines,
    filters,
    isLoading = false,
    onSearchFiltersChange = () => {},
    onSearchClicked = () => {},
}) => {
    const { t } = useTranslation();
    const [innerFilters, setInnerFilters] = useState(filters);

    const { machines } = innerFilters;

    const onFieldValueChange = (fieldName: string) => {
        return (value: any) => {
            const newInnerFilters: any = { ...innerFilters };
            newInnerFilters[fieldName] = value;
            setInnerFilters(newInnerFilters);
            onSearchFiltersChange(getNewFilters(newInnerFilters));
        };
    };

    const getNewFilters = (innerFilters: any) => {
        const { selectedTimeFrame, fromDate, toDate, ...other } = innerFilters;
        const newFilters = {
            ...other,
        };
        return newFilters;
    };

    const onSubmit = () => {
        onSearchClicked(getNewFilters(innerFilters));
    };
    const filtersChanged = JSON.stringify(getNewFilters(innerFilters)) !== JSON.stringify(filters);

    return (
        <FiltersArea title="machines.filter.title" onSubmit={onSubmit} searchDisabled={isLoading || !filtersChanged}>
            <TextFieldWithTags
                title={t('machines.filter.machine.title')}
                placeholderTextNoSelection={t('machines.filter.machine.noSelection')}
                placeholderTextSelection={t('machines.filter.machine.selection')}
                values={machines}
                onSelectionChange={(machine) => {
                    onFieldValueChange('machines')(machine);
                }}
                changeFontSize={true}
            />
        </FiltersArea>
    );
};

// <FilterHeader>
//     <Translation i18nKey="machines.filter.productionLine.title" />
// </FilterHeader>
// <Autocomplete
// style={{ marginBottom: spacing(1) }}
// value={productLines.filter((line) => innerFilters.lines.includes(line))}
// multiple
// disableClearable
// id="checkboxes-tags"
// PopperComponent={(props) => (
//     <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
// )}
// options={productLines}
// // groupBy={groupByLogic}
// disableCloseOnSelect
// getOptionLabel={(line) => line}
// renderTags={(value, props) => {
//     return value.map((option, index) => (
//         <>
//             {/* you can uncomment that line if you want to show the tags/chips inside the selectbox */}
//             {/* <Chip {...props({ index })} color="primary" size="small" label={option.error_number_specific} /> */}
//         </>
//     ));
// }}
// renderOption={(option, { selected }) => (
//     <React.Fragment key={option}>
//         <Checkbox style={{ marginRight: 8 }} checked={selected} />
//         {`(${option})`}
//     </React.Fragment>
// )}
// onChange={(event, checkedOptions) =>
//     onFieldValueChange('lines')(checkedOptions.map((option) => option))
// }
// renderInput={(params) => (
//     <TextField
//         {...params}
//         variant="outlined"
//         placeholder={t('machines.filter.productionLine.placeholder')}
//         size="small"
//     />
// )}
// />
// <Box>
// {innerFilters.lines.map((data, index) => {
//     return (
//         <Tag
//             data={data.toString()}
//             key={index}
//             handleDelete={() =>
//                 onFieldValueChange('lines')(innerFilters.lines.filter((line: string) => line !== data))
//             }
//         />
//     );
// })}
// </Box>
