import React from 'react';
import { EnginesHeader, EnginesRow } from './EnginesTableRows';
import { FlexContainer } from '../../../base/layout/Flex';
import { TitleBar } from '../../../base/layout/TitleBar';
import { Table } from '../../../base/table/Table';
import Translation from '../../../base/translation/Translation';
import { Engine } from '../../../../domain/models/Engine';
import { ISorting } from '../../../../domain/hooks/useEngines';
import { Paginator } from '../../../base/paginator/Paginator';
import spacing from '../../../style/spacing';
import Card from '../../../base/Card';
import Breadcrumbs from '../../../base/breadcrumbs';

export interface IEnginesTableProps {
    onRowClick?: (rowIdx: number) => void;
    onNextPageClicked?: () => void;
    onPrevPageClicked?: () => void;
    setSorting: (column: string) => void;
    sorting: ISorting | null;
    offset?: number;
    limit?: number;
    total: number;
    isLoading?: boolean;
    rows: Engine[];
    setPage: (page: number) => void;
}

export const EnginesTable: React.FC<IEnginesTableProps> = ({
    rows,
    total,
    offset = 0,
    limit = 25,
    isLoading = false,
    sorting,
    onRowClick = () => {},
    onPrevPageClicked = () => {},
    onNextPageClicked = () => {},
    setSorting = (column: string) => {},
    setPage = (page: number) => {},
}: IEnginesTableProps) => (
    <>
        <TitleBar
            dataTestid={'engines-title-bar'}
            resultNumber={total}
            subtitle={
                <Breadcrumbs
                    steps={[
                        {
                            label: 'engines.breadcrumbTitle',
                            href: '/engines',
                        },
                    ]}
                />
            }
            title={
                <FlexContainer alignItems="flex-end">
                    <Translation i18nKey="engines.tableTitle" />
                </FlexContainer>
            }
            actions={
                <Paginator
                    setPage={setPage}
                    total={total}
                    offset={offset}
                    limit={limit}
                    onNextPageClicked={onNextPageClicked}
                    onPrevPageClicked={onPrevPageClicked}
                />
            }
        />
        <Card style={{ marginTop: spacing(3) }} height="100%">
            <Table columns={5} header={true} size="s" sortable={false} isLoading={isLoading}>
                <EnginesHeader sorting={sorting} setSorting={setSorting} />
                {rows.map((row, idx) => (
                    <EnginesRow
                        dataTestid={`ProductBrowser-Row-${idx}`}
                        onClick={() => onRowClick(idx)}
                        key={idx}
                        engine={row}
                    />
                ))}
            </Table>
        </Card>
    </>
);
