import React from 'react';
import IStyledProps from '../IStyledProps';
import IOthersProps from '../IOthersProps';
import { IDataTestProps } from '../IDataTestProps';

export interface IFlexContainerProps extends IStyledProps, IOthersProps, IDataTestProps {
    alignItems?: string;
    justifyContent?: string;
    justifyItem?: string;
    flexDirection?: string;
    fullWidth?: boolean;
    direction?: string;
}

const flexContainerStyle = {
    display: 'flex',
};

export const FlexContainer: React.FC<IFlexContainerProps> = ({
    alignItems = undefined,
    justifyContent = undefined,
    justifyItems = undefined,
    flexDirection = 'row',
    fullWidth = true,
    direction = 'row',
    style = {},
    dataTestid = '',
    ...other
}) => (
    <div
        data-testid={dataTestid}
        style={{
            ...flexContainerStyle,
            width: fullWidth ? '100%' : undefined,
            flexDirection: direction,
            justifyContent,
            alignItems,
            justifyItems,
            ...style,
        }}
        {...other}
    />
);

interface IFlexGrowProps extends IStyledProps, IOthersProps {
    flexGrow?: number;
}

export const FlexGrow: React.FC<IFlexGrowProps> = ({ flexGrow = 0, style = {}, ...others }) => (
    <div style={{ flexGrow: flexGrow, ...style }} {...others} />
);
