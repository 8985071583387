import { Auth, Hub } from 'aws-amplify';
import { ADMIN_ROLE, USER_ROLE } from '../config/auth';

export class User {
    constructor(private readonly roles: string[], private readonly authTime: Date | undefined) {}

    get isAuthorizedUser(): boolean {
        return this.isUser || this.isAdmin;
    }

    get isUser(): boolean {
        return USER_ROLE === undefined ? false : this.roles.includes(USER_ROLE);
    }
    get isAdmin(): boolean {
        return ADMIN_ROLE === undefined ? false : this.roles.includes(ADMIN_ROLE);
    }

    get getAuthTime(): Date | undefined {
        return this.authTime;
    }
}

const toUser = (awsCognitoData: any): User => {
    const tokenPayload = awsCognitoData.signInUserSession.idToken.payload;
    const roles = [];
    const authTime = tokenPayload.auth_time ? new Date(tokenPayload.auth_time * 1000) : undefined;
    try {
        const decodedRoles = JSON.parse(tokenPayload['custom:roles']);
        roles.push(...decodedRoles);
    } catch (e) {
        console.debug('Error while decoding roles for the user.');
    }
    return new User(roles, authTime);
};

export default class AuthService {
    /**
     *
     */
    public async startOauthSignIn(route: string) {
        const config = { provider: 'vw-idp', customState: route };
        // The provider name is not officially declared in the documentation/type-hinting
        // @ts-ignore
        Auth.federatedSignIn(config)
            .then(() => {})
            .catch(() => {});
    }

    /**
     *
     * @param onSignIn
     * @param onSignOut
     * @param onCustomRoute
     */
    public onAuthChange(onSignIn: (user: User) => void, onSignOut: () => void, onCustomRoute: (route: string) => void) {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                    onSignIn(toUser(data));
                    break;
                case 'signOut':
                    onSignOut();
                    break;
                case 'customOAuthState':
                    onCustomRoute(data);
                    break;
            }
        });
    }

    /**
     *
     */
    public async getCurrentAuthenticatedUser(): Promise<User> {
        const promise = new Promise<User>((resolve, reject) => {
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    resolve(toUser(user));
                })
                .catch((e) => {
                    reject(e);
                });
        });

        return promise;
    }

    public async oAuthSignOut(): Promise<any> {
        return Auth.signOut();
    }
}
