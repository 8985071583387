import { useEffect, useState } from 'react';
import ProductionPlantsService, {
    defaultFilters,
    GetMachineProductionStepsResponse,
    IMachineDetailsFilters,
} from '../services/ProductionPlantsService';
import { loadLastTimeFrame } from '../utils/getDates';

export interface ISorting {
    column: String | null;
    direction: String | null;
}

export interface UseMachineProductionStepsState {
    machineProductionSteps: GetMachineProductionStepsResponse | null;
    filters: IMachineDetailsFilters;
    offset: number;
    limit: number;
    total: number;
    loading: boolean;
    sorting: ISorting | null;
    plantId: string;
    machineNumber: string;
    getNextPage: () => void;
    getPreviousPage: () => void;
    setFilters: (filters: IMachineDetailsFilters) => void;
    setSorting: (sort: ISorting) => void;
    setPage: (page: number) => void;
}

export const useMachineProductionSteps: (
    initialFilters: IMachineDetailsFilters,
    currentSorting: ISorting | null,
    plantId: string,
    machineNumber: string,
    initialOffset?: number,
    limit?: number
) => UseMachineProductionStepsState = (
    initialFilters: IMachineDetailsFilters = defaultFilters,
    currentSorting: ISorting | null,
    plantId: string,
    machineNumber: string,
    initialOffset: number = 0,
    limit: number = 20
) => {
    const [offset, setOffset] = useState(initialOffset);
    const [total, setTotal] = useState(0);
    const [machineProductionSteps, setMachineProductionSteps] = useState<GetMachineProductionStepsResponse | null>(
        null
    );
    const [loading, setLoading] = useState(false);
    const [filters, innerSetFilters] = useState({
        ...initialFilters,
        toDate: loadLastTimeFrame().toDate,
        fromDate: loadLastTimeFrame().fromDate,
    });
    const [sorting, setSortingProps] = useState<ISorting | null>(currentSorting);

    useEffect(() => {
        setLoading(true);
        const productionPlantsService = new ProductionPlantsService();
        productionPlantsService
            .getMachineProductionSteps(plantId, machineNumber, filters, offset, limit, sorting)
            .then((machineProductionSteps) => {
                setMachineProductionSteps(machineProductionSteps);
                setLoading(false);
                setTotal(machineProductionSteps.total);
            });
    }, [plantId, machineNumber, offset, limit, filters, sorting]);

    const getNextPage = () => {
        setOffset(offset + limit);
    };

    const getPreviousPage = () => {
        setOffset(Math.max(offset - limit, 0));
    };

    const setFilters = (filters: IMachineDetailsFilters) => {
        innerSetFilters(filters);
        setOffset(0);
    };

    const setSorting = (sort: ISorting) => {
        setSortingProps(sort);
    };

    const setPage = (page: number) => {
        setOffset((page - 1) * limit);
    };

    return {
        machineProductionSteps,
        filters,
        offset,
        limit,
        total,
        loading,
        sorting,
        plantId,
        machineNumber,
        getNextPage,
        getPreviousPage,
        setFilters,
        setSorting,
        setPage,
    };
};
