import { Auth } from 'aws-amplify';

const authorizedFetch = (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    return new Promise<Response>((resolve, reject) => {
        Auth.currentSession()
            .then((res) => {
                let idToken = res.getIdToken();
                let jwt = idToken.getJwtToken();
                fetch(input, { headers: { Authorization: `Bearer ${jwt}` }, ...init })
                    .then((response) => resolve(response))
                    .catch(reject);
            })
            .catch(reject);
    });
};

export default authorizedFetch;
