import React from 'react';
import { ISorting } from '../../../domain/hooks/useEngines';
import { Engine } from '../../../domain/models/Engine';
import { IEngineFilters } from '../../../domain/services/EngineService';
import { Container } from '../../base/layout/Container';
import { GridCol, GridRow } from '../../base/layout/Grid';
import spacing from '../../style/spacing';
import { EngineFiltersForm } from './EngineFiltersForm';
import { EnginesTable, IEnginesTableProps } from './EnginesTable/EnginesTable';

export interface IEnginesBrowser {
    total: number;
    offset: number;
    limit: number;
    filterprops: IEngineFilters;
    engines: Engine[];
    isLoading: boolean;
    onSearchClicked: (filters: IEngineFilters) => void;
    sorting: ISorting | null;
    setSorting: (column: string) => void;
    onEngineClicked: (engine: Engine) => void;
    onNextPageClicked?: () => void;
    onPrevPageClicked?: () => void;
    setPage: (page: number) => void;
}

const EnginesBrowser: React.FC<IEnginesBrowser> = ({
    total,
    offset,
    limit,
    filterprops,
    engines,
    isLoading,
    onSearchClicked,
    sorting,
    setSorting,
    onEngineClicked,
    onNextPageClicked,
    onPrevPageClicked,
    setPage,
}: IEnginesBrowser) => {
    const tableProps: IEnginesTableProps = {
        isLoading: isLoading,
        rows: engines,
        sorting,
        setSorting,
        onRowClick: (idx: number) => onEngineClicked(engines[idx]),
        total: total,
        limit,
        offset,
        onNextPageClicked,
        onPrevPageClicked,
        setPage,
    };

    return (
        <>
            <GridRow>
                <GridCol xs={3} xl={2} noPadding>
                    <EngineFiltersForm
                        isLoading={isLoading}
                        filterprops={filterprops}
                        onSearchClicked={onSearchClicked}
                    />
                </GridCol>
                <GridCol xs={9} xl={10} style={{ paddingLeft: spacing(4), paddingBottom: '0px' }}>
                    <Container style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <EnginesTable {...tableProps} />
                    </Container>
                </GridCol>
            </GridRow>
        </>
    );
};

export default EnginesBrowser;
