import React from 'react';
import { Stats } from '../../../domain/models/stats/Stats';
import { IStatsFilters } from '../../../domain/services/StatsService';
import { TimeFrame } from '../../../domain/utils/timeFrames';
import FiltersArea from '../../base/filtering/filtersArea/FiltersArea';
import { Container } from '../../base/layout/Container';
import { GridCol, GridRow } from '../../base/layout/Grid';
import { HomeFilters } from './HomeFilters';
import { EOLVerificationsCount } from './verificationsStats/EOLVerificationsCount';
import EOLVerificationsDirectPassRatio from './verificationsStats/EOLVerificationsDirectPassRatio';
import { EOLVerificationTop5Errors } from './verificationsStats/EOLVerificationTop5Errors';
import spacing from '../../style/spacing';
import {
    EOLVerificationsProductDistributionStats,
    EOLVerificationsTestBenchDistributionStats,
} from '../../../domain/models/stats/EOLVerificationsStats';
import Breadcrumbs from '../../base/breadcrumbs';

const StatsDashboard = ({
    stats,
    getEolErrorTypeUrl,
}: {
    stats: Stats;
    getEolErrorTypeUrl: (eolErrorCode: number) => string;
}) => (
    <>
        <Breadcrumbs
            style={{ paddingLeft: spacing(2.5) }}
            steps={[
                {
                    label: 'Home',
                },
            ]}
        />

        <GridRow sm={12} style={{ paddingLeft: spacing(1.5) }}>
            <EOLVerificationsCount stats={stats.verifications} />
        </GridRow>
        <GridRow justifyContent={'center'} alignItems={'stretch'} style={{ height: '100%' }} noPadding>
            <GridCol xs={12} xl={6} style={{ paddingBottom: '0px' }}>
                <EOLVerificationsDirectPassRatio stats={stats.verifications} />
            </GridCol>
            <GridCol sm={12} xl={6} style={{ paddingBottom: '0px' }}>
                <EOLVerificationTop5Errors getEolErrorTypeUrl={getEolErrorTypeUrl} stats={stats.verifications} />
            </GridCol>
        </GridRow>
    </>
);

export interface IHomeProps {
    stats: Stats;
    timeFrame: TimeFrame;
    isSearchDisabled: boolean;
    onTimeFrameChange: (timeFrame: TimeFrame) => void;
    filters: IStatsFilters;
    getEolErrorTypeUrl: (eolErrorCode: number) => string;
    productDistributionStats: EOLVerificationsProductDistributionStats | null;
    selectedProductTypes: string[];
    onSelectedProductTypesChanged: (selectedProductTypes: string[]) => void;
    testBenchDistributionStats: EOLVerificationsTestBenchDistributionStats | null;
    selectedTestBenches: string[];
    onSelectedTestBenchesChanged: (selectedTestBenches: string[]) => void;
    onSearchClicked: () => void;
}

const Home: React.FC<IHomeProps> = ({
    timeFrame,
    onTimeFrameChange,
    stats,
    onSearchClicked,
    getEolErrorTypeUrl,
    productDistributionStats,
    selectedProductTypes,
    onSelectedProductTypesChanged,
    testBenchDistributionStats,
    selectedTestBenches,
    onSelectedTestBenchesChanged,
    isSearchDisabled,
}: IHomeProps) => {
    return (
        <GridRow>
            <GridCol xs={3} xl={2} noPadding>
                <FiltersArea
                    title="home.filter.title"
                    hasAction={true}
                    onSubmit={onSearchClicked}
                    searchDisabled={isSearchDisabled}
                >
                    <HomeFilters
                        productDistributionStats={productDistributionStats}
                        selectedProductTypes={selectedProductTypes}
                        onSelectedProductTypesChanged={onSelectedProductTypesChanged}
                        testBenchDistributionStats={testBenchDistributionStats}
                        selectedTestBenches={selectedTestBenches}
                        onSelectedTestBenchesChanged={onSelectedTestBenchesChanged}
                        timeFrame={timeFrame}
                        onTimeFrameChange={onTimeFrameChange}
                        stats={stats}
                    />
                </FiltersArea>
            </GridCol>
            <GridCol xs={9} xl={10} style={{ paddingBottom: '0px', paddingLeft: spacing(2.5) }}>
                <Container style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <StatsDashboard getEolErrorTypeUrl={getEolErrorTypeUrl} stats={stats} />
                </Container>
            </GridCol>
        </GridRow>
    );
};

export default Home;
