import React from 'react';
import { useTranslation } from 'react-i18next';
import { Part } from '../../../domain/models/Part';
import Breadcrumbs from '../../base/breadcrumbs';
import Card from '../../base/Card';
import KeepOnTopContainer from '../../base/keepOnTopContainer/KeepOnTopContainer';
import { Container } from '../../base/layout/Container';
import { Divider } from '../../base/layout/Divider';
import { GridCol, GridRow } from '../../base/layout/Grid';
import { List } from '../../base/lists/List';
import { Border, ListItem, Size } from '../../base/lists/ListItem';
import Translation from '../../base/translation/Translation';
import { Body1, Caption, H4 } from '../../base/typography/textStyles';
import spacing from '../../style/spacing';
import { PartAdditionalInfo } from './PartAdditionalInfo';
import { PartEventsLog } from './PartEventsLog';
import PartProductionStepsDiagram from './PartProductionStepsDiagram';
import { ProductHistory } from './ProductHistory';

const SubPartsList = ({ partChildren }: { partChildren: Part[] }) => {
    const noSubComponents = (
        <ListItem border={Border.none} size={Size.s}>
            <Body1 noGutter>
                {' '}
                <Translation i18nKey="partDetails.noSubComponents" />
            </Body1>
        </ListItem>
    );

    const subComponents = partChildren.map((child) => (
        <ListItem border={Border.none} size={Size.s}>
            <Body1 noGutter>TID: {child.tid}</Body1>
            <Caption noGutter>
                <Translation i18nKey="partDetails.subComponentsType" values={{ type: child.part_type_name }} />
            </Caption>
            {child.part_label !== null ? (
                <Caption noGutter paragraph={true} style={{ marginBottom: 0 }}>
                    <Translation i18nKey="partDetails.label" values={{ label: child.part_label }} />
                </Caption>
            ) : (
                <></>
            )}
        </ListItem>
    ));

    return (
        <>
            <H4 noGutter={true}>
                <Translation i18nKey="partDetails.subComponents" />
            </H4>
            <List style={{ marginBottom: 0 }}>{partChildren.length > 0 ? subComponents : noSubComponents}</List>
        </>
    );
};

const Header = ({ part }: { part: Part }) => {
    const { t } = useTranslation();
    return (
        <>
            <Breadcrumbs
                steps={[
                    {
                        label: 'parts.tableBreadcrumb',
                        href: '/parts',
                    },
                    {
                        label: t('parts.partIDBreadcrumb', { part_tid: part.tid }),
                    },
                ]}
            />
        </>
    );
};

export const PartDetails = ({ part, partChildren = [] }: { part: Part; partChildren?: Part[] }) => {
    return (
        <Container data-testid={'part-details'}>
            <Header part={part} />
            <PartProductionStepsDiagram part={part} partChildren={partChildren} />
            <GridRow justifyContent="space-between" style={{ paddingLeft: 0, marginTop: spacing(3) }}>
                <GridCol noPadding xs={9} s={8} style={{ display: 'flex', flexDirection: 'column' }}>
                    <H4 noGutter={true} style={{ marginBottom: spacing(2) }}>
                        <Translation i18nKey="partDetails.eventsLog.title" />
                    </H4>
                    <PartEventsLog part={part} partChildren={partChildren} />
                </GridCol>
                <GridCol noPadding xs={3} s={4} style={{ display: 'flex', flexDirection: 'column' }}>
                    <H4 noGutter={true} style={{ marginLeft: spacing(3), marginBottom: spacing(2) }}>
                        <Translation i18nKey="partDetails.eventsLog.machineDetails" />
                    </H4>
                    <Card style={{ marginLeft: spacing(3) }} height="100%">
                        <KeepOnTopContainer minDeltaFromTop={100}>
                            <PartAdditionalInfo part={part} />
                            <Divider style={{ marginBottom: spacing(1), marginTop: spacing(1) }} />
                            <SubPartsList partChildren={partChildren} />
                            <Divider style={{ marginBottom: spacing(1), marginTop: spacing(1) }} />
                            <ProductHistory part={part} />
                            <Divider style={{ marginBottom: spacing(1), marginTop: spacing(1) }} />
                        </KeepOnTopContainer>
                    </Card>
                </GridCol>
            </GridRow>
        </Container>
    );
};
