import { useEffect, useState } from 'react';
import { Part } from '../models/Part';
import PartsService from '../services/PartsService';

export interface UsePartState {
    part: Part | null;
    loading: boolean;
    error: Error | null;
}

/**
 * Custom hook
 * Downloads a products given the productId
 * @param productId
 */
export const usePart: (partId: string) => UsePartState = (productId: string) => {
    const [part, setPart] = useState<Part | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        const productService = new PartsService();
        productService
            .getPart(productId)
            .then((response) => {
                setPart(response);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    }, [productId]);

    return {
        part,
        loading,
        error,
    };
};
