import { PartTypecol } from './PartType';
import { Part } from './Part';

export class PartHistory {
    constructor(public assembledAt: Date, public part: Part) {}

    static fromJson(json: any): PartHistory {
        return new PartHistory(new Date(json.assembled_at), Part.fromJson(json.part));
    }
}

export class Product {
    constructor(
        public pid: string,
        public date: Date | null,
        public partHistory: PartHistory[] = [],
        public etlTimestamp: Date | null = null
    ) {}

    getPartsByType(type: PartTypecol): Part[] {
        return this.partHistory.map((partHistrory) => partHistrory.part).filter((part) => part.part_type === type);
    }

    static fromJson(json: any): Product {
        return new Product(
            json.pid,
            json.date ? new Date(json.date) : json.date,
            json.part_history.map((partJson: any) => PartHistory.fromJson(partJson)),
            new Date(json.etl_timestamp)
        );
    }
}
