import {
    IAxisCorrections,
    ICommon,
    ICorrection,
    IDressingStep,
    IDressingTechnology,
    IGear,
    IGreenPartError,
    IGrindingProcessStep,
    IGrindingProcessStroke,
    IGrindingTool,
    IKappResponse,
    IOrder,
    IOrderAnalysis,
    IProcess,
    IProcessingStep,
    IStroke,
    ITechnology,
    ITool,
    IWorkpiece,
} from '../services/MachinesService';

export default class Kapp {
    constructor(
        public tid: string,
        public machine: string,
        public machine_uid: string,
        public source: string,
        public order_analysis: OrderAnalysis[] = [],
        public common: Common,
        public workpiece: Workpiece,
        public technology: Technology,
        public corrections: Correction[] = [],
        public process: Process
    ) {}

    static fromJson(json: IKappResponse) {
        return new Kapp(
            json.tid,
            json.machine,
            json.machine_uid,
            json.source,
            json.order_analysis.map((order_analysis) => OrderAnalysis.fromJson(order_analysis)),
            Common.fromJson(json.common),
            Workpiece.fromJson(json.workpiece),
            Technology.fromJson(json.technology),
            json.corrections.map((correction) => Correction.fromJson(correction)),
            Process.fromJson(json.process)
        );
    }
}

export class AxisCorrections {
    constructor(
        public infeed_axis: number,
        public shift_axis: number,
        public stroke_axis: number,
        public swivel_axis: number,
        public workpiece_axis: number
    ) {}

    static fromJson(json: IAxisCorrections): AxisCorrections {
        return new AxisCorrections(
            json.infeed_axis,
            json.shift_axis,
            json.stroke_axis,
            json.swivel_axis,
            json.workpiece_axis
        );
    }
}
export class Correction {
    constructor(public tool: number, public gear: number, public axis_corrections: AxisCorrections) {}

    static fromJson(json: ICorrection): Correction {
        return new Correction(json.tool, json.gear, json.axis_corrections);
    }
}

export class GreenPartError {
    constructor(public minimum_deviation: number, public maximum_deviation: number) {}

    static fromJson(json: IGreenPartError): GreenPartError {
        return new GreenPartError(json.minimum_deviation, json.maximum_deviation);
    }
}
export class GrindingProcessStroke {
    constructor(public power_max: number, public power_rms: number, public shift_start_pos: number) {}

    static fromJson(json: IGrindingProcessStroke): GrindingProcessStroke {
        return new GrindingProcessStroke(json.power_max, json.power_rms, json.shift_start_pos);
    }
}

export class GrindingProcessStep {
    constructor(
        public active: number,
        public workpiece_rpm: number,
        public grinding_process_strokes: GrindingProcessStroke[]
    ) {}

    static fromJson(json: IGrindingProcessStep): GrindingProcessStep {
        return new GrindingProcessStep(
            json.active,
            json.workpiece_rpm,
            json.grinding_process_strokes.map((grinding_process_stroke) =>
                GrindingProcessStroke.fromJson(grinding_process_stroke)
            )
        );
    }
}

export class Process {
    constructor(
        public green_part_error: GreenPartError,
        public processing_unit: number,
        public tool_unbalance: number,
        public temperature_coolant: number,

        public temperature_ambient: number,
        public index_tool_wear: number,
        public index_noise: number,
        public index_wear_infeed: number,
        public index_wear_shift: number,

        public index_wear_stroke: number,
        public cycletime_processing: number,
        public cycletime_floor_to_floor: number,
        public parts_since_last_dressing: number,
        public override_limited: number,

        public last_dressing: Date,
        public process_start: Date,
        public process_end: Date,

        public grinding_process_steps: GrindingProcessStep[] = []
    ) {}

    static fromJson(json: IProcess): Process {
        return new Process(
            json.green_part_error,
            json.processing_unit,
            json.tool_unbalance,
            json.temperature_coolant,
            json.temperature_ambient,
            json.index_tool_wear,
            json.index_noise,
            json.index_wear_infeed,
            json.index_wear_shift,
            json.index_wear_stroke,
            json.cycletime_processing,
            json.cycletime_floor_to_floor,
            json.parts_since_last_dressing,
            json.override_limited,
            json.last_dressing,
            json.process_start,
            json.process_end,
            json.grinding_process_steps.map((grinding_process_step) =>
                GrindingProcessStep.fromJson(grinding_process_step)
            )
        );
    }
}

export class Gear {
    constructor(
        public number_of_teeth: number,
        public normal_module: number,
        public pressure_angle: number,
        public reference_helix_angle: number,
        public face_width: number,
        public da: number,
        public df: number,
        public dna: number,
        public dnf: number,
        public stock_green_part: number
    ) {}

    static fromJson(json: IGear): Gear {
        return new Gear(
            json.number_of_teeth,
            json.normal_module,
            json.pressure_angle,
            json.reference_helix_angle,
            json.face_width,
            json.da,
            json.df,
            json.dna,
            json.dnf,
            json.stock_green_part
        );
    }
}

export class Workpiece {
    constructor(public drawing_number: string, public gears_count: number, public gears: Gear[]) {}

    static fromJson(json: IWorkpiece): Workpiece {
        return new Workpiece(
            json.drawing_number,
            json.gears_count,
            json.gears.map((gear) => Gear.fromJson(gear))
        );
    }
}
export class Common {
    constructor(
        public machine_id_kapp: string,
        public machine_id_customer: string,
        // EXCLUDED BECAUSE OF GDPR
        // public operator: string,
        // public operator_key_id: string,
        public accesslevel: string,
        public version_plc: string,
        public part_id: string,
        public part_status: string,
        public last_change: string,
        public ip_adress: string
    ) {}

    static fromJson(json: ICommon): Common {
        return new Common(
            json.machine_id_kapp,
            json.machine_id_customer,
            // json.operator,
            // json.operator_key_id,
            json.accesslevel,
            json.version_plc,
            json.part_id,
            json.part_status,
            json.last_change,
            json.ip_adress
        );
    }
}

export class GrindingTool {
    constructor(
        public index: number,
        public ds: number,
        public no_of_threads: number,
        public hand_of_reference_helix: number,
        public generative_angle: number
    ) {}

    static fromJson(json: IGrindingTool): GrindingTool {
        return new GrindingTool(
            json.index,
            json.ds,
            json.no_of_threads,
            json.hand_of_reference_helix,
            json.generative_angle
        );
    }
}

export class DressingStep {
    constructor(
        public worm_rpm: number,
        public form_wheel_rpm: number,
        public path_speed: number,
        public infeed_per_stroke: number,
        public oscillation_active: number,
        public direction_first_stroke: number,
        public strokes: number
    ) {}

    static fromJson(json: IDressingStep): DressingStep {
        return new DressingStep(
            json.worm_rpm,
            json.form_wheel_rpm,
            json.path_speed,
            json.infeed_per_stroke,
            json.oscillation_active,
            json.direction_first_stroke,
            json.strokes
        );
    }
}

export class DressingTechnology {
    constructor(public timestamp: Date, public dressing_steps: DressingStep[]) {}

    static fromJson(json: IDressingTechnology): DressingTechnology {
        return new DressingTechnology(
            json.timestamp,
            json.dressing_steps.map((dressing_step) => DressingStep.fromJson(dressing_step))
        );
    }
}

export class Tool {
    constructor(public grinding_tool: GrindingTool, public dressing_technology: DressingTechnology) {}

    static fromJson(json: ITool): Tool {
        return new Tool(json.grinding_tool, json.dressing_technology);
    }
}

export class Stroke {
    constructor(
        public feed_mm_min: number,
        public fed_mm_re: number,
        public infeed_radial: number,
        public shift_path_mm_str: number,
        public shift_path_jump_end: number
    ) {}

    static fromJson(json: IStroke): Stroke {
        return new Stroke(
            json.feed_mm_min,
            json.fed_mm_re,
            json.infeed_radial,
            json.shift_path_mm_str,
            json.shift_path_jump_end
        );
    }
}

export class ProcessingStep {
    constructor(
        public tool_no: number,
        public tool_speed: number,
        public cutting_speed: number,
        public q_apos_w: number,
        public process_coolant_flow_rate: number,
        public strokes: IStroke[]
    ) {}

    static fromJson(json: IProcessingStep): ProcessingStep {
        return new ProcessingStep(
            json.tool_no,
            json.tool_speed,
            json.cutting_speed,
            json.q_apos_w,
            json.process_coolant_flow_rate,
            json.strokes.map((stroke) => Stroke.fromJson(stroke))
        );
    }
}

export class Technology {
    constructor(
        public clamping_pressure: number,
        public tailstock_active: boolean,
        public tailstock_clamping_force: number,
        public tools_count: number,
        public tools: Tool[] = [],
        public processing_steps_count: number,
        public processing_steps: ProcessingStep[]
    ) {}

    static fromJson(json: ITechnology): Technology {
        return new Technology(
            json.clamping_pressure,
            json.tailstock_active,
            json.tailstock_clamping_force,
            json.tools_count,
            json.tools.map((tool) => Tool.fromJson(tool)),
            json.processing_steps_count,
            json.processing_steps.map((processing_step) => ProcessingStep.fromJson(processing_step))
        );
    }
}
export class OrderAnalysis {
    constructor(
        public sensor_name: string,
        public channel: string,
        public channel_type: string,
        public base_frequency: number,
        public filename: string,
        public orders: Order[] = []
    ) {}

    static fromJson(json: IOrderAnalysis): OrderAnalysis {
        return new OrderAnalysis(
            json.sensor_name,
            json.channel,
            json.channel_type,
            json.base_frequency,
            json.filename,
            json.orders.map((order) => Order.fromJson(order))
        );
    }
}

export class Order {
    constructor(public nr: number, public max: number, public mean: number) {}

    static fromJson(json: IOrder): Order {
        return new Order(json.nr, json.max, json.mean);
    }
}
