import React from 'react';
import { EolVerificationHeader, EolVerificationRow } from './EolVerificationsTableRows';
import { FlexContainer } from '../../../base/layout/Flex';
import { TitleBar } from '../../../base/layout/TitleBar';
import { Table } from '../../../base/table/Table';
import { Paginator } from '../../../base/paginator/Paginator';
import Translation from '../../../base/translation/Translation';
import EOLVerification from '../../../../domain/models/EOLVerification';
import ISorting from '../../../../domain/interfaces/ISorting';
import Card from '../../../base/Card';
import spacing from '../../../style/spacing';
import Breadcrumbs from '../../../base/breadcrumbs';

interface IEolVerificationsTableProps {
    onRowClick?: (rowIdx: number) => void;
    onNextPageClicked?: () => void;
    onPrevPageClicked?: () => void;
    setSorting: (column: string) => void;
    sorting: ISorting | null;
    offset?: number;
    limit?: number;
    total: number;
    isLoading?: boolean;
    rows: EOLVerification[];
    setPage: (page: number) => void;
}

export const EolVerificationsTable: React.FC<IEolVerificationsTableProps> = ({
    rows,
    total,
    offset = 0,
    limit = 25,
    isLoading = false,
    sorting,
    onRowClick = () => {},
    onPrevPageClicked = () => {},
    onNextPageClicked = () => {},
    setSorting = (column: string) => {},
    setPage = (page: number) => {},
}: IEolVerificationsTableProps) => (
    <>
        <TitleBar
            dataTestid={'eolVerifications.tableTitle'}
            resultNumber={total}
            subtitle={
                <Breadcrumbs
                    steps={[
                        {
                            label: 'eolVerifications.breadcrumbTitle',
                            href: '/eolVerifications',
                        },
                    ]}
                />
            }
            title={
                <FlexContainer alignItems="flex-end">
                    <Translation i18nKey="eolVerifications.tableTitle" />
                </FlexContainer>
            }
            actions={
                <Paginator
                    total={total}
                    offset={offset}
                    limit={limit}
                    onNextPageClicked={onNextPageClicked}
                    onPrevPageClicked={onPrevPageClicked}
                    setPage={setPage}
                />
            }
        />
        <Card style={{ marginTop: spacing(3) }} height="100%">
            <Table columns={9} header={true} size="s" sortable={false} isLoading={isLoading}>
                <EolVerificationHeader sorting={sorting} setSorting={setSorting} />
                {rows.map((row, idx) => (
                    <EolVerificationRow
                        dataTestid={`ProductBrowser-Row-${idx}`}
                        onClick={() => onRowClick(idx)}
                        key={idx}
                        eolVerification={row}
                    />
                ))}
            </Table>
        </Card>
    </>
);
