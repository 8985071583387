import { EOLVerificationsStats } from './EOLVerificationsStats';

export class Stats {
    constructor(public readonly verifications: EOLVerificationsStats) {}

    static fromJson(json: any): Stats {
        const verifications = EOLVerificationsStats.fromJson(json.verifications);
        return new Stats(verifications);
    }
}
