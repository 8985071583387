import { loadTimezoneFromAppSettings, toDateTimeString } from '../../components/base/utils';
import moment from 'moment';

// INFORMATION REGARDING DAYDEFINITON FOR OUR PRODUCT
// On the Home Side we are looking to the data in a shift system (early, late and night).
// The early shift starts at 6:30 and the night shift ends at 6:29.
// So VW shift day starts also from 6:30 and goes to 6:29 at the next day.

export class TimeFrame {
    constructor(public readonly fromDate: moment.Moment, public readonly toDate: moment.Moment) {}

    get value(): string {
        return this.name.replace('timeFrames.', '');
    }

    get name(): string {
        return 'timeFrames.custom';
    }

    get date(): string {
        return `From ${toDateTimeString(this.fromDate)}, to ${toDateTimeString(this.toDate)}`;
    }
}

export enum SHIFT_TIME {
    EARLY = 0,
    LATE = 1,
    NIGHT = 2,
}

export class Shift extends TimeFrame {
    static toDate(shiftTime: SHIFT_TIME): moment.Moment {
        let toDate = moment.tz(new Date(), 'Europe/Berlin');

        switch (shiftTime) {
            case SHIFT_TIME.EARLY:
                toDate.subtract(1, 'days');
                toDate.set({
                    hour: 14,
                    minute: 30,
                });
                break;
            case SHIFT_TIME.LATE:
                toDate.subtract(1, 'days');
                toDate.set({
                    hour: 22,
                    minute: 30,
                });
                break;
            case SHIFT_TIME.NIGHT:
                toDate.set({
                    hour: 6,
                    minute: 30,
                });
                break;
        }
        toDate.set({
            second: 0,
            millisecond: 0,
        });
        return toDate;
    }

    static fromDate(shiftTime: SHIFT_TIME): moment.Moment {
        let fromDate = moment.tz(new Date(), 'Europe/Berlin');
        fromDate.subtract(1, 'days');

        switch (shiftTime) {
            case SHIFT_TIME.EARLY:
                fromDate.set({
                    hour: 6,
                    minutes: 30,
                });
                break;
            case SHIFT_TIME.LATE:
                fromDate.set({
                    hour: 14,
                    minutes: 30,
                });
                break;
            case SHIFT_TIME.NIGHT:
                fromDate.set({
                    hour: 22,
                    minutes: 30,
                });
                break;
        }

        fromDate.set({
            second: 0,
            millisecond: 0,
        });
        return fromDate;
    }

    constructor(private readonly shiftTime: SHIFT_TIME = SHIFT_TIME.EARLY) {
        super(Shift.fromDate(shiftTime), Shift.toDate(shiftTime));
    }

    get name(): string {
        switch (this.shiftTime) {
            case SHIFT_TIME.EARLY:
                return 'timeFrames.earlyShift';
            case SHIFT_TIME.LATE:
                return 'timeFrames.lateShift';
            case SHIFT_TIME.NIGHT:
                return 'timeFrames.nightShift';
            default:
                return 'timeFrames.earlyShift';
        }
    }
}

export class TodayVWDefinition extends TimeFrame {
    static fromDate(): moment.Moment {
        let fromDate = moment.tz(new Date(), loadTimezoneFromAppSettings().value);
        fromDate.set({
            hour: 6,
            minute: 30,
            second: 0,
            millisecond: 0,
        });
        return fromDate;
    }

    static toDate(): moment.Moment {
        let toDate = moment.tz(new Date(), loadTimezoneFromAppSettings().value);
        toDate.add(1, 'days');
        toDate.set({
            hour: 6,
            minute: 30,
            second: 0,
            millisecond: 0,
        });
        return toDate;
    }

    constructor() {
        super(TodayVWDefinition.fromDate(), TodayVWDefinition.toDate());
    }

    get name(): string {
        return 'timeFrames.today';
    }
}

export class YesterdayVWDefinition extends TimeFrame {
    static fromDate(): moment.Moment {
        let fromDate = moment.tz(new Date(), loadTimezoneFromAppSettings().value);
        fromDate.subtract(1, 'days');
        fromDate.set({
            hours: 6,
            minutes: 30,
            seconds: 0,
            milliseconds: 0,
        });
        return fromDate;
    }

    static toDate(): moment.Moment {
        let toDate = moment.tz(new Date(), loadTimezoneFromAppSettings().value);
        toDate.set({
            hours: 6,
            minutes: 30,
            seconds: 0,
            milliseconds: 0,
        });
        return toDate;
    }

    constructor() {
        super(YesterdayVWDefinition.fromDate(), YesterdayVWDefinition.toDate());
    }

    get name(): string {
        return 'timeFrames.yesterday';
    }
}

export class LastMonthVWDefinition extends TimeFrame {
    static fromDate(): moment.Moment {
        let fromDate = moment.tz(new Date(), loadTimezoneFromAppSettings().value);
        fromDate.subtract(30, 'days');
        fromDate.set({
            hours: 6,
            minutes: 30,
            seconds: 0,
            milliseconds: 0,
        });
        return fromDate;
    }

    static toDate(): moment.Moment {
        let fromDate = moment.tz(new Date(), loadTimezoneFromAppSettings().value);
        fromDate.set({
            hours: 6,
            minutes: 30,
            seconds: 0,
            milliseconds: 0,
        });
        return fromDate;
    }

    constructor() {
        super(LastMonthVWDefinition.fromDate(), LastMonthVWDefinition.toDate());
    }

    get name(): string {
        return 'timeFrames.lastMonth';
    }
}

export class Today extends TimeFrame {
    static fromDate(): moment.Moment {
        return moment.tz(new Date(), loadTimezoneFromAppSettings().value).startOf('date');
    }

    static toDate(): moment.Moment {
        return moment.tz(new Date(), loadTimezoneFromAppSettings().value).endOf('date');
    }

    constructor() {
        super(Today.fromDate(), Today.toDate());
    }

    get name(): string {
        return 'timeFrames.today';
    }
}
export class Yesterday extends TimeFrame {
    static fromDate(): moment.Moment {
        return moment.tz(new Date(), loadTimezoneFromAppSettings().value).subtract(1, 'days').startOf('date');
    }

    static toDate(): moment.Moment {
        return moment.tz(new Date(), loadTimezoneFromAppSettings().value).subtract(1, 'days').endOf('date');
    }

    constructor() {
        super(Yesterday.fromDate(), Yesterday.toDate());
    }

    get name(): string {
        return 'timeFrames.yesterday';
    }
}

export class LastWeekVWDefinition extends TimeFrame {
    static fromDate(): moment.Moment {
        let fromDate = moment.tz(new Date(), loadTimezoneFromAppSettings().value);
        fromDate.subtract(7, 'days');
        fromDate.set({
            hours: 6,
            minutes: 30,
            seconds: 0,
            milliseconds: 0,
        });
        return fromDate;
    }

    static toDate(): moment.Moment {
        let fromDate = moment.tz(new Date(), loadTimezoneFromAppSettings().value);
        fromDate.set({
            hours: 6,
            minutes: 30,
            seconds: 0,
            milliseconds: 0,
        });
        return fromDate;
    }

    constructor() {
        super(LastWeekVWDefinition.fromDate(), LastWeekVWDefinition.toDate());
    }

    get name(): string {
        return 'timeFrames.lastWeek';
    }
}

export class LastWeek extends TimeFrame {
    static fromDate(): moment.Moment {
        return moment.tz(new Date(), loadTimezoneFromAppSettings().value).subtract(7, 'days').startOf('date');
    }

    static toDate(): moment.Moment {
        return moment.tz(new Date(), loadTimezoneFromAppSettings().value).endOf('date');
    }

    constructor() {
        super(LastWeek.fromDate(), LastWeek.toDate());
    }

    get name(): string {
        return 'timeFrames.lastWeek';
    }
}
export class LastMonth extends TimeFrame {
    static fromDate(): moment.Moment {
        return moment.tz(new Date(), loadTimezoneFromAppSettings().value).subtract(1, 'months').startOf('month');
    }

    static toDate(): moment.Moment {
        return moment.tz(new Date(), loadTimezoneFromAppSettings().value).subtract(1, 'months').endOf('month');
    }

    constructor() {
        super(LastMonth.fromDate(), LastMonth.toDate());
    }

    get name(): string {
        return 'timeFrames.lastMonth';
    }
}

export const toTimeFrames = (now: moment.Moment) => {
    return {
        today: new TodayVWDefinition(),
        nightShift: new Shift(SHIFT_TIME.NIGHT),
        lateShift: new Shift(SHIFT_TIME.LATE),
        earlyShift: new Shift(SHIFT_TIME.EARLY),
        yesterday: new YesterdayVWDefinition(),
        lastWeek: new LastWeekVWDefinition(),
        lastMonth: new LastMonthVWDefinition(),
        custom: new TimeFrame(now, now),
    };
};

export const toTimeFramesWithoutShifts = (now: moment.Moment) => {
    return {
        today: new Today(),
        yesterday: new Yesterday(),
        lastWeek: new LastWeekVWDefinition(),
        lastMonth: new LastMonthVWDefinition(),
        custom: new TimeFrame(now, now),
    };
};
