import React from 'react';
import { ISorting } from '../../../../domain/hooks/useEngines';
import { Engine } from '../../../../domain/models/Engine';
import { LinkIcon } from '../../../base/icons/icons';
import { TableCell, TableRow } from '../../../base/table/Table';
import Tooltip from '../../../base/tooltip/tooltip';
import Translation from '../../../base/translation/Translation';
import { toDateStringGerman } from '../../../base/utils';
import { primary } from '../../../style/colors';
import { EnginesStatus } from './EnginesTableCells';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const EnginesRow = ({
    engine,
    onClick,
    dataTestid,
}: {
    engine: Engine;
    onClick: () => void;
    dataTestid?: string;
}) => {
    const { t } = useTranslation();

    const handleClick = () => {
        let text: string | undefined;
        text = window.getSelection()?.toString();
        if (text !== undefined && text.length > 0) {
            return;
        }
        onClick();
    };
    const onEngineClicked = (enginePid: string) => {
        return `/products/${enginePid}`;
    };

    return (
        <TableRow dataTestid={dataTestid} onClick={handleClick} style={{ cursor: 'pointer' }}>
            <TableCell>{engine.pid}</TableCell>
            <TableCell>{toDateStringGerman(engine.date)}</TableCell>
            <TableCell>{engine.eolNumber}</TableCell>
            <TableCell alignment="center">
                <EnginesStatus status={engine.eolStatus} />
            </TableCell>
            <TableCell alignment="center">
                <Tooltip tooltipMessage={t('engines.linktip')}>
                    <Link to={onEngineClicked(engine.pid)}>
                        <LinkIcon style={{ color: primary }} />
                    </Link>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};
export const EnginesHeader = ({
    sorting,
    setSorting,
}: {
    sorting: ISorting | null;
    setSorting: (column: string) => void;
}) => {
    const getColumnOrder = (column: String) => {
        if (sorting === null || column !== sorting.column) {
            return 'unsorted';
        }
        return sorting.direction === 'asc' ? 'up' : 'down';
    };

    return (
        <>
            <TableRow header style={{ color: primary }}>
                <TableCell sortable={true} order={getColumnOrder('pid')} onClick={() => setSorting('pid')}>
                    <Translation i18nKey="engines.tableHeader.pid" />
                </TableCell>
                <TableCell sortable={true} order={getColumnOrder('pid_date')} onClick={() => setSorting('pid_date')}>
                    <Translation i18nKey="engines.tableHeader.date" />
                </TableCell>
                <TableCell
                    sortable={true}
                    order={getColumnOrder('number_of_verifications')}
                    onClick={() => setSorting('number_of_verifications')}
                >
                    <Translation i18nKey="engines.tableHeader.verifications" />
                </TableCell>
                <TableCell
                    alignment="center"
                    sortable={true}
                    order={getColumnOrder('direct_pass_value')}
                    onClick={() => setSorting('direct_pass_value')}
                >
                    <Translation i18nKey="engines.tableHeader.status" />
                </TableCell>
                <TableCell alignment="center">
                    <Translation i18nKey="engines.tableHeader.link" />
                </TableCell>
            </TableRow>
        </>
    );
};
