import { ProductionStep } from './ProductionStep';
import { PartTypecol } from './PartType';

export class Part {
    constructor(
        public tid: string,
        public part_type: PartTypecol,
        public current_pid: string | null,
        public steps: ProductionStep[] = [],
        public productHistory: ProductHistory[] = [],
        public duns: string,
        public production_date: Date | null,
        public part_index: string,
        public serial: string,
        public part_label: string,
        public part_number: string,
        public part_reprocess: boolean,
        public part_type_name: string,
        public pids: string[] = [],
        public parent_tid: string | null = null
    ) {}

    addStep(step: ProductionStep): void {
        this.steps.push(step);
    }

    static fromJson(json: any): Part {
        let steps = [];
        if (json.production_steps) {
            steps = json.production_steps.map((step: any) => ProductionStep.fromJson(step));
        }
        let productHistory = [];
        if (json.product_history) {
            productHistory = json.product_history.map((partJson: any) => ProductHistory.fromJson(partJson));
        }

        return new Part(
            json.tid,
            json.part_type,
            json.current_pid,
            steps,
            productHistory,
            json.duns,
            json.production_date ? new Date(json.production_date) : null,
            json.part_index,
            json.serial,
            json.part_label,
            json.part_number,
            json.part_reprocess,
            json.part_type_name,
            json.pids,
            json.parent_tid
        );
    }
}

export class ProductHistory {
    constructor(public assembledAt: Date, public pid: string) {}

    static fromJson(json: any): ProductHistory {
        return new ProductHistory(new Date(json.assembled_at), json.pid);
    }
}

export interface IPart {
    tid: string;
    part_label: string;
    part_type: string;
}
