import React, { useCallback } from 'react';
import { FiltersGroup } from './FiltersGroup';
import { Box, Popper, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EolErrorResponse } from '../../../../domain/services/EolErrorService';
import { FilterHeader } from '../../typography/textStyles';
import Translation from '../../translation/Translation';
import { Checkbox } from '../../inputs/Checkbox';
import spacing from '../../../style/spacing';
import { Autocomplete } from '@mui/material';
import { Tag } from './TextFieldWithTags';
import { ThemeProvider, createTheme } from '@mui/material/styles';

interface IQualityChecksFiltersGroupProps {
    allEolErrors: EolErrorResponse[];
    eolErrors: number[];
    eolPassed: boolean;
    eolNotPassed: boolean;
    onEolPassedChanged: (newValue: boolean) => void;
    onEolNotPassedChanged: (newValue: boolean) => void;
    onEolErrorChanged: (newValue: number[]) => void;
}
const groupByLogic = (option: EolErrorResponse) => {
    if (option.error_number_specific < 1000) {
        return '0-999';
    } else if (option.error_number_specific < 10000) {
        return (
            option.error_number_specific.toString()[0] + '000 - ' + option.error_number_specific.toString()[0] + '999'
        );
    } else if (option.error_number_specific < 100000) {
        return '10000 - 99999';
    } else {
        return '100000+';
    }
};

export const QualityChecksFiltersGroup: React.FC<IQualityChecksFiltersGroupProps> = ({
    allEolErrors,
    eolErrors,
    eolPassed,
    eolNotPassed,
    onEolPassedChanged,
    onEolNotPassedChanged,
    onEolErrorChanged,
}) => {
    const { t } = useTranslation();

    const theme = createTheme({
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    groupLabel: {
                        zIndex: 2,
                    },
                },
            },
        },
    });

    const PopperModifier = useCallback((props: any) => {
        return <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />;
    }, []);

    return (
        <FiltersGroup title="">
            <FilterHeader>
                <Translation i18nKey="productDetails.verification" />
            </FilterHeader>

            <Checkbox
                dataTestid="quality-passed-checkbox"
                checked={eolPassed}
                onValueChange={onEolPassedChanged}
                style={{ marginBottom: spacing(1) }}
            >
                <Translation i18nKey="productsFilter.quality.passed" />
            </Checkbox>
            <Checkbox
                dataTestid="quality-notpassed-checkbox"
                checked={eolNotPassed}
                onValueChange={onEolNotPassedChanged}
                style={{ marginBottom: spacing(3) }}
            >
                <Translation i18nKey="productsFilter.quality.notPassed" />
            </Checkbox>
            <FilterHeader>
                <Translation i18nKey="productsFilter.error.title" />
            </FilterHeader>

            <ThemeProvider theme={theme}>
                <Autocomplete
                    data-testid="autocomplete-dropdown"
                    style={{ marginBottom: spacing(1) }}
                    value={allEolErrors.filter((eolError) => eolErrors.includes(eolError.error_number_specific))}
                    multiple
                    disableClearable
                    id="checkboxes-tags"
                    PopperComponent={PopperModifier}
                    options={allEolErrors}
                    groupBy={groupByLogic}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.error_number_specific.toString() + option.error_text}
                    renderTags={(value, props) => {
                        return value.map((option, index) => (
                            <>
                                {/* you can uncomment that line if you want to show the tags/chips inside the selectbox */}
                                {/* <Chip {...props({ index })} color="primary" size="small" label={option.error_number_specific} /> */}
                            </>
                        ));
                    }}
                    // here the logic could be implemented to also true/false the check of the checckboy by going through the list of eolerros

                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                style={{
                                    // This style element is a workaround to force the Popper behaviour which is required
                                    // To make the logic work only the checkbox can be clickable and not the text.
                                    // But the user should not know that
                                    // Therefore the Checkbox is styled bigger then the user sees it and the text is behind it
                                    // in the end the user does not notice anything
                                    width: spacing(78),
                                    paddingTop: spacing(1),
                                    paddingBottom: spacing(1),
                                    marginTop: spacing(-1),
                                    marginBottom: spacing(-1),
                                    marginRight: spacing(-74),
                                    zIndex: 1,
                                }}
                                checked={selected}
                            />
                            {`(${option.error_number_specific}) ${option.error_text}`}
                        </li>
                    )}
                    onChange={(event, checkedOptions, reason, details) => {
                        if (Object.prototype.toString.call(event.target) === '[object HTMLElement]') {
                            onEolErrorChanged(checkedOptions.map((option) => option.error_number_specific));
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder={t('productsFilter.error.text')}
                            size="small"
                        />
                    )}
                />
            </ThemeProvider>

            <Box>
                {eolErrors.map((data, index) => {
                    return (
                        <Tag
                            data={data.toString()}
                            key={index}
                            handleDelete={() =>
                                onEolErrorChanged(eolErrors.filter((errorNumber) => errorNumber !== data))
                            }
                        />
                    );
                })}
            </Box>
        </FiltersGroup>
    );
};
