import moment from 'moment';
import {
    TimeFrame,
    Shift,
    LastMonthVWDefinition,
    LastWeekVWDefinition,
    SHIFT_TIME,
    YesterdayVWDefinition,
    Today,
    TodayVWDefinition,
    Yesterday,
} from './timeFrames';

export const defaultDates = {
    fromDate: moment().startOf('date').toDate(),
    toDate: moment().endOf('date').toDate(),
    timeFrame: new TimeFrame(moment().startOf('date'), moment().endOf('date')),
};

/**
 * Attempts to load the last filters used by the user in this browser.
 * If it fails returns the defaultValue passed as parameter.
 * @param contextId
 * @param defaultValue: the default value to be returned in case the loading fails.
 * @params excludeShifts: don't return shift types.
 */
export const loadLastTimeFrame = (defaultValue: any = defaultDates.timeFrame, excludeShifts: boolean = false) => {
    try {
        const previousFilters = localStorage.getItem(`home-timeframe`);
        if (previousFilters == null) {
            return defaultValue;
        }
        const decodedFilters = JSON.parse(previousFilters);
        decodedFilters.fromDate = decodedFilters.fromDate !== null ? new Date(decodedFilters.fromDate) : null;
        decodedFilters.toDate = decodedFilters.toDate !== null ? new Date(decodedFilters.toDate) : null;
        decodedFilters.baseDate = decodedFilters.baseDate !== null ? new Date(decodedFilters.baseDate) : null;
        switch (decodedFilters.name) {
            case 'timeFrames.today':
                if (excludeShifts) {
                    return new Today();
                } else {
                    return new TodayVWDefinition();
                }
            case 'timeFrames.custom':
                if (excludeShifts) {
                    // No matter the hours set in a tab with shifts, we will look for the whole day/days
                    return new TimeFrame(
                        moment(decodedFilters.fromDate).startOf('date'),
                        moment(decodedFilters.toDate).endOf('date')
                    );
                } else {
                    return new TimeFrame(decodedFilters.fromDate, decodedFilters.toDate);
                }
            case 'timeFrames.lastWeek':
                return new LastWeekVWDefinition();
            case 'timeFrames.yesterday':
                if (excludeShifts) {
                    return new Yesterday();
                } else {
                    return new YesterdayVWDefinition();
                }
            case 'timeFrames.lastMonth':
                return new LastMonthVWDefinition();
            case 'timeFrames.earlyShift':
                if (excludeShifts) {
                    return defaultValue;
                } else {
                    return new Shift(SHIFT_TIME.EARLY);
                }
            case 'timeFrames.lateShift':
                if (excludeShifts) {
                    return defaultValue;
                } else {
                    return new Shift(SHIFT_TIME.LATE);
                }
            case 'timeFrames.nightShift':
                if (excludeShifts) {
                    return defaultValue;
                } else {
                    return new Shift(SHIFT_TIME.NIGHT);
                }
            default:
                return new TimeFrame(decodedFilters.fromDate, decodedFilters.toDate);
        }
    } catch (e) {
        return defaultValue;
    }
};

/**
 * Stores the filters into the localStorage.
 * @param contextId
 * @param newValue
 */
export const storeLastTimeFrame = (newValue: any) => {
    try {
        localStorage.setItem(`home-timeframe`, JSON.stringify({ ...newValue, name: newValue.name }));
    } catch (e) {
        localStorage.removeItem(`home-timeframe`);
    }
};
