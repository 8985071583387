import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { backgroundColor, primary, secondary } from './colors';

const theme = createTheme(
    adaptV4Theme({
        palette: {
            primary: {
                main: primary,
            },
            secondary: {
                main: secondary,
            },
            background: {
                default: backgroundColor,
            },
        },
        overrides: {
            MuiLinearProgress: {
                root: {},
            },
            MuiAppBar: {
                root: {
                    zIndex: 1300,
                },
            },
            MuiDrawer: {
                root: {
                    width: 100,
                },
                paper: {
                    paddingTop: 70,
                    zIndex: 1200,
                },
            },
        },
    })
);

export default theme;
