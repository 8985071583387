import React from 'react';
import { Part } from '../../../domain/models/Part';
import { Ball } from '../../base/icons/Ball';
import { FlexContainer } from '../../base/layout/Flex';
import { Border, ListItem } from '../../base/lists/ListItem';
import Translation from '../../base/translation/Translation';
import { Body1, H4 } from '../../base/typography/textStyles';

export const PartAdditionalInfo = ({ part }: { part: Part }) => {
    return (
        <>
            <H4 noGutter={true}>
                <Translation i18nKey="partDetails.partDetails" />
            </H4>
            {part.part_reprocess ? (
                <FlexContainer alignItems="center">
                    <Ball borderRadius={12} size={24} warning={true} />
                    <Body1 style={{ paddingLeft: '10px' }}>
                        <Translation i18nKey="partDetails.reprocess" />
                    </Body1>
                </FlexContainer>
            ) : (
                ''
            )}
            <ListItem border={Border.none}>
                <Body1 noGutter={true}>
                    <Translation i18nKey="partDetails.partId" values={{ tid: part.tid }} />
                </Body1>
                <Body1 noGutter={true}>
                    <Translation i18nKey="partDetails.partType" values={{ type: part.part_type_name }} />
                </Body1>
                <Body1 noGutter={true}>
                    <Translation i18nKey="partDetails.label" values={{ label: part.part_label }} />
                </Body1>
            </ListItem>
        </>
    );
};
