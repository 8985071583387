import { useEffect, useState } from 'react';
import { Engine } from '../models/Engine';
import EnginesService, { IEngineFilters } from '../services/EngineService';

export interface ISorting {
    column: String | null;
    direction: String | null;
}

export interface UseEnginesState {
    engines: Engine[] | null;
    filters: IEngineFilters;
    offset: number;
    limit: number;
    total: number;
    loading: boolean;
    sorting: ISorting | null;
    getNextPage: () => void;
    getPreviousPage: () => void;
    setFilters: (filters: IEngineFilters) => void;
    setSorting: (sort: ISorting) => void;
    setPage: (page: number) => void;
}

/**
 * Custom hook
 * Downloads a page of Engines given the filters, offset and limit.
 * Returns handlers for navigating between the pages and updating the filters.
 * @param initialFilters
 * @param initialOffset
 * @param limit
 * @param contextId
 */

export const useEngines = ({
    initialFilters,
    currentSorting = null,
    initialOffset = 0,
    limit = 20,
}: {
    initialFilters: IEngineFilters;
    currentSorting?: ISorting | null;
    initialOffset?: number;
    limit?: number;
}): UseEnginesState => {
    const [offset, setOffset] = useState(initialOffset);
    const [total, setTotal] = useState(0);
    const [engines, setEngines] = useState<Engine[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [filters, innerSetFilters] = useState(initialFilters);
    const [sorting, setSortingProps] = useState<ISorting | null>(currentSorting);

    useEffect(() => {
        setLoading(true);
        const enginesService = new EnginesService();
        enginesService.getEngines(filters, offset, limit, sorting).then((response) => {
            setTotal(response.total);
            setEngines(response.engines);
            setLoading(false);
        });
    }, [offset, limit, filters, sorting]);

    const getNextPage = () => {
        setOffset(offset + limit);
    };

    const getPreviousPage = () => {
        setOffset(Math.max(offset - limit, 0));
    };

    const setFilters = (filters: IEngineFilters) => {
        innerSetFilters(filters);
        setOffset(0);
    };

    const setSorting = (sort: ISorting) => {
        setSortingProps(sort);
    };

    const setPage = (page: number) => {
        setOffset((page - 1) * limit);
    };

    return {
        engines,
        filters,
        offset,
        limit,
        total,
        loading,
        sorting,
        getNextPage,
        getPreviousPage,
        setFilters,
        setSorting,
        setPage,
    };
};
