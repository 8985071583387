import React from 'react';
import { ConfidentialityLevel } from '../../../domain/config/confidentiality';
import IOthersProps from '../IOthersProps';
import { Container } from './Container';
import Footer from './Footer';
import NavigationBar from '../navigation/NavigationBar';
import TopBar from '../topbar/TopBar';
import { LinearProgress } from '../loaders/LinearProgress';
import spacing from '../../style/spacing';

interface IPageProps extends IOthersProps {
    title: string;
    loading?: boolean;
    confidentialityLevel?: ConfidentialityLevel;
}

const Page: React.FC<IPageProps> = ({
    title,
    loading = false,
    children,
    confidentialityLevel = ConfidentialityLevel.Internal,
}) => {
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <div style={{ flex: 1 }}>
                    <TopBar title={title} loading={loading} confidentialityLevel={confidentialityLevel} />
                    {loading ? (
                        <LinearProgress variant="indeterminate" color="secondary" />
                    ) : (
                        <LinearProgress variant="determinate" color="primary" value={100} />
                    )}
                    <NavigationBar />

                    <Container
                        style={{
                            margin: spacing(3),
                            marginLeft: spacing(4),
                            marginRight: spacing(3),
                            position: 'relative',
                        }}
                    >
                        {children}
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Page;
