import React from 'react';

import { useNavigate } from 'react-router-dom';
import Page from '../../base/layout/Page';
import { useTranslation } from 'react-i18next';
import { IMachinesFilter } from '../../../domain/services/MachinesService';
import { defaultPlantId } from '../../../domain/config/plantInfo';
import { ISorting, useMachines } from '../../../domain/hooks/useMachines';
import { Machine } from '../../../domain/models/Machine';
import { getQueryString } from '../../utils/URLService';
import MachinesBrowser from './MachinesBrowser';

interface IQueryMachinesFilters {
    tid?: string;
    machine?: string[];
    timestamp?: string;
    date_from?: string;
    date_to?: string;
    lines?: string[];
    sorting?: ISorting;
    sort?: string;
    limit?: string;
    offset?: string;
}

export const dataObjectToQueryObject = (
    filters: IMachinesFilter,
    sorting: ISorting | null,
    limit?: number,
    offset?: number
): IQueryMachinesFilters => {
    return {
        sort: sorting ? `${sorting.column}:${sorting.direction}` : '',
        limit: limit?.toString(),
        offset: offset?.toString(),
        lines: filters.lines ? filters.lines : [],
        machine: filters.machines ? filters.machines : [],
    };
};

const MachinesPage = ({ searchParams }: { searchParams: IQueryMachinesFilters }) => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const queryObjectDataObject = (searchParams: IQueryMachinesFilters): IMachinesFilter => {
        return {
            machines: searchParams.machine ? searchParams.machine.map(String) : [],
            lines: searchParams.lines ? searchParams.lines.map(String) : [],
            plantId: defaultPlantId,
        };
    };

    const queryToSortingObject = (searchParams: IQueryMachinesFilters): ISorting | null => {
        const currentSorting: ISorting | null = searchParams.sort
            ? {
                  column: searchParams.sort[0].substring(0, searchParams.sort[0].indexOf(':')),
                  direction: searchParams.sort[0].substring(searchParams.sort[0].indexOf(':') + 1),
              }
            : null;
        return currentSorting;
    };

    const onMachineClicked = (machine: Machine) => {
        navigate(`/machines/${machine.nr}`);
    };

    const {
        machines,
        filters,
        offset,
        limit,
        total,
        loading,
        sorting,
        getNextPage,
        getPreviousPage,
        setFilters,
        setSorting,
        setPage,
    } = useMachines(
        queryObjectDataObject(searchParams),
        queryToSortingObject(searchParams),
        searchParams.offset ? parseInt(searchParams.offset) : undefined,
        searchParams.limit ? parseInt(searchParams.limit) : undefined
    );

    const productLines = ['Line1', 'Line2', 'Line3', 'Line4'];

    const onSortClicked = (column: string) => {
        let newSorting = null;
        if (sorting === null || sorting.column !== column) {
            newSorting = { column: column, direction: 'asc' };
        } else {
            newSorting = { column: column, direction: sorting.direction === 'asc' ? 'desc' : 'asc' };
        }

        setSorting(newSorting);
        navigate(`/machines?${getQueryString(dataObjectToQueryObject(filters, newSorting, limit, offset))}`);
    };

    const onSearchClicked = (filters: IMachinesFilter) => {
        setFilters(filters);
        navigate(`/machines?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, 0))}`);
        // The offset value has to be 0, otherwise it can happen that when a user is on page 4 and add a new filter, where there is no longer a page 4,,
        // it does not show any results, because the offset did not reset itself
    };
    const onNextPageClicked = () => {
        navigate(`/machines?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, offset + limit))}`);
        getNextPage();
    };

    const onPrevPageClicked = () => {
        navigate(`/machines?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, offset - limit))}`);
        getPreviousPage();
    };
    const onSetPageClicked = (page: number) => {
        navigate(`/machines?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, (page - 1) * limit))}`);
        setPage(page);
    };

    return (
        <Page title={t('machines.title')} loading={loading}>
            <MachinesBrowser
                productLines={productLines}
                total={total}
                offset={offset}
                limit={limit}
                filters={filters}
                sorting={sorting}
                machinesPage={machines || []}
                onSearchClicked={onSearchClicked}
                isLoading={loading}
                onMachineClicked={onMachineClicked}
                onPrevPageClicked={onPrevPageClicked}
                onNextPageClicked={onNextPageClicked}
                setSorting={onSortClicked}
                setPage={onSetPageClicked}
            />
        </Page>
    );
};

export default MachinesPage;
