import React from 'react';
import EOLVerification from '../../../../domain/models/EOLVerification';
import { TableCell, TableRow } from '../../../base/table/Table';
import Translation from '../../../base/translation/Translation';
import { loadTimezoneFromAppSettings, toSettingsTimezoneDate, toSettingsTimezoneTime } from '../../../base/utils';
import { EOLStatus } from './EolVerificationsTableCells';
import ISorting from '../../../../domain/interfaces/ISorting';
import { primary } from '../../../style/colors';
import { LinkIcon } from '../../../base/icons/icons';
import Tooltip from '../../../base/tooltip/tooltip';
import { useTranslation } from 'react-i18next';
import spacing from '../../../style/spacing';
import { Link } from 'react-router-dom';

export const EolVerificationRow = ({
    eolVerification,
    onClick,
    dataTestid,
}: {
    eolVerification: EOLVerification;
    onClick: () => void;
    dataTestid?: string;
}) => {
    const stringCutter = (string: string, length: number): string => {
        return string.length <= length ? string : string.substring(0, length) + '...';
    };
    const addSpacesForShorterNumbers = (errorCode: number): string => {
        let errorCodeAsString = '(' + String(errorCode);
        for (let i = errorCodeAsString.length - 1; i < 5; i++) {
            errorCodeAsString = '\u2007' + errorCodeAsString;
        }
        return errorCodeAsString + ')';
    };

    const { t } = useTranslation();

    const handleClick = () => {
        let text: string | undefined;
        text = window.getSelection()?.toString();
        if (text !== undefined && text.length > 0) {
            return;
        }
        onClick();
    };

    const onVerificationClicked = (verification: EOLVerification) => {
        return `/products/${verification.pid}/verification/${verification.id}`;
    };

    return (
        <TableRow dataTestid={dataTestid} onClick={handleClick} style={{ cursor: 'pointer' }}>
            <TableCell>{eolVerification.numberOfTests}</TableCell>
            <TableCell>{eolVerification.pid}</TableCell>
            <TableCell>{eolVerification.productType}</TableCell>
            <TableCell>{toSettingsTimezoneDate(eolVerification.date)}</TableCell>
            <TableCell>{toSettingsTimezoneTime(eolVerification.date)}</TableCell>
            <TableCell>{eolVerification.station}</TableCell>
            <TableCell alignment="center">
                <EOLStatus status={eolVerification.status} tooltipPrefix="eolVerifications" />
            </TableCell>
            <TableCell alignment="center">
                <Tooltip tooltipMessage={t('eolVerifications.linktip')}>
                    <Link to={onVerificationClicked(eolVerification)}>
                        <LinkIcon style={{ color: primary }} />
                    </Link>
                </Tooltip>
            </TableCell>
            <TableCell>
                <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                    {eolVerification.errors
                        .filter(
                            (error, i) =>
                                eolVerification.errors.findIndex(
                                    (a) => a.error_type_number_specific === error.error_type_number_specific
                                ) === i
                        ) //filters Errors with the same ErrorType (error_type_number_specific)out
                        .map((error) => (
                            <li style={{ overflow: 'hidden' }} key={error.error_sequence}>
                                <div style={{ float: 'left', width: '70px' }}>{`${addSpacesForShorterNumbers(
                                    error.error_type_number_specific
                                )}`}</div>
                                <div style={{ overflow: 'hidden' }}>{`${stringCutter(error.error_type_text, 30)}`}</div>
                            </li>
                        ))}
                </ul>
            </TableCell>
        </TableRow>
    );
};
export const EolVerificationHeader = ({
    sorting,
    setSorting,
}: {
    sorting: ISorting | null;
    setSorting: (column: string) => void;
}) => {
    const getColumnOrder = (column: String) => {
        if (sorting === null || column !== sorting.column) {
            return 'unsorted';
        }
        return sorting.direction === 'asc' ? 'up' : 'down';
    };
    return (
        <>
            <TableRow header style={{ color: primary }}>
                <TableCell>
                    <Translation i18nKey="eolVerifications.number" />
                </TableCell>
                <TableCell sortable={true} order={getColumnOrder('pid')} onClick={() => setSorting('pid')}>
                    <Translation i18nKey="eolVerifications.productId" />
                </TableCell>
                <TableCell sortable={true} order={getColumnOrder('pid_type')} onClick={() => setSorting('pid_type')}>
                    <Translation i18nKey="eolVerifications.productType" />
                </TableCell>
                <TableCell
                    sortable={true}
                    order={getColumnOrder('status_timestamp')}
                    onClick={() => setSorting('status_timestamp')}
                >
                    <Translation i18nKey="eolVerifications.date" />
                </TableCell>
                <TableCell
                    sortable={true}
                    order={getColumnOrder('status_timestamp')}
                    onClick={() => setSorting('status_timestamp')}
                >
                    <Translation i18nKey="eolVerifications.time" />
                    <div style={{ marginLeft: spacing(1) }}>
                        {loadTimezoneFromAppSettings().label.split(' ').shift()}
                    </div>
                </TableCell>
                <TableCell
                    sortable={true}
                    order={getColumnOrder('station_name')}
                    onClick={() => setSorting('station_name')}
                >
                    <Translation i18nKey="eolVerifications.station" />
                </TableCell>
                <TableCell
                    sortable={true}
                    order={getColumnOrder('status')}
                    alignment="center"
                    onClick={() => setSorting('status')}
                >
                    <Translation i18nKey="eolVerifications.status" />
                </TableCell>
                <TableCell alignment="center">
                    <Translation i18nKey="eolVerifications.link" />
                </TableCell>
                <TableCell>
                    <Translation i18nKey="eolVerifications.errors" />
                </TableCell>
            </TableRow>
        </>
    );
};
