import React from 'react';
import { Cell, Label, Legend, Pie, PieChart as PieReChart, Tooltip } from 'recharts';
import { ResponsiveChartContainer } from './ResponsiveChartContainer';
import { pieChartColors } from '../../style/colors';
import IStyledProps from '../IStyledProps';
import { useTranslation } from 'react-i18next';

function CustomLabel(props: any) {
    const { cx, cy } = props.viewBox;
    const { t } = useTranslation();

    return (
        <>
            <text
                x={cx}
                y={cy - 5}
                fill="rgba(0, 0, 0, 0.87)"
                className="recharts-text recharts-label"
                textAnchor="middle"
                dominantBaseline="central"
            >
                <tspan alignmentBaseline="middle" fontSize="31.5px">
                    {t(props.value2)}
                </tspan>
            </text>
            <text
                x={cx}
                y={cy + 20}
                fill="rgba(0, 0, 0, 0.54)"
                className="recharts-text recharts-label"
                textAnchor="middle"
                dominantBaseline="central"
            >
                <tspan fontSize="12.3px">{t(props.value1)}</tspan>
            </text>
        </>
    );
}
interface IPieChartProps extends IStyledProps {
    style?: any;
    data: any;
    title?: string;
    total: number;
    subTitle?: string;
    isDonut?: boolean;
    showLegend?: boolean;
    showLabels?: boolean;
    onClick?: (data: any, index: number) => void;
}

const PieChart: React.FC<IPieChartProps> = ({
    data,
    onClick,
    total,
    isDonut = false,
    showLegend = false,
    showLabels = false,
    style = {},
    title,
    subTitle,
}: IPieChartProps) => (
    <ResponsiveChartContainer style={style}>
        <PieReChart>
            <Tooltip formatter={(value) => (((value as unknown as number) / total) * 100).toFixed(2) + '%'} />
            {showLegend && <Legend layout="vertical" verticalAlign="middle" align="right" />}
            <Pie
                label={showLabels}
                onClick={onClick}
                dataKey="value"
                data={data}
                cx="50%"
                cy="50%"
                outerRadius={'100%'}
                innerRadius={isDonut ? '75%' : 0}
            >
                {data.map((entry: any, index: number) => (
                    <Cell
                        key={`cell-${index}`}
                        fill={entry.color ? entry.color : pieChartColors[index % pieChartColors.length]}
                    />
                ))}

                <Label position="center" content={<CustomLabel value1={subTitle} value2={title} />}></Label>
            </Pie>
        </PieReChart>
    </ResponsiveChartContainer>
);

export default PieChart;
