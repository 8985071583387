import React from 'react';
import { Bars } from 'react-loader-spinner';
import theme from '../../style/theme';
import IOthersProps from '../IOthersProps';
import { IColorProps } from '../IColorProps';

const colors: any = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
};
const defaultColor = theme.palette.primary.main;

export interface IBarsProgressProps extends IOthersProps, IColorProps {
    size?: number;
}

export const BarsProgress: React.FC<IBarsProgressProps> = ({ color = 'primary', size = 50, ...props }) => (
    <Bars color={colors.hasOwnProperty(color) ? colors[color] : defaultColor} width={size} height={size} {...props} />
);
