import React from 'react';

export const Ball = ({ borderRadius, size, warning }: { borderRadius: number; size: number; warning: boolean }) => {
    const ballStyle = {
        border: `1px solid black`,
        background: 'transparent',
        borderRadius: `${borderRadius}px`,
        height: `${size}px`,
        width: `${size}px`,
        display: 'flex',
        fontSize: '16pt',
        fontWeight: 700,
        alignItems: 'center',
        justifyContent: 'center',
    };
    return <div style={ballStyle}> {warning ? '!' : null} </div>;
};
