import { toDateString } from '../utils';
import { IInputProps } from './Input';
import React from 'react';
import { GroupuiDatePicker } from '@group-ui/group-ui-react';

interface IDatePickerProps extends IInputProps {
    onDateChange: (event: any) => void;
}

export const DatePicker: React.FC<IDatePickerProps> = ({ value = '', ...props }) => {
    const { disabled } = props;

    const handleDateChange = (event: any) => {
        const newDateValue: string = event.target.value;
        props.onDateChange(newDateValue);
    };

    const formatValue: (value: string | Date | null) => string = (value: string | Date | null): string => {
        if (value == null) return '';

        if (value instanceof Date) {
            return toDateString(value);
        } else {
            return value;
        }
    };

    return (
        <GroupuiDatePicker
            disabled={disabled}
            value={formatValue(value)}
            onGroupuiChange={handleDateChange}
            {...props}
        />
    );
};
