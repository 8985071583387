import React from 'react';
import { useTranslation } from 'react-i18next';
import { IMachineDetailsFilters } from '../../../domain/services/ProductionPlantsService';
import { toTimeFrames } from '../../../domain/utils/timeFrames';
import FiltersArea from '../../base/filtering/filtersArea/FiltersArea';
import DateIntervalFiltersGroup from '../../base/filtering/filtersGroups/DateIntervalFiltersGroup';
import TextFieldWithTags from '../../base/filtering/filtersGroups/TextFieldWithTags';
import { loadTimezoneFromAppSettings } from '../../base/utils';
import moment from 'moment';

export const MachineDetailsFiltersForm: React.FC<{
    filters: IMachineDetailsFilters;
    onSearchClicked: (filters: IMachineDetailsFilters) => void;
    isLoading: boolean;
    innerFilters: IMachineDetailsFilters;
    onFieldValueChange: (arg: string) => (arg: any) => void;
    disableDateFilter?: boolean;
}> = ({ filters, onSearchClicked, isLoading, innerFilters, onFieldValueChange, disableDateFilter }) => {
    const { t } = useTranslation();
    const now = moment.tz(new Date(), loadTimezoneFromAppSettings().value);

    const timeFrames: any = toTimeFrames(now);
    const { tids } = innerFilters;
    const { machineSections } = innerFilters;

    const getNewFilters = (innerFilters: any) => {
        const { selectedTimeFrame, fromDate, toDate, ...other } = innerFilters;
        const newFilters = {
            fromDate: selectedTimeFrame.fromDate,
            toDate: selectedTimeFrame.toDate,
            ...other,
        };
        return newFilters;
    };

    const onSubmit = () => {
        onSearchClicked(getNewFilters(innerFilters));
    };

    const filtersChanged = JSON.stringify(getNewFilters(innerFilters)) !== JSON.stringify(filters);
    const isTimeFrameValid = getNewFilters(innerFilters).fromDate <= getNewFilters(innerFilters).toDate;

    return (
        <FiltersArea
            title="machineDetails.partsTable_filtersForm_title"
            onSubmit={onSubmit}
            searchDisabled={isLoading || !filtersChanged || !isTimeFrameValid}
        >
            <DateIntervalFiltersGroup
                label={t('machineDetails.partsTable_filtersForm_processingDate')}
                onTimeFrameChange={onFieldValueChange('selectedTimeFrame')}
                timeFrame={innerFilters.selectedTimeFrame}
                timeFrames={timeFrames}
                hasCustomTime={true}
                disabled={disableDateFilter}
            />

            <TextFieldWithTags
                key={'tid-filter'}
                title={t('machines.filter.part.title')}
                placeholderTextNoSelection={t('machines.filter.part.noSelection')}
                placeholderTextSelection={t('machines.filter.part.selection')}
                values={tids}
                onSelectionChange={(types) => {
                    onFieldValueChange('tids')(types);
                }}
                changeFontSize={true}
            />

            <TextFieldWithTags
                key={'spindel-filter'}
                title={t('machines.filter.spindel.title')}
                placeholderTextNoSelection={t('machines.filter.spindel.placeholder')}
                placeholderTextSelection={t('machines.filter.spindel.placeholder')}
                values={machineSections}
                onSelectionChange={onFieldValueChange('machineSections')}
                changeFontSize={true}
            />
        </FiltersArea>
    );
};
