import React, { useEffect, useRef, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import IChildrenProps from '../IChildrenProps';
import IStyledProps from '../IStyledProps';

interface IResponsiveChartContainerProps extends IChildrenProps, IStyledProps {}

export const ResponsiveChartContainer: React.FC<IResponsiveChartContainerProps> = ({ children, style }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState<any | null>(null);
    const onResize = () => {
        if (containerRef.current) {
            setSize({ width: containerRef.current.clientWidth, height: containerRef.current.clientHeight });
        }
    };

    /**
     * This effect makes sure the size of all the width and height are updated every time the
     * window is resize.
     */
    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [containerRef]);

    /**
     * This effect makes sure that the chart is rendered when the container is
     * first rendered in the page (the container.ref passes from null to non null).
     */
    useEffect(() => {
        const readyTimeout = setTimeout(() => {
            while (containerRef.current === null) {}
            setSize({ width: containerRef.current.clientWidth, height: containerRef.current.clientHeight });
        }, 250);

        return () => {
            clearTimeout(readyTimeout);
        };
    }, [containerRef]);

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%', ...style }}>
            {size && (
                <ResponsiveContainer width={size.width} height={size.height}>
                    {children}
                </ResponsiveContainer>
            )}
        </div>
    );
};
