import { Graph } from './Graph';
import { Edge } from './Edge';
import { Node } from './Node';
import { Machine } from '../Machine';
import { PartTypecol } from '../PartType';

const machines = [
    new Machine('9413593', 'Hartdrehen', 'Felsomat', 'mock', [3, 4]),
    new Machine('9413780', 'Zw.Waschen', 'Elwema', 'mock', [1]),
    new Machine('9413892', 'Schrauben/Kennz.', 'PIA', 'mock', [1]),
    new Machine('9413894', 'Fügen/Kennz.', 'PIA', 'mock', [1]),
    new Machine('9413546', 'LVZ-Schleifen', 'Liebherr', 'mock', [1]),
    new Machine('9413545', 'LVZ-Schleifen', 'Liebherr', 'mock', [1]),
    new Machine('9415083', 'LVZ-Schleifen', 'Liebherr', 'mock', [1]),
    new Machine('9413784', 'Waschen', 'Elwema', 'mock', [1]),
    new Machine('9415085', 'Waschen', 'Elwema', 'mock', [1]),
    new Machine('9413601', 'Kurbel-Montage', 'Grob', 'mock', [1, 2]),
    new Machine('9413602', 'Kurbel-Montage', 'Grob', 'mock', [1, 2]),
    new Machine('9413539', 'Richten', 'MAE', 'mock', [1]),
    new Machine('9413589', 'Hartdrehen', 'Weisser', 'mock', [1]),
    new Machine('9413590', 'Hartdrehen', 'Weisser', 'mock', [1]),
    new Machine('9413540', 'Schleifen', 'Junker', 'mock', [1]),
    new Machine('9413541', 'Schleifen', 'Junker', 'mock', [1]),
    new Machine('9413782', 'Zw.Waschen', 'Elwema', 'mock', [1]),
    new Machine('9413542', 'LVZ-Schleifen', 'Kapp', 'mock', [1, 2]),
    new Machine('9413543', 'LVZ-Schleifen', 'Kapp', 'mock', [3, 4]),
    new Machine('9414304', 'LVZ-Schleifen', 'Kapp', 'mock', [5, 6]),
    new Machine('9415433', 'LVZ-Schleifen', 'Kapp', 'mock', [7, 8]),
    new Machine('9413882', 'Messung', 'jedes 48. Teil', 'mock', [1]),
    new Machine('9413592', 'Hartdrehen', 'Felsomat', 'mock', [1, 2]),
    new Machine('9413544', 'LVZ-Schleifen', 'Liebherr', 'mock', [1]),
    new Machine('9413860', 'LVZ-Schleifen', 'Liebherr', 'mock', [1]),
    new Machine('9414396', 'Hartdrehen', 'Felsomat', 'mock', [5, 6]),
    new Machine('9413783', 'Waschen', 'Elwema', 'mock', [1]),
    new Machine('9413785', 'Waschen', 'Elwema', 'mock', [1]),
    new Machine('9413911', 'Auflegen', 'ABB', 'mock', [1]),
    new Machine('9413912', 'Auflegen', 'ABB', 'mock', [1]),
    new Machine('9413913', 'Auflegen', 'ABB', 'mock', [1]),
    new Machine('9413914', 'Auflegen', 'ABB', 'mock', [1]),
    new Machine('9415010', 'Beladen', 'Wekal', 'mock', [1]),
    new Machine('9415012', 'Beladen', 'Wekal', 'mock', [1]),
    new Machine('9415014', 'Beladen', 'Wekal', 'mock', [1]),
    new Machine('9415011', 'Entladen', 'Wekal', 'mock', [1]),
    new Machine('9415013', 'Entladen', 'Wekal', 'mock', [1]),
    new Machine('9415015', 'Entladen', 'Wekal', 'mock', [1]),
    new Machine('9413940_1', 'Kennzeichnen', 'Nill & Ritz', 'mock', [1]), // Correct number is "9413940", but in the data it's "9413940_1"
    new Machine('01', 'START', 'ATW', 'mock', [1]),
    new Machine('02', 'START', 'AR', 'mock', [1]),
    new Machine('03', 'START', 'TW', 'mock', [1]),
    new Machine('04', 'START', 'Festrad', 'mock', [1]),
    new Machine('05', 'END', 'ZSB', 'mock', [1]),
    new Machine('06', 'END', 'ATW', 'mock', [1]),
    new Machine('001', 'EXTRA', 'Ausgleichsgehäuse', 'mock', [1]),
    new Machine('002', 'EXTRA', 'Kegelräder', 'mock', [1]),
    new Machine('1', 'STATION', 'Messung QS-Stat.', 'mock', [1]),
    new Machine('2', 'STATION', 'Lager', 'mock', [1]),
];

const getMachine = (nr: string): Machine | undefined => {
    return machines.find((machine) => machine.nr === nr);
};

export const graphs = (partType: string): Graph | undefined => {
    if (partType === 'ATW') {
        const node0: Node = new Node('0', getMachine('01'), { column: 1, row: 1 });
        const node1: Node = new Node('1', getMachine('9413913'), { column: 2, row: 1 });
        const node2: Node = new Node('2', getMachine('9413539'), { column: 3, row: 1 });
        const node3: Node = new Node('3', getMachine('9413589'), { column: 4, row: 1 });
        const node4: Node = new Node('4', getMachine('9413540'), { column: 5, row: 1 });
        const node5: Node = new Node('5', getMachine('9413541'), { column: 5, row: 2 });
        const node6: Node = new Node('6', getMachine('9413782'), { column: 6, row: 1 });
        const node7: Node = new Node('7', getMachine('9413542'), { column: 7, row: 1 });
        const node8: Node = new Node('8', getMachine('9413543'), { column: 7, row: 2 });
        const node9: Node = new Node('9', getMachine('9413784'), { column: 8, row: 1 });
        const node10: Node = new Node('10', getMachine('9413940_1'), { column: 10, row: 1 });
        const node11: Node = new Node('11', getMachine('9415010'), { column: 11, row: 1 });
        const node12: Node = new Node('12', getMachine('9415011'), { column: 13, row: 1 });
        const node13: Node = new Node('13', getMachine('06'), { column: 14, row: 1 });
        const node001: Node = new Node('14', getMachine('1'), { column: 9, row: 1 });
        const node002: Node = new Node('15', getMachine('2'), { column: 12, row: 1 });

        const Edges = [
            new Edge('e0', node0, node1, PartTypecol.atw),
            new Edge('e1', node1, node2, PartTypecol.atw),
            new Edge('e2', node2, node3, PartTypecol.atw),
            new Edge('e3', node2, node4, PartTypecol.atw),
            new Edge('e4', node4, node5, PartTypecol.atw),
            new Edge('e5', node4, node6, PartTypecol.atw),
            new Edge('e6', node6, node7, PartTypecol.atw),
            new Edge('e7', node7, node8, PartTypecol.atw),
            new Edge('e8', node7, node9, PartTypecol.atw),
            new Edge('e9', node9, node001, PartTypecol.atw),
            new Edge('e10', node001, node10, PartTypecol.atw),
            new Edge('e11', node10, node11, PartTypecol.atw),
            new Edge('e12', node11, node002, PartTypecol.atw),
            new Edge('e13', node002, node12, PartTypecol.atw),
            new Edge('e14', node12, node13, PartTypecol.atw),
        ];
        return new Graph('Antriebswelle', new Date(), new Date(), Edges, null);
    } else if (partType === 'AAR' || partType === 'AR') {
        const node0: Node = new Node('0', getMachine('02'), { column: 1, row: 1 });
        const node1: Node = new Node('1', getMachine('9413911'), { column: 2, row: 1 });
        const node2: Node = new Node('2', getMachine('9413593'), { column: 3, row: 1 });
        const node3: Node = new Node('3', getMachine('9414396'), { column: 3, row: 2 });
        const node4: Node = new Node('4', getMachine('9413780'), { column: 4, row: 1 });
        const node5: Node = new Node('5', getMachine('9413892'), { column: 5, row: 1 });
        const node6: Node = new Node('6', getMachine('9413545'), { column: 6, row: 1 });
        const node7: Node = new Node('7', getMachine('9413546'), { column: 6, row: 2 });
        const node8: Node = new Node('8', getMachine('9415083'), { column: 6, row: 3 });
        const node9: Node = new Node('9', getMachine('9415433'), { column: 6, row: 4 });
        const node10: Node = new Node('10', getMachine('9413783'), { column: 7, row: 1 });
        const node11: Node = new Node('11', getMachine('9413601'), { column: 9, row: 1 });
        const node12: Node = new Node('12', getMachine('9413602'), { column: 9, row: 2 });
        const node13: Node = new Node('13', getMachine('9415014'), { column: 10, row: 1 });
        const node14: Node = new Node('14', getMachine('9415015'), { column: 12, row: 1 });
        const node15: Node = new Node('15', getMachine('05'), { column: 13, row: 1 });
        const node001: Node = new Node('16', getMachine('001'), { column: 5, row: 3 });
        const node002: Node = new Node('17', getMachine('002'), { column: 9, row: 3 });
        const node003: Node = new Node('18', getMachine('1'), { column: 8, row: 1 });
        const node004: Node = new Node('19', getMachine('2'), { column: 11, row: 1 });

        const Edges = [
            new Edge('e0', node0, node1, PartTypecol.aar),
            new Edge('e031', node1, node2, PartTypecol.aar),
            new Edge('e1', node2, node3, PartTypecol.aar),
            new Edge('e2', node2, node4, PartTypecol.aar),
            new Edge('e3', node4, node5, PartTypecol.aar),
            new Edge('e4', node5, node6, PartTypecol.aar),
            new Edge('e5', node6, node7, PartTypecol.aar),
            new Edge('e6', node7, node8, PartTypecol.aar),
            new Edge('e7', node8, node9, PartTypecol.aar),
            new Edge('e8', node6, node10, PartTypecol.aar),
            new Edge('e9', node10, node003, PartTypecol.aar),
            new Edge('e10', node003, node11, PartTypecol.aar),
            new Edge('e11', node11, node12, PartTypecol.aar),
            new Edge('e12', node11, node13, PartTypecol.aar),
            new Edge('e13', node13, node004, PartTypecol.aar),
            new Edge('e14', node004, node14, PartTypecol.aar),
            new Edge('e15', node5, node001, PartTypecol.aar),
            new Edge('e16', node12, node002, PartTypecol.aar),
            new Edge('e17', node14, node15, PartTypecol.aar),
        ];
        return new Graph('Achsantriebsrad', new Date(), new Date(), Edges, null);
    } else if (partType === 'ZSB' || partType === 'AGH' || partType === 'FR') {
        const node0: Node = new Node('0', getMachine('03'), { column: 1, row: 1 });
        const node1: Node = new Node('1', getMachine('04'), { column: 1, row: 3 });
        const node2: Node = new Node('2', getMachine('9413914'), { column: 2, row: 1 });
        const node3: Node = new Node('3', getMachine('9413912'), { column: 2, row: 3 });
        const node4: Node = new Node('4', getMachine('9413590'), { column: 3, row: 1 });
        const node5: Node = new Node('5', getMachine('9413592'), { column: 3, row: 3 });
        const node6: Node = new Node('6', getMachine('9413593'), { column: 3, row: 4 });
        const node7: Node = new Node('7', getMachine('9413540'), { column: 4, row: 1 });
        const node8: Node = new Node('8', getMachine('9413541'), { column: 4, row: 2 });
        const node9: Node = new Node('9', getMachine('9413780'), { column: 4, row: 3 });
        const node10: Node = new Node('10', getMachine('9413782'), { column: 5, row: 1 });
        const node11: Node = new Node('11', getMachine('9413543'), { column: 6, row: 1 });
        const node12: Node = new Node('12', getMachine('9414304'), { column: 6, row: 2 });
        const node13: Node = new Node('13', getMachine('9413784'), { column: 7, row: 1 });
        const node14: Node = new Node('14', getMachine('9413894'), { column: 8, row: 2 });
        const node15: Node = new Node('15', getMachine('9413544'), { column: 9, row: 2 });
        const node16: Node = new Node('16', getMachine('9413860'), { column: 9, row: 3 });
        const node17: Node = new Node('17', getMachine('9415433'), { column: 9, row: 4 });
        const node18: Node = new Node('18', getMachine('9415085'), { column: 10, row: 1 });
        const node19: Node = new Node('19', getMachine('9415012'), { column: 12, row: 1 });
        const node20: Node = new Node('20', getMachine('9415013'), { column: 14, row: 1 });
        const node21: Node = new Node('21', getMachine('05'), { column: 15, row: 1 });
        const node001: Node = new Node('22', getMachine('1'), { column: 8, row: 1 });
        const node002: Node = new Node('23', getMachine('1'), { column: 8, row: 3 });
        const node003: Node = new Node('24', getMachine('1'), { column: 11, row: 1 });
        const node004: Node = new Node('25', getMachine('2'), { column: 13, row: 1 });

        const Edges = [
            new Edge('e0', node0, node2, PartTypecol.tw),
            new Edge('e1', node1, node3, PartTypecol.tw),
            new Edge('e2', node2, node4, PartTypecol.tw),
            new Edge('e3', node3, node5, PartTypecol.tw),
            new Edge('e4', node4, node7, PartTypecol.tw),
            new Edge('e5', node5, node9, PartTypecol.tw),
            new Edge('e6', node5, node6, PartTypecol.tw),
            new Edge('e7', node7, node8, PartTypecol.tw),
            new Edge('e8', node7, node10, PartTypecol.tw),
            new Edge('e9', node10, node11, PartTypecol.tw),
            new Edge('e10', node11, node12, PartTypecol.tw),
            new Edge('e11', node11, node13, PartTypecol.tw),
            new Edge('e12', node9, node002, PartTypecol.tw),
            new Edge('e13', node13, node001, PartTypecol.tw),
            new Edge('e14', node13, node001, PartTypecol.tw),
            new Edge('e15', node001, node14, PartTypecol.tw),
            new Edge('e16', node14, node002, PartTypecol.tw),
            new Edge('e17', node14, node15, PartTypecol.tw),
            new Edge('e18', node15, node16, PartTypecol.tw),
            new Edge('e19', node16, node17, PartTypecol.tw),
            new Edge('e20', node15, node18, PartTypecol.tw),
            new Edge('e21', node18, node003, PartTypecol.tw),
            new Edge('e22', node003, node19, PartTypecol.tw),
            new Edge('e23', node19, node004, PartTypecol.tw),
            new Edge('e24', node004, node20, PartTypecol.tw),
            new Edge('e25', node20, node21, PartTypecol.tw),
        ];

        return new Graph('Triebwelle', new Date(), new Date(), Edges, null);
    }
    return undefined;
};
