import { GroupuiTooltip } from '@group-ui/group-ui-react';
import React from 'react';
import IChildrenProps from '../IChildrenProps';
import IStyledProps from '../IStyledProps';
import IOthersProps from '../IOthersProps';

export enum TooltipPosition {
    top = 'top',
    bottom = 'bottom',
    left = 'left',
    right = 'right',
}

export interface ITooltipProps extends IChildrenProps, IStyledProps, IOthersProps {
    tooltipMessage: string | any;
    position?: TooltipPosition;
}

const Tooltip: React.FC<ITooltipProps> = ({ children, tooltipMessage, position = 'top', style = {}, ...others }) => (
    // @ts-ignore TODO: Remove when Groupui exposes the types for the props
    <GroupuiTooltip position={position} style={style} {...others}>
        <>{children}</>
        <div style={{ maxWidth: 'none' }} slot="tooltip">
            {tooltipMessage}
        </div>
    </GroupuiTooltip>
);

export default Tooltip;
