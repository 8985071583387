import IStyledProps from '../IStyledProps';
import IChildrenProps from '../IChildrenProps';
import React, { useEffect, useRef, useState } from 'react';

interface IKeepOnTopContainerProps extends IStyledProps, IChildrenProps {
    minDeltaFromTop: number;
}

const KeepOnTopContainer: React.FC<IKeepOnTopContainerProps> = ({ minDeltaFromTop = 0, style, children }) => {
    const compRef = useRef<HTMLDivElement>(null);
    const [currentScrollY, setCurrentScrollY] = useState<number>(window.scrollY);

    const scrollEventName = 'scroll';

    useEffect(() => {
        const onScrollHandler = () => {
            setCurrentScrollY(window.scrollY);
        };
        window.addEventListener(scrollEventName, onScrollHandler, true);
        return () => {
            // Cleanup the listener when the component is unmounted
            window.removeEventListener(scrollEventName, onScrollHandler);
        };
    }, [setCurrentScrollY]);

    const initialTop = compRef.current !== null ? compRef.current.offsetTop : 0;
    const initialWidth = compRef.current !== null ? compRef.current.offsetWidth : 0;
    const shouldBeFixed = initialTop !== null && currentScrollY > initialTop;
    const fixedPositionStyle = {
        top: minDeltaFromTop,
        width: initialWidth,
        position: 'fixed',
    };
    const autoScrollStyle = shouldBeFixed ? fixedPositionStyle : {};
    return (
        <div ref={compRef}>
            <div style={{ ...style, ...autoScrollStyle }}>{children}</div>
        </div>
    );
};

export default KeepOnTopContainer;
