import { Part } from '../models/Part';
import { PARTS_URL } from '../config/baseUrls';
import authorizedFetch from '../security/authorizedFetch';
import moment from 'moment';
import ISorting from '../interfaces/ISorting';
import { PartType } from '../models/PartType';

export interface IPartsFilter {
    fromDate: Date | null;
    toDate: Date | null;
    tids: string[];
    parttypes: string[];
    pids: string[];
    limit?: number;
    offset?: number;
}

export interface GetPartsResponse {
    parts: Part[];
    total: number;
}

export interface ExportPartsResponse {
    data: Blob;
}
const queryBuilder = (filters: IPartsFilter, offset: number, limit: number, sorting: ISorting | null) => {
    const query = new URLSearchParams();
    if (filters.fromDate) query.append('date_from', moment(filters.fromDate).format());
    if (filters.toDate) query.append('date_to', moment(filters.toDate).format());
    filters.tids.forEach((tid) => query.append('tid', tid));
    filters.pids.forEach((pid) => query.append('pid', pid));
    filters.parttypes.forEach((parttype) => query.append('parttype', parttype));
    query.append('limit', limit.toString());
    query.append('offset', offset.toString());
    if (sorting) query.append('sort', `${sorting.column}:${sorting.direction}`);
    return query;
};

export default class PartsService {
    getPartTypes(): Promise<PartType[]> {
        return new Promise((resolve, reject) => {
            authorizedFetch(`${PARTS_URL}/types`)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function (responseAsJson) {
                    resolve(responseAsJson.part_types.map((partType: PartType) => PartType.fromJson(partType)));
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }
    getPart(partId: string): Promise<Part> {
        return new Promise((resolve, reject) => {
            authorizedFetch(`${PARTS_URL}/${partId}`)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function (responseAsJson) {
                    resolve(Part.fromJson(responseAsJson));
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    getPartChildren(parentId: string): Promise<Part[]> {
        return new Promise((resolve, reject) => {
            authorizedFetch(`${PARTS_URL}/${parentId}/children`)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function (responseAsJson) {
                    const children = responseAsJson.children.map((part: any) => Part.fromJson(part));
                    resolve(children);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    getParts(
        filters: IPartsFilter,
        offset: number,
        limit: number,
        sorting: ISorting | null = null
    ): Promise<GetPartsResponse> {
        return new Promise((resolve, reject) => {
            authorizedFetch(PARTS_URL + '?' + queryBuilder(filters, offset, limit, sorting))
                .then((response) => response.json())
                .then((data) => {
                    resolve({
                        parts: data.parts.map((jsonObject: any) => Part.fromJson(jsonObject)),
                        total: data.total,
                    });
                });
        });
    }

    async exportParts(
        exportType: string,
        filters: IPartsFilter,
        offset: number,
        limit: number,
        sorting: ISorting | null = null
    ): Promise<ExportPartsResponse> {
        return await new Promise((resolve, reject) => {
            authorizedFetch(
                PARTS_URL + '/export?export_type=' + exportType + '&' + queryBuilder(filters, offset, limit, sorting)
            )
                .then((response) => response.blob())
                .then((data) => {
                    resolve({
                        data: data,
                    });
                });
        });
    }
}
