import { Chip, TextField } from '@mui/material';
import Box from '@mui/material/Box/Box';
import { useEffect, useState } from 'react';
import spacing from '../../../style/spacing';
import { IDataTestProps } from '../../IDataTestProps';
import { FilterHeader } from '../../typography/textStyles';

interface ITagsProps extends IDataTestProps {
    data: string;
    handleDelete: () => void;
    fontSizeTag?: string;
    margin?: string;
}

export const Tag: React.FC<ITagsProps> = ({
    data,
    handleDelete,
    dataTestid = '',
    fontSizeTag = '13px',
    margin = spacing(0.5),
}) => {
    return (
        <Chip
            data-testid={dataTestid}
            label={data}
            onDelete={handleDelete}
            color="primary"
            size="small"
            style={{ margin: margin, fontSize: fontSizeTag }}
        />
    );
};

interface ITextFieldWithTagsProps {
    title: string;
    placeholderTextNoSelection: string;
    placeholderTextSelection: string;
    values: string[];
    onSelectionChange: (values: string[]) => void;
    fontSizeTag?: string;
    dataTestid?: string;
    changeFontSize?: boolean;
}

const TextFieldWithTags: React.FC<ITextFieldWithTagsProps> = ({
    onSelectionChange,
    values,
    title,
    placeholderTextNoSelection,
    placeholderTextSelection,
    fontSizeTag,
    dataTestid = '',
    changeFontSize = false,
}) => {
    const [tags, setTags] = useState<string[]>(values);
    const [value, setValue] = useState<string>('');
    const [hasLastElementBeenEdited, setHasLastElementBeenEdited] = useState<boolean>(false);

    const handleOnSubmit = (e: React.KeyboardEvent, value: string) => {
        let key: string = e.key;
        if (key === 'Enter' && value !== '') {
            e.preventDefault();
            let values: string[] = value.split(/[,;]/);
            setTags([...tags, ...values]);
            onSelectionChange([...tags, ...values]);
            setHasLastElementBeenEdited(false);
            setValue('');
        }
        if (key === 'Backspace' && value === '' && !hasLastElementBeenEdited) {
            e.preventDefault();
            setValue(tags[tags.length - 1]);
            setTags(tags.slice(0, -1));
            onSelectionChange(tags.slice(0, -1));
            setHasLastElementBeenEdited(true);
        }
    };

    const handleDelete = (value: string) => {
        return () => {
            setTags(tags.filter((tag) => tag !== value));
            onSelectionChange(tags.filter((tag) => tag !== value));
        };
    };

    useEffect(() => {
        setTags(values);
    }, [values]);

    return (
        <Box style={{ flexGrow: 1, marginBottom: spacing(1) }}>
            <FilterHeader>{title}</FilterHeader>
            <TextField
                inputProps={{ 'data-testid': dataTestid }}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e) => handleOnSubmit(e, value)}
                fullWidth
                variant="outlined"
                size="small"
                style={{ marginBottom: spacing(1) }}
                placeholder={tags.length < 1 ? placeholderTextNoSelection : placeholderTextSelection}
                value={value}
            />
            <Box>
                {tags.map((data, index) => {
                    let label = data;
                    if (changeFontSize) {
                        fontSizeTag = label.length <= 28 ? '13px' : '11px';
                    }
                    return (
                        <Tag
                            fontSizeTag={fontSizeTag}
                            data={label}
                            key={index + data}
                            handleDelete={handleDelete(data)}
                            dataTestid={`${dataTestid}-tag-${index}`}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

export default TextFieldWithTags;
