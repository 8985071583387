import React from 'react';
import { GroupuiBreadcrumb, GroupuiBreadcrumbs } from '@group-ui/group-ui-react';
import IStyledProps from '../IStyledProps';
import spacing from '../../style/spacing';
import { useTranslation } from 'react-i18next';

interface IBreadcrumbsProps extends IStyledProps {
    steps: {
        href?: string;
        label: string;
    }[];
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ steps, style }) => {
    const { t } = useTranslation();
    return (
        <GroupuiBreadcrumbs style={{ marginBottom: spacing(1), ...style }}>
            {steps.map(({ label, href }) => (
                <GroupuiBreadcrumb key={label} href={href}>
                    {t(label)}
                </GroupuiBreadcrumb>
            ))}
        </GroupuiBreadcrumbs>
    );
};

export default Breadcrumbs;
