import { Stats } from '../models/stats/Stats';
import { STATS_URL } from '../config/baseUrls';
import authorizedFetch from '../security/authorizedFetch';
import moment from 'moment';

export interface IStatsFilters {
    fromDate: moment.Moment;
    toDate: moment.Moment;
    productType?: string;
    selectedProductTypes?: string[];
    selectedTestBenches?: string[];
    exclude?: boolean;
}

export const defaultFilters = {
    toDate: new Date(),
    fromDate: new Date(2020, 1, 1),
};

export default class StatsService {
    getStats(filters: IStatsFilters): Promise<Stats> {
        return new Promise((resolve, reject) => {
            const query = new URLSearchParams();
            if (filters.fromDate) query.append('from_date', moment(filters.fromDate).format());
            if (filters.toDate) query.append('to_date', moment(filters.toDate).format());
            if (filters.productType) query.append('product_type', filters.productType);

            if (filters.selectedProductTypes) {
                filters.selectedProductTypes.forEach((productType) => query.append('product_type_in', productType));
            }
            if (filters.selectedTestBenches) {
                filters.selectedTestBenches.forEach((testBench) => query.append('test_bench_in', testBench));
            }
            if (filters.exclude) {
                query.append('exclude', filters.exclude.toString());
            }

            authorizedFetch(`${STATS_URL}?${query}`)
                .then(function (response) {
                    if (!response.ok) {
                        reject(new Error(response.statusText));
                    }
                    return response.json();
                })
                .then(function (responseAsJson) {
                    resolve(Stats.fromJson(responseAsJson));
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }
}
