import { Link } from 'react-router-dom';
import React from 'react';
import { Part } from '../../../domain/models/Part';
import { PartHistory } from '../../../domain/models/Product';
import { List } from '../../base/lists/List';
import { Border, ListItem, Size } from '../../base/lists/ListItem';
import Translation from '../../base/translation/Translation';
import { Caption, H3, H6 } from '../../base/typography/textStyles';
import { toDateString, toTimeString } from '../../base/utils';
import Card from '../../base/Card';
import spacing from '../../style/spacing';
import { Divider } from '../../base/layout/Divider';
import { FlexContainer } from '../../base/layout/Flex';
import { LinkIcon } from '../../base/icons/icons';
import { Button } from '../../base/buttons/Button';
import moment from 'moment';
import { usePartChildren } from '../../../domain/hooks/usePartChildren';
import { SZ_ONLINE_URL } from '../../../domain/config/baseUrls';

// const ROTOR_STATOR_PART_TYPES = ['Rotor', 'Stator', 'Stator 0EH902172'];

const is_rotor_stator = (inputString: string): boolean => {
    return ['Rotor', 'Stator', 'Stator 0EH902172'].includes(inputString);
};

const SubPartsList = ({ partChildren }: { partChildren: Part[] | null }) => {
    if (partChildren != null && partChildren.length > 0) {
        const subComponents = partChildren.map((child) => (
            <ListItem border={Border.none} size={Size.s}>
                <Caption style={{ fontSize: 12, fontWeight: 'bold' }} noGutter>
                    TID: {child.tid}
                </Caption>
                <Caption noGutter>
                    <Translation i18nKey="partDetails.subComponentsType" values={{ type: child.part_type_name }} />
                </Caption>
                {child.part_label !== null ? (
                    <Caption noGutter paragraph={true} style={{ marginBottom: 0 }}>
                        <Translation i18nKey="partDetails.label" values={{ label: child.part_label }} />
                    </Caption>
                ) : (
                    <></>
                )}
            </ListItem>
        ));
        return (
            <>
                <details>
                    <summary style={{ fontSize: '14px', color: '#20607E', marginTop: 2 }}>
                        <Translation i18nKey="partDetails.subComponentTitle"></Translation>
                    </summary>
                    <List style={{ marginBottom: 0, marginLeft: 5 }}>{subComponents}</List>
                </details>
            </>
        );
    }
    return <></>;
};

const ComponentsListItem = ({
    assemblyDate,
    pid,
    part,
    redirectUrl,
    productionDate,
    partNumber,
}: {
    assemblyDate: Date;
    pid: string;
    part: Part;
    redirectUrl: string;
    productionDate: Date | null;
    partNumber: string;
}) => {
    const { partChildren } = usePartChildren(part.tid);
    // const isRotorStator = ROTOR_STATOR_PART_TYPES.includes(part.part_type_name);

    return (
        <>
            <ListItem>
                <FlexContainer justifyContent="space-between" alignItems="center">
                    <div>
                        <H6>
                            <Translation i18nKey="productDetails.partType" values={{ type: part.part_type_name }} />
                        </H6>
                        {part.part_label !== null ? (
                            <Caption>
                                <Translation i18nKey="productDetails.label" values={{ label: part.part_label }} />
                            </Caption>
                        ) : (
                            <></>
                        )}
                        <Caption>
                            <Translation i18nKey="productDetails.partId" values={{ tid: part.tid }} />
                        </Caption>
                        <Caption>
                            <Translation
                                i18nKey="productDetails.assemblyDate"
                                values={
                                    !(
                                        assemblyDate.getFullYear() === 1971 ??
                                        assemblyDate.getMonth() === 1 ??
                                        assemblyDate.getDay() === 1
                                    )
                                        ? {
                                              date: toDateString(moment(assemblyDate)),
                                              time: toTimeString(moment(assemblyDate)),
                                          }
                                        : {
                                              date: 'XXXX-XX-XX',
                                              time: 'XX:XX',
                                          }
                                }
                            />
                        </Caption>
                        {is_rotor_stator(part.part_type_name) && productionDate !== null && (
                            <Caption>
                                <Translation
                                    i18nKey="productDetails.productionDate"
                                    values={
                                        !(
                                            productionDate.getFullYear() === 1971 ??
                                            productionDate.getMonth() === 1 ??
                                            productionDate.getDay() === 1
                                        )
                                            ? {
                                                  date: toDateString(moment(productionDate)),
                                              }
                                            : {
                                                  date: 'XXXX-XX-XX',
                                              }
                                    }
                                />
                            </Caption>
                        )}
                        {is_rotor_stator(part.part_type_name) && (
                            <Caption>
                                <Translation
                                    i18nKey="productDetails.partNumber"
                                    values={{
                                        partNumber: partNumber,
                                    }}
                                />
                            </Caption>
                        )}
                        <Caption style={{ marginBottom: 0 }}>
                            <SubPartsList partChildren={partChildren} />
                        </Caption>
                    </div>
                    <div>
                        <Link to={redirectUrl} target={is_rotor_stator(part.part_type_name) ? '_blank' : ''}>
                            <Button iconOnly={true}>
                                <LinkIcon />
                            </Button>
                        </Link>
                    </div>
                </FlexContainer>
            </ListItem>
        </>
    );
};

const EmptyComponentsList = () => (
    <FlexContainer alignItems="center" justifyContent="center" fullWidth={true} style={{ minHeight: '500px' }}>
        <Translation i18nKey="productDetails.noComponentsFound" />
    </FlexContainer>
);

const Title = () => (
    <>
        <H3 noGutter>
            <Translation i18nKey="productDetails.components" />
        </H3>
        <Caption noGutter>&nbsp;</Caption>
    </>
);

export const ComponentsList = ({ pid, partHistory }: { pid: string; partHistory: PartHistory[] }) => {
    // There are different (edge) cases which have to be considered
    // - [1] The assembly station has 3 parts connected with the product, the parts are not a build into other products (normal case)
    // - [2] The assembly station has 3 parts connected with the product, one or more parts can be find in other products (product was used as a partdonor for antoher product)
    // - [3] The assembly station has more than 3 parts connected with the product, the parts can only be connected with this product (product was repaired, the changed part was defekt and NOT used in another product)
    // - [4] The assembly station has more than 3 parts connected with the product, some of the parts now are used in another product (one or more parts of this product were used in another product, but also this product was repaired with also a new or other part (not sure if this is a real case))

    const sortedPartHistory = partHistory.sort((a, b) =>
        a.part.part_type > b.part.part_type ? 1 : b.part.part_type > a.part.part_type ? -1 : 0
    );

    const currentComponents = sortedPartHistory.filter(
        (partHistory) => partHistory.part.part_type !== null && partHistory.part.current_pid === pid
    );

    const replacedComponents = sortedPartHistory.filter(
        (partHistory) => partHistory.part.part_type !== null && partHistory.part.current_pid !== pid
    );

    if (currentComponents.length === 0 && replacedComponents.length === 0) {
        return (
            <>
                <Title />
                <EmptyComponentsList />
            </>
        );
    }

    const rotorStatorComponents = currentComponents.filter((components) =>
        is_rotor_stator(components.part.part_type_name)
    );

    return (
        <div>
            <Title />
            <div style={{ display: 'flex', flexDirection: 'column', gap: spacing(3) }}>
                <ComponentsCard
                    pid={pid}
                    filteredComponents={currentComponents.filter(
                        (partHistory) => !is_rotor_stator(partHistory.part.part_type_name)
                    )}
                />
                {replacedComponents.length > 0 ? (
                    <>
                        <H3 style={{ marginBottom: '0px' }}>
                            <Translation i18nKey="productDetails.replacedComponents" />
                        </H3>
                        <ComponentsCard pid={pid} filteredComponents={replacedComponents} />
                    </>
                ) : null}
                {rotorStatorComponents.length > 0 ? (
                    <ComponentsCard pid={pid} filteredComponents={rotorStatorComponents} />
                ) : null}
            </div>
        </div>
    );
};

const ComponentsCard = ({ pid, filteredComponents }: { pid: string; filteredComponents: PartHistory[] }) => {
    const getPartUrl = (part: Part) => {
        if (is_rotor_stator(part.part_type_name)) {
            return `${SZ_ONLINE_URL}/part/${encodeURIComponent(part.tid)}`;
        } else {
            return `/products/${part.current_pid}/parts/${part.tid}`;
        }
    };
    if (filteredComponents.length === 0) {
        return <></>;
    }
    return (
        <>
            <Card>
                <List>
                    {filteredComponents.map((partHistory) => {
                        const redirectUrl = getPartUrl(partHistory.part);
                        return (
                            <div key={partHistory.part.tid}>
                                <ComponentsListItem
                                    assemblyDate={partHistory.assembledAt}
                                    pid={pid}
                                    redirectUrl={redirectUrl}
                                    key={partHistory.part.tid}
                                    part={partHistory.part}
                                    productionDate={partHistory.part.production_date}
                                    partNumber={partHistory.part.serial}
                                />
                                <Divider />
                            </div>
                        );
                    })}
                </List>
            </Card>
        </>
    );
};
