import React from 'react';
import { negative, positive, warning } from '../../../style/colors';
import { StatistikCard } from './StatistikCard';
import { EOLVerificationDirectPassStats } from '../../../../domain/models/stats/EOLVerificationsStats';

const transformedData = (directPassStats: EOLVerificationDirectPassStats) => {
    if (directPassStats)
        return [
            {
                name: 'home.EOLVerificationEolErrors.directPassRatioTotal',
                subName: '(100%)',
                value: directPassStats.total,
                color: 'black',
            },
            {
                name: 'home.EOLVerificationEolErrors.directPass',
                subName: `(${directPassStats.directPassRatio.toFixed(1)}%)`,
                value: directPassStats.directPass,
                color: positive,
            },
            {
                name: 'home.EOLVerificationEolErrors.passedAfterFailure',
                subName: `(${directPassStats.passedAfterFailureRatio.toFixed(1)}%)`,
                value: directPassStats.passedAfterFailure,
                color: warning,
            },
            {
                name: 'home.EOLVerificationEolErrors.failed',
                subName: `(${directPassStats.failedRatio.toFixed(1)}%)`,
                value: directPassStats.failed,
                color: negative,
            },
        ];

    return [];
};

const EOLVerificationsDirectPassRatio: React.FC<any> = ({ stats }: { stats: any }) => {
    const directPassStats = stats.directPassStats;
    return (
        <StatistikCard
            title="home.EOLVerificationEolErrors.directPassRatio"
            subTitle="home.EOLVerificationEolErrors.directPassRatioExplanation"
            pieData={transformedData(directPassStats).filter((test) => test.color !== 'black')}
            chartData={transformedData(directPassStats)}
            pieChartTitle="home.EOLVerificationEolErrors.engines"
            pieChartSubTitle="home.EOLVerificationEolErrors.directPassSubTitle"
            total={directPassStats.total}
            dataTestid="directPassRatioPiechart"
        />
    );
};

export default EOLVerificationsDirectPassRatio;
