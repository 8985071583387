import { Edge } from './Edge';
import { Node } from './Node';

export class ReactFlowUiElement {
    constructor(public elementId: Node | Edge, public positionX: number, public positionY: number) {}

    static fromJson(json: any): ReactFlowUiElement {
        return new ReactFlowUiElement(json.elementId, json.positionX, json.positionY);
    }
}
