import React from 'react';
import { Checkbox } from '../../inputs/Checkbox';
import Translation from '../../translation/Translation';
import { H6 } from '../../typography/textStyles';
import { FiltersGroup } from './FiltersGroup';

interface IDirectPassFiltersGroupProps {
    directPassStatus: number[];
    onCheckBoxChanged: (newValue: number[]) => void;
}

export const DirectPassFiltersGroup: React.FC<IDirectPassFiltersGroupProps> = ({
    directPassStatus,
    onCheckBoxChanged,
}) => {
    const handleChange = (event: any, status: number) => {
        let newState = [];
        if (event) newState = [...directPassStatus, status];
        else newState = directPassStatus.filter((currentStatus) => currentStatus !== status);
        onCheckBoxChanged(newState);
    };

    return (
        <FiltersGroup title="enginesFilter.quality.title">
            <H6>
                <Translation i18nKey="enginesFilter.quality.verifications" />
            </H6>
            <Checkbox
                data-testId="enginesFilter-directPass-checkbox"
                checked={directPassStatus.includes(1)}
                onValueChange={(e) => handleChange(e, 1)}
            >
                <Translation i18nKey="enginesFilter.quality.directPass" />
            </Checkbox>
            <Checkbox checked={directPassStatus.includes(2)} onValueChange={(e) => handleChange(e, 2)}>
                <Translation i18nKey="enginesFilter.quality.passedAfterFailure" />
            </Checkbox>
            <Checkbox checked={directPassStatus.includes(0)} onValueChange={(e) => handleChange(e, 0)}>
                <Translation i18nKey="enginesFilter.quality.alwaysError" />
            </Checkbox>
            <Checkbox checked={directPassStatus.includes(-1)} onValueChange={(e) => handleChange(e, -1)}>
                <Translation i18nKey="enginesFilter.quality.undefined" />
            </Checkbox>
        </FiltersGroup>
    );
};
