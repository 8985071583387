import React, { useEffect, useState } from 'react';
import spacing from '../../../style/spacing';
import { Checkbox } from '../../inputs/Checkbox';
import { FlexContainer } from '../../layout/Flex';
import { List } from '../../lists/List';
import { ListItem, Size } from '../../lists/ListItem';
import Translation from '../../translation/Translation';
import { Body1 } from '../../typography/textStyles';
import { FiltersGroup } from './FiltersGroup';
import Tag from '../../tag';
import { Input } from '../../inputs/Input';
import { useTranslation } from 'react-i18next';
import {
    EOLVerificationsCountByTestBenchDTO,
    EOLVerificationsTestBenchDistributionStats,
} from '../../../../domain/models/stats/EOLVerificationsStats';
import { machineLearningTestBench } from '../../../../domain/config/values';

interface ITestBenchesFiltersGroupProps {
    testBenchDistributionStats: EOLVerificationsTestBenchDistributionStats | null;
    selectedTestBenches: string[];
    onSelectedTestBenchesChanged: (selectedTestBenches: string[]) => void;
}

const SelectableListItem: React.FC<any> = ({ selected, children, onClick }) => (
    <ListItem size={Size.s} onClick={onClick} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
        <FlexContainer>{children}</FlexContainer>
    </ListItem>
);

const TestBenchFiltersGroup: React.FC<ITestBenchesFiltersGroupProps> = ({
    testBenchDistributionStats,
    selectedTestBenches,
    onSelectedTestBenchesChanged,
}) => {
    const addTestBenchIfSelectedButZeroResults = (
        stats: EOLVerificationsTestBenchDistributionStats | null,
        testBenches: string[]
    ) => {
        if (stats === null) return [];
        let arr: EOLVerificationsCountByTestBenchDTO[] = stats.byTestBench;
        for (let i = 0; i < testBenches.length; i++) {
            let found = false;
            for (let j = 0; j < arr.length; j++) {
                if (arr[j].test_bench === testBenches[i]) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                arr.push(new EOLVerificationsCountByTestBenchDTO(0, testBenches[i]));
            }
        }
        return arr;
    };
    const testBenches: EOLVerificationsCountByTestBenchDTO[] = addTestBenchIfSelectedButZeroResults(
        testBenchDistributionStats,
        selectedTestBenches
    );

    const { t } = useTranslation();
    const [testBenchSearch, setTestBenchSearch] = useState<string | undefined>(undefined);

    const onTestBenchSearchInputChange = (testBenchSearch: string) => {
        setTestBenchSearch(testBenchSearch);
    };

    const onTestBenchClicked = (testBench: EOLVerificationsCountByTestBenchDTO, value: boolean) => {
        if (value) {
            const newSelectedTestBenches = [...selectedTestBenches, testBench.test_bench];
            onSelectedTestBenchesChanged(newSelectedTestBenches);
        } else {
            const newSelectedTestBenches = selectedTestBenches.filter(
                (testBenchType) => testBenchType !== testBench.test_bench
            );
            onSelectedTestBenchesChanged(newSelectedTestBenches);
            if (testBench.test_bench !== machineLearningTestBench) setSelectAll(false);
        }
    };

    const [selectAll, setSelectAll] = useState(false);
    const allTestBenchesClicked = (selectAll: boolean) => {
        if (selectAll) {
            setSelectAll(false);
            onSelectedTestBenchesChanged([]);
        } else {
            setSelectAll(true);
            onSelectedTestBenchesChanged(
                testBenchDistributionStats
                    ? testBenchDistributionStats?.byTestBench
                          .map((testBench) => testBench.test_bench)
                          .filter(
                              (testBench) =>
                                  testBench !== machineLearningTestBench ||
                                  selectedTestBenches.includes(machineLearningTestBench)
                          )
                          .map(String)
                    : []
            );
        }
    };

    useEffect(() => {
        allTestBenchesClicked(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const testBenchCountByTestBench = (test_bench: string): number | null => {
        if (testBenches === undefined) return null;
        const value = testBenches.find((stat) => stat.test_bench === test_bench)?.total;
        return value !== undefined ? value : null;
    };

    const allTestBenchesCount = (): number | null => {
        if (testBenchDistributionStats === null) return null;
        const value = testBenchDistributionStats.total;
        return value !== undefined ? value : null;
    };

    const total = allTestBenchesCount();

    const filteredTestBenches = testBenches?.filter((testBench) => {
        if (testBenchSearch === undefined) {
            return true;
        }
        return testBench.test_bench.toLowerCase().includes(testBenchSearch?.toLowerCase());
    });

    const sortedTestBenches = [
        ...filteredTestBenches.filter(({ test_bench }) => test_bench !== machineLearningTestBench),
        ...filteredTestBenches.filter(({ test_bench }) => test_bench === machineLearningTestBench),
    ];

    return (
        <FiltersGroup title="home.filter.testBench.title">
            <Input
                value={testBenchSearch}
                onValueChange={onTestBenchSearchInputChange}
                type="text"
                icon="search-24"
                iconPosition="left"
                placeholder={t('home.filter.testBench.searchPlaceholder')}
            />
            <List>
                <SelectableListItem selected={selectAll} onClick={() => allTestBenchesClicked(selectAll)}>
                    <Checkbox
                        dataTestid={`Home.Filters.TestBenchAllCheckBox`}
                        noGutter={true}
                        checked={selectAll}
                        key={`all-${selectAll}`}
                    />
                    <Body1 noGutter={true} style={{ marginLeft: spacing(1), marginRight: spacing(2) }}>
                        <Translation i18nKey={'home.filter.testBench.all'} />
                    </Body1>
                    <Tag dataTestid={`Home.Filters.TestBenchAllCount`}>{total}</Tag>
                </SelectableListItem>

                {sortedTestBenches
                    .filter((testBench) => testBench.test_bench !== machineLearningTestBench)
                    .map((testBench, idx) => {
                        const selected = selectedTestBenches.includes(testBench.test_bench);
                        const count = testBenchCountByTestBench(testBench.test_bench);
                        return (
                            <SelectableListItem
                                key={`${idx}-${selected}`}
                                selected={selected}
                                onClick={() => onTestBenchClicked(testBench, !selected)}
                            >
                                <Checkbox
                                    dataTestid={`Home.Filters.TestBench${testBench.test_bench}CheckBox`}
                                    noGutter={true}
                                    checked={selected}
                                    key={`${idx}-${selected}`}
                                />
                                <Body1
                                    dataTestid={`Home.Filters.TestBench${testBench.test_bench}`}
                                    noGutter={true}
                                    style={{ marginLeft: spacing(1), marginRight: spacing(2) }}
                                >
                                    {testBench.test_bench}
                                </Body1>
                                <Tag dataTestid={`Home.Filters.TestBench${testBench.test_bench}Count`}>
                                    {count ? count : 0}
                                </Tag>
                            </SelectableListItem>
                        );
                    })}
                {filteredTestBenches
                    .filter((testBench) => testBench.test_bench === machineLearningTestBench)
                    .map((testBench, idx) => {
                        const selected = selectedTestBenches.includes(testBench.test_bench);
                        const count = testBenchCountByTestBench(testBench.test_bench);
                        return (
                            <SelectableListItem
                                key={`${idx}-${selected}`}
                                selected={selected}
                                onClick={() => onTestBenchClicked(testBench, !selected)}
                            >
                                <Checkbox
                                    dataTestid={`Home.Filters.TestBench${testBench.test_bench}CheckBox`}
                                    noGutter={true}
                                    checked={selected}
                                    key={`${idx}-${selected}`}
                                />
                                <Body1
                                    dataTestid={`Home.Filters.TestBench${testBench.test_bench}`}
                                    noGutter={true}
                                    style={{ marginLeft: spacing(1), marginRight: spacing(2) }}
                                >
                                    {testBench.test_bench}
                                </Body1>
                                <Tag dataTestid={`Home.Filters.TestBench${testBench.test_bench}Count`}>
                                    {count ? count : 0}
                                </Tag>
                            </SelectableListItem>
                        );
                    })}
            </List>
        </FiltersGroup>
    );
};

export default TestBenchFiltersGroup;
