import { Body1, H4, H5 } from '../../../base/typography/textStyles';
import { pieChartColors } from '../../../style/colors';
import React from 'react';
import Translation from '../../../base/translation/Translation';
import spacing from '../../../style/spacing';
import { ListItem, Size } from '../../../base/lists/ListItem';
import { List } from '../../../base/lists/List';
import Card from '../../../base/Card';
import { FlexContainer } from '../../../base/layout/Flex';
import { LinkIcon } from '../../../base/icons/icons';
import Tooltip, { TooltipPosition } from '../../../base/tooltip/tooltip';
import PieChart from '../../../base/diagrams/PieChart';
import { IDataTestProps } from '../../../base/IDataTestProps';
import Tag from '../../../base/tag';
import { Button } from '../../../base/buttons/Button';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface IData {
    name: string;
    subName?: string;
    value: number;
    onClick?: () => void;
    toolTipText?: string;
    color?: string;
    redirectUrl?: string;
}

const StatistikPieChart: React.FC<any> = ({
    data,
    title,
    subTitle,
    total,
    dataTestid,
}: {
    data: IData[];
    title: string;
    subTitle: string;
    total: number;
    dataTestid?: string;
}) => {
    const { t } = useTranslation();

    data = data.map((data) => ({ ...data, name: t(data.name) }));
    return (
        <div data-testid={dataTestid}>
            <PieChart
                total={total}
                style={{ height: '275px' }}
                data={data}
                isDonut={true}
                showLegend={false}
                showLabels={false}
                title={title}
                subTitle={subTitle}
            />
        </div>
    );
};

interface IStastikItem extends IDataTestProps {
    value: number;
    name: string;
    subName?: string;
    color?: string;
    toolTipText?: string;
    redirectUrl: string;
}
const StatistikItem: React.FC<IStastikItem> = ({
    value,
    name,
    color = 'black',
    dataTestid,
    subName,
    toolTipText,
    redirectUrl,
}) => {
    return (
        <ListItem size={Size.s} noPadding={true}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    <FlexContainer alignItems="center">
                        <H5
                            weight="bold"
                            noGutter={true}
                            style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                color: color,
                            }}
                        >
                            <Translation i18nKey={name} /> &nbsp;
                        </H5>
                        <Tag dataTestid={dataTestid} style={{ background: color }}>
                            {value}
                        </Tag>
                    </FlexContainer>
                    <FlexContainer flexDirection="column" dataTestid={dataTestid + '.subtitle'}>
                        {subName}
                    </FlexContainer>
                </Box>

                {redirectUrl && (
                    <Tooltip
                        size={Size.s}
                        tooltipMessage={toolTipText}
                        variant={'secondary'}
                        position={TooltipPosition.bottom}
                    >
                        <Link to={redirectUrl} data-testid={`statistikItem.${name}.Button`}>
                            <Button size={Size.s} iconOnly={true}>
                                <LinkIcon height={16} width={16} />
                            </Button>
                        </Link>
                    </Tooltip>
                )}
            </Box>
        </ListItem>
    );
};

export const StatistikCard = ({
    title,
    subTitle,
    pieData,
    chartData = pieData,
    pieChartTitle,
    pieChartSubTitle,
    total,
    dataTestid,
}: {
    title: string;
    subTitle: string;
    pieData: IData[];
    chartData?: IData[];
    pieChartTitle: string;
    pieChartSubTitle?: string;
    total: number;
    dataTestid?: string;
}) => {
    return (
        <>
            {pieData.length > 0 && (
                <Card height={'100%'}>
                    <H4 weight="bold">
                        <Translation i18nKey={title} />
                    </H4>
                    <Body1>
                        <Translation i18nKey={subTitle} />
                    </Body1>
                    <StatistikPieChart
                        title={pieChartTitle}
                        dataTestid={dataTestid}
                        subTitle={pieChartSubTitle}
                        data={pieData}
                        total={total}
                    />
                    <List style={{ marginTop: spacing(1) }}>
                        {chartData.map((data: IData, idx: number) => (
                            <StatistikItem
                                key={idx}
                                value={data.value}
                                color={data.color ? data.color : pieChartColors[idx]}
                                name={data.name}
                                subName={data.subName}
                                dataTestid={`statistikItem.${data.name}`}
                                toolTipText={data.toolTipText}
                                redirectUrl={data.redirectUrl ? data.redirectUrl : ''}
                            />
                        ))}
                    </List>
                </Card>
            )}
        </>
    );
};
