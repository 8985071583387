import React from 'react';
import { FlexContainer } from '../../../base/layout/Flex';
import { TitleBar } from '../../../base/layout/TitleBar';
import { Table } from '../../../base/table/Table';
import { Paginator } from '../../../base/paginator/Paginator';
import Translation from '../../../base/translation/Translation';
import ISorting from '../../../../domain/interfaces/ISorting';
import Card from '../../../base/Card';
import spacing from '../../../style/spacing';
import { MachineHeader, MachineRow } from './MachinesTableRows';
import { Machine } from '../../../../domain/models/Machine';
import Breadcrumbs from '../../../base/breadcrumbs';

interface IMachinesTableProps {
    onRowClick?: (rowIdx: number) => void;
    onNextPageClicked?: () => void;
    onPrevPageClicked?: () => void;
    setSorting: (column: string) => void;
    sorting: ISorting | null;
    offset?: number;
    limit?: number;
    total: number;
    isLoading?: boolean;
    rows: Machine[];
    setPage: (page: number) => void;
}

export const MachinesTable: React.FC<IMachinesTableProps> = ({
    rows,
    total,
    offset = 0,
    limit = 25,
    isLoading = false,
    sorting,
    onRowClick = () => {},
    onPrevPageClicked = () => {},
    onNextPageClicked = () => {},
    setSorting = (column: string) => {},
    setPage = (page: number) => {},
}: IMachinesTableProps) => {
    return (
        <>
            <TitleBar
                dataTestid={'machines.tableTitle'}
                resultNumber={total}
                subtitle={
                    <Breadcrumbs
                        steps={[
                            {
                                label: 'machines.breadcrumbTitle',
                                href: '/machines',
                            },
                        ]}
                    />
                }
                title={
                    <FlexContainer alignItems="flex-end">
                        <Translation i18nKey="machines.tableTitle" />
                    </FlexContainer>
                }
                actions={
                    <Paginator
                        total={total}
                        offset={offset}
                        limit={limit}
                        onNextPageClicked={onNextPageClicked}
                        onPrevPageClicked={onPrevPageClicked}
                        setPage={setPage}
                    />
                }
            />
            <Card style={{ marginTop: spacing(3), height: '100%' }}>
                <Table columns={4} header={true} size="s" sortable={false} isLoading={isLoading}>
                    <MachineHeader sorting={sorting} setSorting={setSorting} />
                    {rows.map((row, idx) => (
                        <MachineRow
                            dataTestid={`MachineBrowser-Row-${idx}`}
                            onClick={() => onRowClick(idx)}
                            key={idx}
                            machine={row}
                        />
                    ))}
                </Table>
            </Card>
        </>
    );
};
