export class EOLVerificationsCountByProductTypeDTO {
    constructor(public readonly total: number, public readonly type: string) {}

    static fromJson(json: any): EOLVerificationsCountByProductTypeDTO {
        return new EOLVerificationsCountByProductTypeDTO(json.total, json.type);
    }
}

export class EOLVerificationsProductDistributionStats {
    constructor(public readonly total: number, public readonly byType: EOLVerificationsCountByProductTypeDTO[]) {}

    static fromJson(json: any): EOLVerificationsProductDistributionStats {
        return new EOLVerificationsProductDistributionStats(
            json.total,
            json.by_type.map((count_by_type: any) => EOLVerificationsCountByProductTypeDTO.fromJson(count_by_type))
        );
    }
}

export class EOLVerificationsCountByTestBenchDTO {
    constructor(public readonly total: number, public readonly test_bench: string) {}

    static fromJson(json: any): EOLVerificationsCountByTestBenchDTO {
        return new EOLVerificationsCountByTestBenchDTO(json.total, json.test_bench);
    }
}
export class EOLVerificationsTestBenchDistributionStats {
    constructor(public readonly total: number, public readonly byTestBench: EOLVerificationsCountByTestBenchDTO[]) {}

    static fromJson(json: any): EOLVerificationsTestBenchDistributionStats {
        return new EOLVerificationsTestBenchDistributionStats(
            json.total,
            json.by_test_bench.map((count_by_test_bench: any) =>
                EOLVerificationsCountByTestBenchDTO.fromJson(count_by_test_bench)
            )
        );
    }
}

export class EOLVerificationDirectPassStats {
    constructor(
        public readonly total: number,
        public readonly directPass: number,
        public readonly passedAfterFailure: number,
        public readonly failed: number
    ) {}

    get directPassRatio(): number {
        return this.total > 0 ? (100 * this.directPass) / this.total : 0;
    }

    get passedAfterFailureRatio(): number {
        return this.total > 0 ? (100 * this.passedAfterFailure) / this.total : 0;
    }

    get failedRatio(): number {
        return this.total > 0 ? (100 * this.failed) / this.total : 0;
    }

    static fromJson(json: any): EOLVerificationDirectPassStats {
        return new EOLVerificationDirectPassStats(json.total, json.direct_pass, json.passed_after_failure, json.failed);
    }
}

export class FailedEOLVerificationsByErrorType {
    constructor(
        public readonly total: number,
        public readonly errorTypeCode: string,
        public readonly errorTypeText: string,
        public readonly errorTypeId: string
    ) {}

    static fromJson(json: any): FailedEOLVerificationsByErrorType {
        return new FailedEOLVerificationsByErrorType(json.total, json.error_code, json.error_text, json.error_id);
    }
}

export class EOLVerificationsStats {
    constructor(
        public readonly total: number,
        public readonly passed: number,
        public readonly notPassed: number,
        public readonly failedByErrorType: FailedEOLVerificationsByErrorType[],
        public readonly directPassStats: EOLVerificationDirectPassStats,
        public readonly productTypeDistribution: EOLVerificationsProductDistributionStats,
        public readonly testBenchDistribution: EOLVerificationsTestBenchDistributionStats
    ) {}

    static fromJson(json: any): EOLVerificationsStats {
        const failedByType = json.failed_by_error_type.map((failedJson: any) =>
            FailedEOLVerificationsByErrorType.fromJson(failedJson)
        );
        const directPassStats = EOLVerificationDirectPassStats.fromJson(json.direct_pass_stats);
        const productTypeDistribution = EOLVerificationsProductDistributionStats.fromJson(
            json.product_type_distribution
        );
        const testBenchDistribution = EOLVerificationsTestBenchDistributionStats.fromJson(json.test_bench_distribution);
        return new EOLVerificationsStats(
            json.total,
            json.passed,
            json.not_passed,
            failedByType,
            directPassStats,
            productTypeDistribution,
            testBenchDistribution
        );
    }
}
