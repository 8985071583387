import React from 'react';
import { GroupuiTab, GroupuiTabs } from '@group-ui/group-ui-react';
import IStyledProps from '../IStyledProps';
import spacing from '../../style/spacing';

interface ITabProps extends IStyledProps {
    noPadding?: boolean;
    onGroupuiTabChange?: (e: any) => void;

    activeTabId?: string;
    tabs: {
        id: string;
        label: string;
        disabled?: boolean;
    }[];
}

const Tabs: React.FC<ITabProps> = ({ tabs, style, ...props }) => (
    <GroupuiTabs {...props} style={{ marginBottom: spacing(1), ...style }}>
        {tabs.map(({ label, id, disabled = false }) => (
            <GroupuiTab tab={id} name={label} disabled={disabled} />
        ))}
    </GroupuiTabs>
);

export default Tabs;
