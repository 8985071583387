import { GroupuiTable, GroupuiTableCell } from '@group-ui/group-ui-react';
import React, { useRef } from 'react';
import { IDataTestProps } from '../IDataTestProps';
import IOthersProps from '../IOthersProps';
import IStyledProps from '../IStyledProps';
import { skeletonLoadingColor } from '../../style/colors';

const SkeletonLoadingTable: React.FC<{ columnsCount: number; rowsCount?: number }> = ({
    columnsCount,
    rowsCount = 100,
}) => {
    const rows = Array.from(Array(rowsCount).keys());
    const cols = Array.from(Array(columnsCount).keys());

    const shapeStyle = {
        borderRadius: '5px',
        height: '100%',
        width: '100%',
        background: skeletonLoadingColor,
    };

    const skeletonContent = <div style={{ ...shapeStyle }}>&nbsp;</div>;

    const fakeColumn = <TableCell>{skeletonContent}</TableCell>;
    const fakeRow = <TableRow>{cols.map((col) => fakeColumn)}</TableRow>;

    return <GroupuiTable columns={columnsCount}>{rows.map((row) => fakeRow)}</GroupuiTable>;
};

export interface ITableProps extends IOthersProps, IDataTestProps, IStyledProps {
    columns: number;
    header?: boolean;
    isLoading?: boolean;
    sortable?: boolean;
    size?: 'm' | 'l' | 's';
}

export const Table: React.FC<ITableProps> = ({
    columns,
    dataTestid = '',
    isLoading = false,
    sortable = false,
    header = false,
    style = {},
    size = 'm',
    ...props
}) => {
    const tableRef = useRef<HTMLGroupuiTableElement | null>(null);

    return (
        <>
            {isLoading ? (
                <SkeletonLoadingTable columnsCount={columns} />
            ) : (
                <GroupuiTable
                    columns={columns}
                    header={header}
                    ref={tableRef}
                    sortable={sortable}
                    data-testid={dataTestid}
                    style={{ overflow: 'hidden', cursor: 'pointer', ...style }}
                    // @ts-ignore the type definition for the size is not correctly implemented in group-ui
                    size={size}
                    {...props}
                />
            )}
        </>
    );
};

export interface ITableRowProps extends IOthersProps, IDataTestProps {}

export const TableRow: React.FC<ITableRowProps> = ({ dataTestid = '', ...props }) => {
    const { children, ...otherProps } = props;
    return (
        <>
            {
                // @ts-ignore
                props.children.map((child, idx) =>
                    React.cloneElement(child, { ...otherProps, key: idx, dataTestid: dataTestid })
                )
            }
        </>
    );
};

export interface ITableCellProps extends IOthersProps, IDataTestProps {}
//@ts-ignore
export const TableCell = ({ dataTestid = '', sortable = false, ...props }) => (
    <GroupuiTableCell
        {...props}
        sortable={sortable}
        style={{ cursor: sortable ? 'pointer' : 'default' }}
        // @ts-ignore TODO: Remove when Groupui exposes the types for the props
        dataTestid={dataTestid}
    />
);
