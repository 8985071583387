import { GroupuiGridCol, GroupuiGridRow } from '@group-ui/group-ui-react';
import React from 'react';

export const GridRow = ({
    alignItems = '',
    justifyItems = '',
    justifyContent = '',
    noPadding = false,
    style = {},
    ...props
}) => {
    return (
        <GroupuiGridRow
            style={{
                alignItems,
                justifyItems,
                justifyContent,
                padding: noPadding ? '0px' : undefined,
                ...style,
            }}
            {...props}
        />
    );
};
export const GridCol = ({ noPadding = false, style = {}, ...props }) => (
    <GroupuiGridCol
        style={{
            padding: noPadding ? '0px' : undefined,
            ...style,
        }}
        {...props}
    />
);
