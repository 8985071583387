import React, { useState } from 'react';
import { toTimeFramesWithoutShifts } from '../../../domain/utils/timeFrames';
import DateIntervalFiltersGroup from '../../base/filtering/filtersGroups/DateIntervalFiltersGroup';
import moment from 'moment';
import FiltersArea from '../../base/filtering/filtersArea/FiltersArea';
import { IPartsFilter } from '../../../domain/services/PartsService';
import TextFieldWithTags, { Tag } from '../../base/filtering/filtersGroups/TextFieldWithTags';
import { FilterHeader } from '../../base/typography/textStyles';
import { Autocomplete } from '@mui/material';
import spacing from '../../style/spacing';
import { useTranslation } from 'react-i18next';
import Translation from '../../base/translation/Translation';
import { Box, Popper, TextField } from '@mui/material';
import { Checkbox } from '../../base/inputs/Checkbox';
import { PartType } from '../../../domain/models/PartType';
import { defaultDates, loadLastTimeFrame, storeLastTimeFrame } from '../../../domain/utils/getDates';

export interface IPartsFiltersFormProps {
    allPartTypes: PartType[];
    filters: IPartsFilter;
    isLoading?: boolean;
    onSearchFiltersChange?: (filters: IPartsFilter) => void;
    onSearchClicked?: (filters: IPartsFilter) => void;
}

export const PartsFiltersForm: React.FC<IPartsFiltersFormProps> = ({
    allPartTypes,
    filters,
    isLoading = false,
    onSearchFiltersChange = () => {},
    onSearchClicked = () => {},
}) => {
    const { t } = useTranslation();
    const [innerFilters, setInnerFilters] = useState({
        selectedTimeFrame: loadLastTimeFrame(defaultDates.timeFrame, true),
        ...filters,
    });

    const timeFrames = toTimeFramesWithoutShifts(moment(new Date()));
    const { tids, parttypes, pids } = innerFilters;

    const onFieldValueChange = (fieldName: string) => {
        return (value: any) => {
            const newInnerFilters: any = { ...innerFilters };
            newInnerFilters[fieldName] = value;
            setInnerFilters(newInnerFilters);
            if (fieldName === 'selectedTimeFrame') {
                storeLastTimeFrame(value);
            }
            onSearchFiltersChange(getNewFilters(newInnerFilters));
        };
    };

    const getNewFilters = (innerFilters: any) => {
        const { selectedTimeFrame, fromDate, toDate, ...other } = innerFilters;
        const newFilters = {
            fromDate: selectedTimeFrame.fromDate,
            toDate: selectedTimeFrame.toDate,
            ...other,
        };
        return newFilters;
    };

    const onSubmit = () => {
        onSearchClicked(getNewFilters(innerFilters));
    };
    const filtersChanged = JSON.stringify(getNewFilters(innerFilters)) !== JSON.stringify(filters);
    const isTimeFrameValid = getNewFilters(innerFilters).fromDate <= getNewFilters(innerFilters).toDate;

    return (
        <FiltersArea
            title="parts.filter.title"
            onSubmit={onSubmit}
            searchDisabled={isLoading || !filtersChanged || !isTimeFrameValid}
        >
            <DateIntervalFiltersGroup
                label={t('parts.filter.date.title')}
                key={'datetime'}
                onTimeFrameChange={onFieldValueChange('selectedTimeFrame')}
                timeFrame={innerFilters.selectedTimeFrame}
                timeFrames={timeFrames}
                hasCustomTime={false}
            />

            <TextFieldWithTags
                title={t('parts.filter.tid.title')}
                placeholderTextNoSelection={t('parts.filter.tid.noSelection')}
                placeholderTextSelection={t('parts.filter.tid.selection')}
                values={tids}
                onSelectionChange={(productIds) => {
                    onFieldValueChange('tids')(productIds);
                }}
                changeFontSize={true}
            />

            <TextFieldWithTags
                title={t('parts.filter.pid.title')}
                placeholderTextNoSelection={t('parts.filter.pid.noSelection')}
                placeholderTextSelection={t('parts.filter.pid.selection')}
                values={pids}
                onSelectionChange={(productIds) => {
                    onFieldValueChange('pids')(productIds);
                }}
                changeFontSize={true}
            />

            <FilterHeader>
                <Translation i18nKey="parts.filter.parttype.title" />
            </FilterHeader>
            <Autocomplete
                style={{ marginBottom: spacing(1) }}
                value={allPartTypes.filter((parttype) => parttypes.includes(parttype.partTypeName))}
                // value={allEolErrors.filter((eolError) => eolErrors.includes(eolError.error_number_specific))}
                multiple
                disableClearable
                id="checkboxes-tags"
                PopperComponent={(props) => (
                    <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
                )}
                options={allPartTypes}
                // groupBy={groupByLogic}
                disableCloseOnSelect
                getOptionLabel={(option) => option.partTypeName}
                renderTags={(value, props) => {
                    return value.map((option, index) => (
                        <>
                            {/* you can uncomment that line if you want to show the tags/chips inside the selectbox */}
                            {/* <Chip {...props({ index })} color="primary" size="small" label={option} /> */}
                        </>
                    ));
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {`${option.partTypeName}`}
                    </li>
                )}
                onChange={(event, checkedOptions) => {
                    onFieldValueChange('parttypes')(checkedOptions.map((option) => option.partTypeName));
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={t('parts.filter.parttype.noSelection')}
                        size="small"
                    />
                )}
            />
            <Box>
                {parttypes.map((data, index) => {
                    return (
                        <Tag
                            data={data.toString()}
                            key={index}
                            handleDelete={() => {
                                onFieldValueChange('parttypes')(parttypes.filter((parttype) => parttype !== data));
                            }}
                        />
                    );
                })}
            </Box>
        </FiltersArea>
    );
};
