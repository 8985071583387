import { KAPP_URL } from '../config/baseUrls';
import Kapp from '../models/Kapp';
import authorizedFetch from '../security/authorizedFetch';
import { IMachineDetailsFilters } from './ProductionPlantsService';

export interface IMachinesFilter {
    // tid: string | null;
    machines: string[];
    // timestamp: Date | null;
    // fromDate: Date;
    // toDate: Date;
    lines: string[];
    plantId: string;
}

export interface IKappResponse {
    tid: string;
    machine: string;
    machine_uid: string;
    source: string;
    order_analysis: [IOrderAnalysis];
    common: ICommon;
    workpiece: IWorkpiece;
    technology: ITechnology;
    corrections: [ICorrection];
    process: IProcess;
}
export interface ICommon {
    machine_id_kapp: string;
    machine_id_customer: string;
    // operator: string;
    // operator_key_id: string;
    accesslevel: string;
    version_plc: string;
    part_id: string;
    part_status: string;
    last_change: string;
    ip_adress: string;
}
export interface IProcess {
    green_part_error: IGreenPartError;
    processing_unit: number;
    tool_unbalance: number;
    temperature_coolant: number;

    temperature_ambient: number;
    index_tool_wear: number;
    index_noise: number;
    index_wear_infeed: number;
    index_wear_shift: number;

    index_wear_stroke: number;
    cycletime_processing: number;
    cycletime_floor_to_floor: number;
    parts_since_last_dressing: number;
    override_limited: number;

    last_dressing: Date;
    process_start: Date;
    process_end: Date;

    grinding_process_steps: IGrindingProcessStep[];
}
export interface IGrindingTool {
    index: number;
    ds: number;
    no_of_threads: number;
    hand_of_reference_helix: number;
    generative_angle: number;
}
export interface IGear {
    number_of_teeth: number;
    normal_module: number;
    pressure_angle: number;
    reference_helix_angle: number;
    face_width: number;
    da: number;
    df: number;
    dna: number;
    dnf: number;
    stock_green_part: number;
}
export interface IGrindingProcessStep {
    active: number;
    workpiece_rpm: number;
    grinding_process_strokes: IGrindingProcessStroke[];
}
export interface IGrindingProcessStroke {
    power_max: number;
    power_rms: number;
    shift_start_pos: number;
}
export interface IDressingStep {
    worm_rpm: number;
    form_wheel_rpm: number;
    path_speed: number;
    infeed_per_stroke: number;
    oscillation_active: number;
    direction_first_stroke: number;
    strokes: number;
}
export interface IDressingTechnology {
    timestamp: Date;
    dressing_steps: [IDressingStep];
}
export interface ITool {
    grinding_tool: IGrindingTool;
    dressing_technology: IDressingTechnology;
}
export interface IStroke {
    feed_mm_min: number;
    fed_mm_re: number;
    infeed_radial: number;
    shift_path_mm_str: number;
    shift_path_jump_end: number;
}
export interface IProcessingStep {
    tool_no: number;
    tool_speed: number;
    cutting_speed: number;
    q_apos_w: number;
    process_coolant_flow_rate: number;
    strokes: [IStroke];
}

export interface ITechnology {
    clamping_pressure: number;
    tailstock_active: boolean;
    tailstock_clamping_force: number;
    tools_count: number;
    tools: [ITool];
    processing_steps_count: number;
    processing_steps: [IProcessingStep];
}
export interface IWorkpiece {
    drawing_number: string;
    gears_count: number;
    gears: IGear[];
}
export interface IAxisCorrections {
    infeed_axis: number;
    shift_axis: number;
    stroke_axis: number;
    swivel_axis: number;
    workpiece_axis: number;
}
export interface ICorrection {
    tool: number;
    gear: number;
    axis_corrections: IAxisCorrections;
}
export interface IGreenPartError {
    minimum_deviation: number;
    maximum_deviation: number;
}

export interface IOrderAnalysis {
    sensor_name: string;
    channel: string;
    channel_type: string;
    base_frequency: number;
    filename: string;
    orders: [IOrder];
}

export interface IOrder {
    nr: number;
    max: number;
    mean: number;
}
interface GetKappResponse {
    kapp: Kapp | null;
}

const queryBuilder = (filters: IMachineDetailsFilters | null, machineNumber: string) => {
    if (filters?.selectedTids.length !== 0 && filters) {
        const query = new URLSearchParams();
        query.append('tid', filters.selectedTids[0].split('_')[0]);
        query.append('machine', machineNumber);

        return query;
    } else {
        return null;
    }
};

export default class KappService {
    getKappData(filters: IMachineDetailsFilters, machineNumber: string): Promise<GetKappResponse> {
        let query = queryBuilder(filters, machineNumber);
        return new Promise((resolve, reject) => {
            if (query == null) {
                resolve({ kapp: null });
            } else {
                authorizedFetch(KAPP_URL + '?' + queryBuilder(filters, machineNumber))
                    .then((response) => response.json())
                    .then((data) => {
                        if (data === null) resolve({ kapp: null });
                        resolve({
                            kapp: Kapp.fromJson(data),
                        });
                    })
                    .catch((e) => {
                        reject(e);
                    });
            }
        });
    }
}
