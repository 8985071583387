import { useEffect, useState } from 'react';
import EOLVerification from '../models/EOLVerification';
import ProductsService from '../services/ProductsService';

export interface UseProductVerificationsState {
    verifications: EOLVerification[] | null;
    loading: boolean;
    error: Error | null;
}
/**
 * Custom hook
 * Downloads a products given the productId
 * @param productId
 */
export const useProductVerifications: (productId: string) => UseProductVerificationsState = (productId: string) => {
    const [product, setProduct] = useState<EOLVerification[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        const productService = new ProductsService();
        productService
            .getProductVerifications(productId)
            .then((response) => {
                setProduct(response);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    }, [productId]);

    return {
        verifications: product,
        loading,
        error,
    };
};
