import EOLVerification from '../models/EOLVerification';
import { EOL_VERIFICATION_URL } from '../config/baseUrls';
import moment from 'moment';
import authorizedFetch from '../security/authorizedFetch';
import ISorting from '../interfaces/ISorting';

export interface IEolVerificationsFilter {
    fromDate: moment.Moment | null;
    toDate: moment.Moment | null;
    pids: string[];
    stations: string[];
    motorType: string[];
    eolPassed: boolean;
    eolNotPassed: boolean;
    eolErrors: number[];
}

export const defaultFilters = {
    fromDate: moment().startOf('date').toDate(),
    toDate: moment().endOf('date').toDate(),
    toDateUTC: moment().utc().endOf('date').toDate(),
    pids: new Array<string>(),
    stations: new Array<string>(),
    motorType: new Array<string>(),
    eolPassed: false,
    eolNotPassed: false,
    eolErrors: new Array<number>(),
};

export interface IError {
    error_column_number: number;
    error_sequence: number;
    mode_name: string;
    mode_id: number;
    channel_name: string;
    location_name: string;
    location_id: number;
    instrument_name: string;
    instrument_id: number;
    parameter_name: string;
    parameter_id: number;
    error_value: number;
    error_limit: number;
    error_position: number;
    unit_name: string;
    unit_id: number;
    source: string;
    etl_timestamp: Date;
    error_type_id: number;
    error_type_number_specific: number;
    error_type_text: string;
    error_type_source: string;
    error_type_timestamp: string;
}

export interface IEolVerificationResponse {
    id: string;
    pid: string;
    status: number;
    status_timestamp: Date;
    product_type: string;
    station_name: string;
    number_of_tests: number;
    errors: IError[];
}

interface GetEolVerificationsResponse {
    verificationsPage: EOLVerification[];
    total: number;
}

export const queryBuilder = (
    filters: IEolVerificationsFilter,
    limit: number,
    offset: number,
    sorting: ISorting | null
) => {
    const query = new URLSearchParams();
    if (filters.fromDate) {
        query.append('date_from', filters.fromDate.toISOString());
    }
    if (filters.toDate) {
        query.append('date_to', filters.toDate.toISOString());
    }
    filters.pids.forEach((pid) => query.append('pid_in', pid));
    filters.stations.forEach((station) => query.append('station', station)); // filter not yet implemented in the Backend
    filters.motorType.forEach((type) => query.append('type_in', type));
    query.append('passed', filters.eolPassed.toString());
    query.append('not_passed', filters.eolNotPassed.toString());
    filters.eolErrors.forEach((eolError) => query.append('eol_error_in', eolError.toString()));
    if (sorting) query.append('sort', `${sorting.column}:${sorting.direction}`);
    query.append('limit', limit.toString());
    query.append('offset', offset.toString());

    return query;
};

export default class EolVerificationsService {
    getVerifications(
        filters: IEolVerificationsFilter,
        offset: number,
        limit: number,
        sorting: ISorting | null
    ): Promise<GetEolVerificationsResponse> {
        return new Promise((resolve, reject) => {
            authorizedFetch(EOL_VERIFICATION_URL + '?' + queryBuilder(filters, limit, offset, sorting))
                .then((response) => response.json())
                .then((data) => {
                    resolve({
                        verificationsPage: data.verifications.map((verification: IEolVerificationResponse) =>
                            EOLVerification.fromJson(verification)
                        ),
                        total: data.total,
                    });
                });
        });
    }
}
