import { Machine } from './Machine';

export class ProductionStep {
    constructor(
        public machine: Machine,
        public timestamp: Date,
        public machineSection: number,
        public derivedFromLabel: number,
        public source: string
    ) {}

    static fromJson(json: any): ProductionStep {
        return new ProductionStep(
            Machine.fromJson(json.machine),
            new Date(json.timestamp),
            json.machine_section,
            json.derived_from_label,
            json.source
        );
    }
}
