import { useEffect, useState } from 'react';
import { Graph } from '../models/graph/Graph';
import ProductionPlantsService from '../services/ProductionPlantsService';

export interface UseGraphState {
    graph: Graph | null;
    loading: boolean;
    error: Error | null;
}

/**
 * Custom hook
 * Downloads a Graph given the PartType
 * @param partType
 */
export const useAssemblyLineGraph: (patType: string) => UseGraphState = (partType: string) => {
    const [graph, setGraph] = useState<Graph | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        const assemblyLineService: ProductionPlantsService = new ProductionPlantsService();
        assemblyLineService
            .getAssemblyLineByPartType(partType)
            .then((response) => {
                setGraph(response);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    }, [partType]);

    return {
        graph,
        loading,
        error,
    };
};
