import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18nPromise = new Promise((resolve, reject) => {
    i18n.use(Backend)
        // detect user language
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: 'en',
            debug: false,
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            react: {
                useSuspense: false, //   <---- this will do the magic
            },
        })
        .then(() => {
            i18n.loadLanguages(['en', 'de']).then(resolve).catch(reject);
        })
        .catch(reject);
});

export default i18nPromise;
