import { Graph } from '../models/graph/Graph';
import { graphs } from '../models/graph/Graph.mocks';
import { Machine } from '../models/Machine';
import authorizedFetch from '../security/authorizedFetch';
import { MACHINES_URL } from '../config/baseUrls';
import { defaultPlantId } from '../config/plantInfo';
import moment from 'moment';
import { ISorting } from '../hooks/useMachines';
import { IMachinesFilter } from './MachinesService';

const responseTime = 0; // milliseconds

export interface IMachineDetailsFilters {
    fromDate: Date | null;
    toDate: Date | null;
    tids: string[];
    limit?: number;
    offset?: number;
    selectedTimeFrame?: any;
    selectedTids: string[];
    machineSections: string[];
}

export const defaultFilters = {
    fromDate: moment().startOf('date').toDate(),
    toDate: moment().endOf('date').toDate(),
    tids: [],
    limit: 20,
    offset: 0,
    selectedTids: [],
    machineSections: [],
};

export interface MachineProductionStep {
    hasKappData: boolean;
    partTid: string;
    partType: string;
    machineNumber: string;
    productionDate: Date;
    spindel: number;
}

export interface GetMachineProductionStepsResponse {
    productionSteps: MachineProductionStep[];
    limit: number;
    offset: number;
    total: number;
}

export interface GetMachinesResponse {
    machines: Machine[];
    total: number;
}

const queryBuilder = (filters: IMachinesFilter, offset: number, limit: number, sorting: ISorting | null) => {
    const query = new URLSearchParams();
    const { lines, machines } = filters;
    machines.forEach((machine) => query.append('machine', machine));
    lines.forEach((line) => query.append('line', line));
    query.append('limit', limit.toString());
    query.append('offset', offset.toString());
    if (sorting) query.append('sort', `${sorting.column}:${sorting.direction}`);
    return query;
};

export default class ProductionPlantsService {
    getMachines(
        filters: IMachinesFilter,
        offset: number,
        limit: number,
        sorting: ISorting | null = null
    ): Promise<GetMachinesResponse> {
        const { plantId } = filters;
        return new Promise((resolve, reject) => {
            authorizedFetch(`${MACHINES_URL}/${plantId}?${queryBuilder(filters, offset, limit, sorting)}`)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function (responseAsJson) {
                    const response = {
                        machines: responseAsJson.machines.map((machine: any) => Machine.fromJson(machine)),
                        total: responseAsJson.total,
                    };
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    getMachine(plantId: string = defaultPlantId, machineNumber: string): Promise<Machine> {
        return new Promise((resolve, reject) => {
            authorizedFetch(`${MACHINES_URL}/${plantId}/${machineNumber}`)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function (responseAsJson) {
                    const machine = Machine.fromJson(responseAsJson);
                    resolve(machine);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    getMachineProductionSteps(
        plantId: string = defaultPlantId,
        machineNumber: string,
        filters: IMachineDetailsFilters = defaultFilters,
        offset: number,
        limit: number,
        sorting: ISorting | null = null
    ): Promise<any> {
        const { fromDate, toDate, tids, machineSections } = filters;
        const query = new URLSearchParams();
        query.append('limit', limit.toString());
        query.append('offset', offset.toString());
        if (fromDate) query.append('date_from', moment(fromDate).format());
        if (toDate) query.append('date_to', moment(toDate).format());
        tids.forEach((tid) => query.append('tid', tid));
        machineSections.forEach((section) => query.append('machine_section', section));
        if (sorting) query.append('sort', `${sorting.column}:${sorting.direction}`);

        return new Promise((resolve, reject) => {
            authorizedFetch(`${MACHINES_URL}/${plantId}/${machineNumber}/production_steps?${query}`)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function (responseAsJson) {
                    const response = {
                        productionSteps: responseAsJson.production_steps.map((jsonStep: any) => ({
                            hasKappData: jsonStep.has_kapp_data,
                            machineNumber: jsonStep.machine_number,
                            partTid: jsonStep.part_tid,
                            partType: jsonStep.part_type,
                            productionDate: new Date(jsonStep.production_date),
                            spindel: jsonStep.machine_section,
                        })),
                        limit: responseAsJson.limit,
                        offset: responseAsJson.offset,
                        total: responseAsJson.total,
                    };
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    getAssemblyLineByPartType(partType: string): Promise<Graph> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const graph = graphs(partType);
                if (graph === undefined) {
                    reject(new Error('Product not found'));
                } else {
                    resolve(graph);
                }
            }, responseTime);
        });
    }
}
