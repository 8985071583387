import moment from 'moment';
import React, { useState } from 'react';
import Kapp from '../../../domain/models/Kapp';
import { InfoIcon } from '../../base/icons/icons';
import { GridCol, GridRow } from '../../base/layout/Grid';
import Tooltip from '../../base/tooltip/tooltip';
import { Body1 } from '../../base/typography/textStyles';
import { toDateTimeStringGerman } from '../../base/utils';
import { primary } from '../../style/colors';
import spacing from '../../style/spacing';
import LeftNavigation from './LeftNavigation';
import { useTranslation } from 'react-i18next';

const TechnologyDetailInformation = (
    selectedElement: any | null,
    object: any,
    activeTab: string,
    name_of_object: string
) => {
    const keyValueArray = [];
    const { t } = useTranslation();

    if (selectedElement === null) return <></>;

    if (
        (selectedElement.firstTabId && activeTab === 'firstTabId') ||
        (selectedElement.secondTabId && activeTab === 'secondTabId') ||
        (selectedElement.thirdTabId && activeTab === 'thirdTabId') ||
        (selectedElement.forthTabId && activeTab === 'forthTabId') ||
        (selectedElement.forthTabId && activeTab === 'fifthsTabId')
    ) {
        const myArray = selectedElement[activeTab].split('.');
        for (let i = 0; i < myArray.length; i++) {
            let key = myArray[i];
            object = object[key];
        }

        for (const [key, value] of Object.entries(object)) {
            if (typeof value !== 'object') {
                keyValueArray.push({ key: key, value: value });
            }
        }
        let width = activeTab === 'firstTabId' ? '50%' : '30%';

        const specialFields = (arg: string) => {
            if (arg === 'machineDetails.analysis.common.accesslevel') {
                return (
                    <Tooltip
                        tooltipMessage={t('machineDetails.analysis.common.accesslevel.tooltip')}
                        style={{ height: '28px' }}
                    >
                        <InfoIcon style={{ marginLeft: spacing(0.5), color: primary }} />
                    </Tooltip>
                );
            }
            if (arg === 'machineDetails.analysis.technology_process.temperature_coolant') {
                return (
                    <Tooltip
                        tooltipMessage={t('machineDetails.analysis.technology_process.temperature_coolant.tooltip')}
                        style={{ height: '28px' }}
                    >
                        <InfoIcon style={{ marginLeft: spacing(0.5), color: primary }} />
                    </Tooltip>
                );
            }
            if (arg === 'machineDetails.analysis.technology_process.temperature_ambient') {
                return (
                    <Tooltip
                        tooltipMessage={t('machineDetails.analysis.technology_process.temperature_ambient.tooltip')}
                        style={{ height: '28px' }}
                    >
                        <InfoIcon style={{ marginLeft: spacing(0.5), color: primary }} />
                    </Tooltip>
                );
            }
            if (arg === 'machineDetails.analysis.technology_process.index_noise') {
                return (
                    <Tooltip
                        tooltipMessage={t('machineDetails.analysis.technology_process.index_noise.tooltip')}
                        style={{ height: '28px' }}
                    >
                        <InfoIcon style={{ marginLeft: spacing(0.5), color: primary }} />
                    </Tooltip>
                );
            } else {
                return <></>;
            }
        };

        // for the common part we don't need the LeftNavigation, therefore we don't need such a high margin space
        let marginSpaceLeft = 20;

        if (selectedElement.firstTabId && activeTab === 'firstTabId') {
            marginSpaceLeft = 1;
        }

        return (
            <div style={{ marginLeft: spacing(marginSpaceLeft), width: width }}>
                {keyValueArray.map((item, index) => {
                    toDateTimeStringGerman(new Date(String(item.value)));
                    return (
                        <GridRow
                            key={item.key + index}
                            alignItems="flex-start"
                            style={{ padding: spacing(0), marginLeft: spacing(1), margin: spacing(0) }}
                        >
                            <GridCol
                                xs={7}
                                xl={7}
                                style={{ textAlign: 'left', padding: spacing(0), marginTop: spacing(0) }}
                            >
                                <GridRow style={{ textAlign: 'left', padding: spacing(0), marginTop: spacing(0) }}>
                                    <Body1>
                                        {t(`machineDetails.analysis.${name_of_object}.${item.key}.title`)}{' '}
                                        {t(`machineDetails.analysis.${name_of_object}.${item.key}.unit`) ? (
                                            <div style={{ display: 'inline' }}>
                                                ({t(`machineDetails.analysis.${name_of_object}.${item.key}.unit`)})
                                            </div>
                                        ) : null}
                                    </Body1>
                                    {specialFields(`machineDetails.analysis.${name_of_object}.${item.key}`)}
                                </GridRow>
                            </GridCol>
                            <GridCol
                                xs={5}
                                xl={5}
                                style={{ textAlign: 'left', padding: spacing(0), margin: spacing(0) }}
                            >
                                {moment(String(item.value), 'YYYY-MM-DDTHH:mm:ss', true).isValid() ? (
                                    <Body1>{`${toDateTimeStringGerman(new Date(String(item.value)))}`}</Body1>
                                ) : (
                                    <Body1>{`${item.value}`}</Body1>
                                )}
                            </GridCol>
                        </GridRow>
                    );
                })}
            </div>
        );
    } else {
        return <></>;
    }
};

export const AdditionalInformation = ({ activeTab, kappPage }: { activeTab: string; kappPage: Kapp }) => {
    const [selectedElement, setSelectedElement] = useState<any | null>({
        firstTabId: 'common',
        secondTabId: 'workpiece',
        thirdTabId: 'technology',
        forthTabId: 'process',
        fifthsTabId: 'corrections.0',
    });

    const handleSelect = (arg: string) => {
        setSelectedElement({ ...selectedElement, [activeTab]: arg });
    };

    if (activeTab === 'firstTabId') {
        return <div>{TechnologyDetailInformation(selectedElement, kappPage, activeTab, 'common')}</div>;
    }

    if (activeTab === 'secondTabId') {
        const workpiece = kappPage.workpiece;

        return (
            <div style={{ width: '100%' }}>
                <GridRow style={{ marginTop: spacing(2) }}>
                    <LeftNavigation
                        object={workpiece}
                        setSelectedElement={handleSelect}
                        selectedElement={selectedElement}
                        style={{ width: '300px' }}
                    />
                    {TechnologyDetailInformation(selectedElement, kappPage, activeTab, 'workpiece')}
                </GridRow>
            </div>
        );
    }
    if (activeTab === 'thirdTabId') {
        const technology = kappPage.technology;
        return (
            <div style={{ width: '100%' }}>
                <GridRow style={{ marginTop: spacing(0) }}>
                    <LeftNavigation
                        object={technology}
                        setSelectedElement={handleSelect}
                        selectedElement={selectedElement}
                        style={{ width: '300px' }}
                    />
                    {TechnologyDetailInformation(selectedElement, kappPage, activeTab, 'technology_tooling')}
                </GridRow>
            </div>
        );
    }
    if (activeTab === 'forthTabId') {
        const process = kappPage.process;
        return (
            <div style={{ width: '100%' }}>
                <GridRow style={{ marginTop: spacing(2) }}>
                    <LeftNavigation
                        object={process}
                        setSelectedElement={handleSelect}
                        selectedElement={selectedElement}
                        style={{ width: '300px' }}
                    />
                    {TechnologyDetailInformation(selectedElement, kappPage, activeTab, 'technology_process')}
                </GridRow>
            </div>
        );
    }
    if (activeTab === 'fifthsTabId') {
        const corrections = kappPage.corrections;

        return (
            <div style={{ width: '100%' }}>
                <GridRow style={{ marginTop: spacing(2) }}>
                    <LeftNavigation
                        object={corrections}
                        setSelectedElement={handleSelect}
                        selectedElement={selectedElement}
                        style={{ width: '300px' }}
                    />
                    {TechnologyDetailInformation(selectedElement, kappPage, activeTab, 'corrections')}
                </GridRow>
            </div>
        );
    } else {
        return <></>;
    }
};
