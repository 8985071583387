import { dataObjectToQueryObject } from '../../components/pages/enginesPage';
import { getQueryString } from '../../components/utils/URLService';
import { PRODUCTS_URL } from '../config/baseUrls';
import { ISorting } from '../hooks/useEngines';
import { Engine } from '../models/Engine';
import authorizedFetch from '../security/authorizedFetch';

export interface INumberOfEOLVerificationsSpread {
    min: number | null;
    max: number | null;
}
export interface GetEngineResponse {
    engines: Engine[];
    total: number;
    offset: number;
    limit: number;
}
export interface IEngineFilters {
    fromDate: Date | null;
    toDate: Date | null;
    fromDateUTC: Date | null;
    toDateUTC: Date | null;
    directPassStatus: number[];
    numberOfEOLVerificationsSpread: INumberOfEOLVerificationsSpread;
    pids: string[];
}
export interface IEngineResponse {
    pid: string;
    pid_date: Date;
    number_of_verifications: number;
    direct_pass_value: number;
}
export default class EnginesService {
    getEngines(
        filters: IEngineFilters,
        offset: number,
        limit: number,
        sorting: ISorting | null
    ): Promise<GetEngineResponse> {
        return new Promise((resolve, reject) => {
            authorizedFetch(
                `${PRODUCTS_URL}?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, offset))}`
            )
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function (responseAsJson) {
                    resolve({
                        engines: responseAsJson.products.map((engine: IEngineResponse) => Engine.fromJson(engine)),
                        total: responseAsJson.total,
                        limit: responseAsJson.limit,
                        offset: responseAsJson.offset,
                    });
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }
}
