import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import React, { useEffect } from 'react';
import './App.css';
import { FlexContainer, FlexGrow } from './components/base/layout/Flex';
import EnginesPage from './components/pages/enginesPage';
import EolVerificationsPage from './components/pages/eolVerifications';
import HomePage from './components/pages/home';
import WithAuthorization from './components/pages/login/WithAuthorization';

import PartDetailsPage from './components/pages/partDetails';
import ProductDetailsPage from './components/pages/productDetails';
import theme from './components/style/theme';
import { getUrlParams } from './components/utils/URLService';
import MachineDetailsPage from './components/pages/machineDetails';
import ISorting from './domain/interfaces/ISorting';
import PartsPage from './components/pages/parts';
import MachinesPage from './components/pages/machines';
import { Routes, Route, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { loadLastTimeFrame } from './domain/utils/getDates';

function Redirect({ to }: { to: string }) {
    let navigate = useNavigate();
    useEffect(() => {
        navigate(to);
    });
    return null;
}

const HomePageRoute = () => <HomePage />;

const EolVerificationsPageRoute = () => {
    const [searchParams] = useSearchParams(); // inside your component

    const fromDateString = searchParams.get('fromDate');
    const toDateString = searchParams.get('toDate');
    const code = searchParams.getAll('eolErrorCode').map(Number);
    const fromDate = fromDateString ? new Date(fromDateString) : loadLastTimeFrame().fromDate;
    const toDate = toDateString ? new Date(toDateString) : loadLastTimeFrame().toDate;
    const notPassed = searchParams.has('notPassed') ? searchParams.get('notPassed') === '1' : null;
    const passed = searchParams.has('passed') ? searchParams.get('passed') === '1' : null;
    const productType = searchParams.getAll('productType');
    const stations = searchParams.getAll('station');
    const pid_in = searchParams.getAll('pid_in');
    const sortString = searchParams.get('sort');
    const urlLimit = searchParams.get('limit') ? Number(searchParams.get('limit')) : null;
    const urlOffset = searchParams.get('offset') ? Number(searchParams.get('offset')) : null;

    const currentSorting: ISorting | null = sortString
        ? {
              column: sortString.substring(0, sortString.indexOf(':')),
              direction: sortString.substring(sortString.indexOf(':') + 1),
          }
        : null;

    return (
        <EolVerificationsPage
            productType={productType}
            fromDate={fromDate}
            toDate={toDate}
            eolError={code}
            notPassed={notPassed}
            passed={passed}
            stations={stations}
            pid_in={pid_in}
            currentSorting={currentSorting}
            urlLimit={urlLimit}
            urlOffset={urlOffset}
        />
    );
};
const MachinesPageRoute = () => {
    const [searchParams] = useSearchParams();
    return <MachinesPage searchParams={getUrlParams(searchParams)} />;
};
const EnginesPageRoute = () => {
    const [searchParams] = useSearchParams();
    return <EnginesPage searchParams={getUrlParams(searchParams)} />;
};

const PartsPageRoute = () => {
    const [searchParams] = useSearchParams();
    return <PartsPage searchParams={getUrlParams(searchParams)} />;
};

const ProductDetailsPageRoute = () => {
    let { productId, verificationId } = useParams();

    return productId !== undefined ? (
        <ProductDetailsPage verificationId={verificationId} productId={productId} />
    ) : (
        <HomePageRoute />
    );
};

const PartDetailsPageroute = () => {
    let { partId } = useParams();

    return partId !== undefined ? <PartDetailsPage partId={partId} /> : <Redirect to="/" />;
};

const MachineDetailsPageRoute = () => {
    const [searchParams] = useSearchParams();
    let { machineNumber } = useParams();

    return machineNumber !== undefined ? (
        <MachineDetailsPage machineNumber={machineNumber} searchParams={getUrlParams(searchParams)} />
    ) : (
        <Redirect to="/" />
    );
};

function App() {
    return (
        <WithAuthorization>
            <div className="App">
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <FlexContainer fullWidth={true}>
                            <FlexGrow flexGrow={1}>
                                <Routes>
                                    <Route path="/" element={<HomePage />} />
                                    <Route path="/eolVerifications" element={<EolVerificationsPageRoute />} />
                                    <Route
                                        path="/products/:productId/verification/:verificationId"
                                        element={<ProductDetailsPageRoute />}
                                    />
                                    <Route path="/products/:productId" element={<ProductDetailsPageRoute />} />
                                    <Route
                                        path="/products/:productId/parts/:partId"
                                        element={<PartDetailsPageroute />}
                                    />
                                    <Route path="/engines" element={<EnginesPageRoute />} />
                                    <Route path="/machines" element={<MachinesPageRoute />} />
                                    <Route path="/parts" element={<PartsPageRoute />} />
                                    <Route path="/machines/:machineNumber" element={<MachineDetailsPageRoute />} />
                                </Routes>
                            </FlexGrow>
                        </FlexContainer>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        </WithAuthorization>
    );
}

export default App;
