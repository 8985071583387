import IStyledProps from '../IStyledProps';
import IOthersProps from '../IOthersProps';
import React from 'react';
import { FlexContainer } from './Flex';
import { Body1, H4 } from '../typography/textStyles';
import Translation from '../translation/Translation';
import spacing from '../../style/spacing';
import { GroupuiTag } from '@group-ui/group-ui-react';
import { grey800 } from '../../style/colors';
import { IDataTestProps } from '../IDataTestProps';

export interface ITitleBarProps extends IStyledProps, IOthersProps, IDataTestProps {
    title: JSX.Element | string;
    subtitle?: JSX.Element | string;
    actions?: JSX.Element;
    resultNumber?: number;
}

export const TitleBar: React.FC<ITitleBarProps> = ({
    title,
    subtitle,
    actions = null,
    resultNumber,
    style = {},
    dataTestid = '',
    ...others
}) => (
    <FlexContainer justifyContent="space-between" alignItems="center" dataTestid={dataTestid} style={style} {...others}>
        <div>
            {subtitle && (
                <Body1 noGutter style={{ color: grey800, marginBottom: spacing(1) }}>
                    <Translation i18nKey={subtitle} />
                </Body1>
            )}
            <FlexContainer alignItems="center">
                <H4 noGutter weight="bold" style={{ color: grey800 }}>
                    <Translation i18nKey={title} />
                </H4>
                {resultNumber ? (
                    <GroupuiTag style={{ marginLeft: spacing(1) }} square={false}>
                        {resultNumber}
                    </GroupuiTag>
                ) : (
                    <></>
                )}
            </FlexContainer>
        </div>
        <div>{actions}</div>
    </FlexContainer>
);
