export const positive = '#64A844';
export const negative = '#DA0C1F';
export const warning = '#FF9B00';

export const lightText = '#ffffff';

export const hoverColor = '#F0F1F2';
export const hoverColorOpaque20 = 'rgba(240, 241, 242, 0.20)';
export const primary = '#00354D';
export const primaryOpaque80 = 'rgba(32, 96, 126, 0.80)';
export const secondary = '#E6C95F';

export const transparent = 'transparent';
export const black = 'black';
export const darkGrey = '#4f4f4f';
export const pieChartColors = ['#1F4E63', '#4E839C', '#80B0C8', '#68752F', '#95A844', darkGrey];
export const greyDividerColor = 'rgba(0, 0, 0, 0.12)';
export const skeletonLoadingColor = 'rgba(0, 0, 0, 0.05)';
export const grey800 = '#4C5356';
export const grey600 = '#7A8084';
export const backgroundColor = '#F0F1F2';
export const dividerColor = '#DFE2E6';
export const iconBright = '#A8ADB3';
export const iconDark = '#20607E';
export const nodeSelectedColor = '#E2F7F7';
export const nodeDerviedFromLabelColor = '#3BD8DD';
export const nodeBorderColor = '#979797';
