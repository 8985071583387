// import React from 'react';
// import { ProductionStepsTable } from './ProductionStepsTable';
// import { GetMachineProductionStepsResponse } from '../../../../domain/services/ProductionPlantsService';
// import Card from '../../../base/Card';

import React from 'react';
import { Table } from '../../../base/table/Table';
import ISorting from '../../../../domain/interfaces/ISorting';
import Card from '../../../base/Card';
import spacing from '../../../style/spacing';
import { MachineProductionStep } from '../../../../domain/services/ProductionPlantsService';
import { ProductionStepsHeader, ProductionStepsRow } from './ProductionStepsTableRows';

interface IProductionStepsTableProps {
    onRowClick?: (rowIdx: number) => void;
    onNextPageClicked?: () => void;
    onPrevPageClicked?: () => void;
    setSorting: (column: string) => void;
    sorting: ISorting | null;
    offset?: number;
    limit?: number;
    total: number;
    isLoading?: boolean;
    rows: MachineProductionStep[];
    setPage: (page: number) => void;
    onTidClicked: (arg: string[]) => void;
    tids: string[];
}

export const ProductionStepsTable: React.FC<IProductionStepsTableProps> = ({
    rows,
    total,
    offset = 0,
    limit = 25,
    isLoading = false,
    sorting,
    onRowClick = () => {},
    onPrevPageClicked = () => {},
    onNextPageClicked = () => {},
    setSorting = (column: string) => {},
    setPage = (page: number) => {},
    onTidClicked = () => {},
    tids,
}: IProductionStepsTableProps) => {
    const onCheckboxClicked = (tid_date: string) => {
        // LOGIC FOR CHECKBOXES WHEN THERE IS MULTISELECT POSSIBLE
        // if (tids.includes(tid)) {
        //     onTidClicked(tids.filter((exisitngTid) => exisitngTid !== tid));
        // } else {
        //     onTidClicked([...tids, tid]);
        // }

        onTidClicked([tid_date]);
    };
    return (
        <>
            <Card style={{ marginTop: spacing(3) }} height="100%">
                <Table columns={7} header={true} size="s" sortable={false} isLoading={isLoading}>
                    <ProductionStepsHeader sorting={sorting} setSorting={setSorting} />
                    {rows.map((row, idx) => (
                        <ProductionStepsRow
                            onTidClicked={(arg: string) => onCheckboxClicked(arg)}
                            dataTestid={`ProductBrowser-Row-${idx}`}
                            onClick={() => onRowClick(idx)}
                            key={idx}
                            productionStep={row}
                            checked={tids.includes(row.partTid + '_' + row.productionDate.toISOString())}
                        />
                    ))}
                </Table>
            </Card>
        </>
    );
};
