import React, { useState } from 'react';
import ProductDetails from './ProductDetails';
import Page from '../../base/layout/Page';
import { FlexContainer } from '../../base/layout/Flex';
import { BarsProgress } from '../../base/loaders/BarsProgress';
import { useProduct } from '../../../domain/hooks/useProduct';
import { Part } from '../../../domain/models/Part';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useProductVerifications } from '../../../domain/hooks/useProductVerifications';

const ProductDetailsPage = ({
    productId,
    verificationId = undefined,
}: {
    productId: string;
    verificationId?: string;
}) => {
    const [error] = useState<string | null>(null);
    const { product, loading } = useProduct(productId);
    const { verifications, loading: loadingVerifications } = useProductVerifications(productId);
    const { t } = useTranslation();
    let navigate = useNavigate();

    const onPartClicked = (part: Part) => {
        navigate(`/products/${productId}/parts/${part.tid}`);
    };

    const loadingPage = (
        <FlexContainer style={{ height: '600px' }} fullWidth justifyContent="center" alignItems="center">
            <BarsProgress />
        </FlexContainer>
    );

    const errorPage = (
        <FlexContainer style={{ height: '600px' }} fullWidth justifyContent="center" alignItems="center">
            {error}
        </FlexContainer>
    );

    return (
        <Page title={t('productDetails.title')} loading={loading}>
            {loading || loadingVerifications ? loadingPage : null}
            {error !== null ? errorPage : null}
            {product !== null && verifications !== null ? (
                <ProductDetails
                    selectedVerificationId={verificationId}
                    verifications={verifications || []}
                    product={product}
                    onPartClicked={onPartClicked}
                />
            ) : null}
        </Page>
    );
};

export default ProductDetailsPage;
