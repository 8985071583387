import { Edge } from './Edge';
import { Node } from './Node';
import { UiContainer } from './UiContainer';

export class Graph {
    constructor(
        public name: string,
        public validityDateStart: Date,
        public validityDateEnd: Date,
        public edges: Edge[],
        public uiData: UiContainer[] | null
    ) {}

    getInputsByNode(node: Node): Edge[] {
        return this.edges.filter((edge) => node.id === edge.targetNode.id);
    }

    getOutputsByNode(node: Node): Edge[] {
        return this.edges.filter((edge) => node.id === edge.sourceNode.id);
    }

    static fromJson(json: any): Graph {
        let edges = [];
        if (json.edges) {
            edges = json.edges.map((edge: Edge) => Edge.fromJson(edge));
        }
        let uiData = [];
        if (json.uiData) {
            uiData = json.uiData.map((uiData: UiContainer) => UiContainer.fromJson(uiData));
        }

        return new Graph(json.name, json.validityDateStart, json.validityDateEnd, edges, uiData);
    }
}
