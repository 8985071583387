export const getQueryString = (queryObject: any): URLSearchParams => {
    let queryString = new URLSearchParams();
    for (let [key, value] of Object.entries(queryObject)) {
        if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
        } else {
            if (typeof value === 'string' && value.length !== 0) {
                queryString.append(key, value);
            }
            if (Array.isArray(value)) {
                value
                    .filter((value) => typeof value === 'string' && value.length !== 0)
                    .forEach((value) => queryString.append(key, value));
            }
        }
    }

    return queryString;
};

export const getUrlParams = (search: string | URLSearchParams): any => {
    let urlSeachParams;
    if (typeof search === 'string' || search instanceof String) {
        if (search === '' || search.indexOf('?') === -1) {
            return {};
        }
        urlSeachParams = new URLSearchParams(search);
    } else {
        urlSeachParams = search;
    }

    // it's a string
    console.log(urlSeachParams);
    const params: any = {};
    for (let pair of urlSeachParams.entries()) {
        if (!params[pair[0]]) {
            params[pair[0]] = [pair[1]];
        } else {
            params[pair[0]].push(pair[1]);
        }
    }
    return params;
};
