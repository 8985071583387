const apiUrl = process.env.REACT_APP_BACKEND_BASEURL;

export const EOL_VERIFICATION_URL = `${apiUrl}/eol_verifications`;
export const PRODUCTS_URL = `${apiUrl}/products`;
export const STATS_URL = `${apiUrl}/stats`;
export const EOL_ERRORS_URL = `${apiUrl}/eol_errors`;
export const PARTS_URL = `${apiUrl}/parts`;
export const PRODUCTION_PLANTS_URL = `${apiUrl}/production_plants`;
export const MACHINES_URL = `${apiUrl}/machines`;
export const KAPP_URL = `${apiUrl}/kapp_by_tid`;
export const FILE_EXPORT_URL = `${apiUrl}/eol_verifications_file_export`;

export const SZ_ONLINE_URL = 'https://portal.volkswagen-mqs.de';

const wikiUrl = process.env.REACT_APP_HELP_WIKI_URL;
export const HELP_WIKI_URL = wikiUrl;
