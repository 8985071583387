import React from 'react';
import { FiltersGroup } from './FiltersGroup';
import { useTranslation } from 'react-i18next';
import TextFieldWithTags from './TextFieldWithTags';

interface IIdentificationNumbersFiltersGroupProps {
    productIds: string[] | [];
    onPidsChange: (newPids: string[]) => void;
    stations?: string[] | [];
    onStationsChange?: (newStations: string[]) => void;
    motorTypes?: string[] | [];
    onMotorTypesChange?: (newMotorTypes: string[]) => void;
}

export const IdentificationNumbersFiltersGroup: React.FC<IIdentificationNumbersFiltersGroupProps> = ({
    productIds,
    onPidsChange,
    motorTypes,
    onMotorTypesChange,
    stations,
    onStationsChange,
}) => {
    const { t } = useTranslation();

    return (
        <FiltersGroup title="">
            <TextFieldWithTags
                title={t('productsFilter.IDs.PIDs')}
                placeholderTextNoSelection={t('productsFilter.IDs.noSelection')}
                placeholderTextSelection={t('productsFilter.IDs.selection')}
                values={productIds}
                onSelectionChange={(productIds) => {
                    onPidsChange(productIds);
                }}
                changeFontSize={true}
            />
            {motorTypes && onMotorTypesChange ? (
                <TextFieldWithTags
                    dataTestid="pid-text-field"
                    title={t('productsFilter.IDs.type')}
                    placeholderTextNoSelection={t('productsFilter.type.noSelection')}
                    placeholderTextSelection={t('productsFilter.type.selection')}
                    values={motorTypes}
                    onSelectionChange={(types) => {
                        onMotorTypesChange(types);
                    }}
                    changeFontSize={true}
                />
            ) : (
                <></>
            )}
            {stations && onStationsChange ? (
                <TextFieldWithTags
                    title={t('productsFilter.IDs.station')}
                    placeholderTextNoSelection={t('productsFilter.station.noSelection')}
                    placeholderTextSelection={t('productsFilter.station.selection')}
                    values={stations}
                    onSelectionChange={(stations) => {
                        onStationsChange(stations);
                    }}
                    changeFontSize={true}
                />
            ) : (
                <></>
            )}
        </FiltersGroup>
    );
};
