import React, { useState } from 'react';
import Page from '../../base/layout/Page';
import { usePart } from '../../../domain/hooks/usePart';
import { FlexContainer } from '../../base/layout/Flex';
import { BarsProgress } from '../../base/loaders/BarsProgress';
import { useTranslation } from 'react-i18next';
import { PartDetails } from './PartDetails';
import { usePartChildren } from '../../../domain/hooks/usePartChildren';

const PartDetailsPage = ({ partId }: { partId: string }) => {
    const [tempPartId, setTempPartId] = useState<string>(partId);
    const [loadingParents, setLoadingParents] = useState<boolean>(true);
    let { part, loading: loadingPart, error: errorPart } = usePart(tempPartId);
    const { partChildren, loading: loadingPartChildren, error: partChildrenError } = usePartChildren(tempPartId);

    //then a part has a parentID, therefore is itself a subcomponent, it will load the parentID
    if (part !== null && part.parent_tid !== null && loadingPartChildren === false) {
        if (tempPartId !== part.parent_tid) {
            setTempPartId(part.parent_tid);
        }
    } else {
        if (loadingParents) {
            setLoadingParents(false);
        }
    }

    const { t } = useTranslation();

    const loading = loadingPart || loadingPartChildren || loadingParents;
    const error = errorPart !== null || partChildrenError !== null;
    const loadingPage = (
        <FlexContainer style={{ height: '600px' }} fullWidth justifyContent="center" alignItems="center">
            <BarsProgress />
        </FlexContainer>
    );

    const errorPage = (
        <FlexContainer style={{ height: '600px' }} fullWidth justifyContent="center" alignItems="center">
            {error}
        </FlexContainer>
    );

    return (
        <Page title={t('partDetails.title')} loading={loading}>
            {loading ? loadingPage : null}
            {error ? errorPage : null}
            {part !== null && partChildren !== null && !loading ? (
                <PartDetails part={part} partChildren={partChildren} />
            ) : null}
        </Page>
    );
};

export default PartDetailsPage;
