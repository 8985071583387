import IStyledProps from '../IStyledProps';
import { BarsProgress } from './BarsProgress';
import { FlexContainer } from '../layout/Flex';
import React from 'react';

interface IFullPageLoaderProps extends IStyledProps {}

const FullPageLoader: React.FC<IFullPageLoaderProps> = ({ style = {} }) => (
    <FlexContainer style={{ height: '600px', ...style }} fullWidth justifyContent="center" alignItems="center">
        <BarsProgress />
    </FlexContainer>
);

export default FullPageLoader;
