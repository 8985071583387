import React from 'react';
import { grey800, primary } from '../../style/colors';
import { ArrowBackIcon, ArrowDownIcon, ArrowForwardIcon } from '../icons/icons';
import { IDataTestProps } from '../IDataTestProps';
import IOthersProps from '../IOthersProps';
import { Body2 } from '../typography/textStyles';
import { FlexContainer } from '../layout/Flex';
import Divider from '@mui/material/Divider';
import spacing from '../../style/spacing';
import { FormControl, MenuItem, Select } from '@mui/material';
import { pad } from '../utils';
import { Button } from '../buttons/Button';

export interface IPaginatorProps extends IOthersProps, IDataTestProps {
    total: number;
    offset: number;
    limit: number;
    onPrevPageClicked: () => void;
    onNextPageClicked: () => void;
    setPage: (page: number) => void;
}

export const Paginator: React.FC<IPaginatorProps> = ({
    total,
    offset,
    limit,
    onNextPageClicked = () => {},
    onPrevPageClicked = () => {},
    setPage = (page: number) => {},
}) => {
    if (total === 0) {
        return <div style={{ display: 'flex', alignItems: 'flex-end' }} />;
    }

    const hasNext = offset + limit < total;
    const hasPrev = offset > 0;
    const currentPage = offset / limit + 1;
    const maxPage = Math.ceil(total / limit);
    const pages: number[] = Array.from({ length: maxPage }, (_, i) => i + 1);

    const PrevButton = () => (
        <>
            <Button
                iconOnly={true}
                disabled={!hasPrev}
                variant="tertiary"
                color={primary}
                onClick={onPrevPageClicked}
                dataTestid="Paginator-PrevButton"
            >
                <ArrowBackIcon height={30} width={30} />
            </Button>
            <Divider
                style={{ marginLeft: spacing(2), marginRight: spacing(2) }}
                orientation="vertical"
                flexItem
            ></Divider>
        </>
    );
    const NextButton = () => (
        <>
            <Divider
                style={{ marginLeft: spacing(2), marginRight: spacing(2) }}
                orientation="vertical"
                flexItem
            ></Divider>
            <Button
                iconOnly={true}
                disabled={!hasNext}
                variant="tertiary"
                color={primary}
                onClick={onNextPageClicked}
                dataTestid="Paginator-NextButton"
            >
                <ArrowForwardIcon height={30} width={30} />
            </Button>
        </>
    );

    const iconStyle = {
        position: 'absolute',
        top: '10',
        right: '0',
        display: 'inline-block',
        width: '24px',
        height: '24px',
        pointerEvents: 'none',
    };

    return (
        <FlexContainer alignItems="center" justifyContent="center">
            <Body2 style={{ color: grey800 }}>
                {pad(currentPage, 2)} of {pad(maxPage, 2)} pages
            </Body2>
            <Divider
                style={{ marginLeft: spacing(2), marginRight: spacing(2) }}
                orientation="vertical"
                flexItem
            ></Divider>
            <PrevButton />

            <FormControl>
                <Select
                    style={{ padding: '0px', paddingRight: '10px', color: primary, fontWeight: 'bold' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentPage}
                    label="Page"
                    disableUnderline
                    IconComponent={() => <ArrowDownIcon style={iconStyle} />}
                    onChange={(e: any) => setPage(e.target.value)}
                >
                    {pages.map((page, idx) => (
                        <MenuItem value={page} key={idx}>
                            {' '}
                            {pad(page, 2)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <NextButton />
        </FlexContainer>
    );
};
