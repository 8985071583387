import React from 'react';
import { GridCol, GridRow } from '../../base/layout/Grid';
import spacing from '../../style/spacing';
import { Container } from '../../base/layout/Container';
import ISorting from '../../../domain/interfaces/ISorting';
import { Machine } from '../../../domain/models/Machine';
import { IMachinesFilter } from '../../../domain/services/MachinesService';
import { MachinesFiltersForm } from './MachinesFiltersForm';
import { MachinesTable } from './MachineTable/MachinesTable';

export interface IMachinesBrowserProps {
    productLines: string[];
    machinesPage: Machine[];
    total: number;
    offset: number;
    limit: number;
    filters: IMachinesFilter;
    isLoading?: boolean;
    sorting: ISorting | null;
    onMachineClicked?: (machine: Machine) => void;
    onNextPageClicked?: () => void;
    onPrevPageClicked?: () => void;
    onSearchClicked?: (filters: IMachinesFilter) => void;
    setSorting: (column: string) => void;
    setPage: (page: number) => void;
}

const MachinesBrowser: React.FC<IMachinesBrowserProps> = ({
    productLines,
    machinesPage,
    total,
    offset = 0,
    limit = 25,
    filters,
    isLoading = false,
    sorting,
    onPrevPageClicked = () => {},
    onNextPageClicked = () => {},
    onMachineClicked = () => {},
    onSearchClicked = () => {},
    setSorting = () => {},
    setPage = () => {},
}: IMachinesBrowserProps) => {
    const filtersProps = {
        filters,
        isLoading,
        onSearchClicked: onSearchClicked,
    };

    const tableProps = {
        isLoading,
        rows: machinesPage,
        onNextPageClicked,
        onPrevPageClicked,
        total,
        limit,
        offset,
        sorting,
        onRowClick: (idx: number) => onMachineClicked(machinesPage[idx]),
        setSorting,
        setPage,
    };

    return (
        <>
            <GridRow style={{ minHeight: '50vh' }}>
                <GridCol xs={3} xl={2} noPadding>
                    <MachinesFiltersForm productLines={productLines} {...filtersProps} />
                </GridCol>
                <GridCol xs={9} xl={10} style={{ paddingLeft: spacing(4), paddingBottom: '0px' }}>
                    <Container style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <MachinesTable {...tableProps} isLoading={isLoading} />
                    </Container>
                </GridCol>
            </GridRow>
        </>
    );
};

export default MachinesBrowser;
