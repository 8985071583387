import { Machine } from '../Machine';
import { Grid } from './Grid';

export class Node {
    constructor(public id: string, public machine: Machine | undefined, public coordinates: Grid) {}

    static fromJson(json: any): Node {
        return new Node(json.id, json.machine, json.coordinates);
    }
}
