import React from 'react';
import ReactFlow, { ReactFlowProvider, useReactFlow } from 'react-flow-renderer';
import { Part } from '../../../domain/models/Part';
import { useAssemblyLineGraph } from '../../../domain/hooks/useGraph';
import { CustomizedNode } from '../../../domain/models/graph/ReactFlowNode';
import { createFlowEdges, createFlowElements } from '../../../domain/utils/reactFlow';
import FullPageLoader from '../../base/loaders/FullPageLoader';
import { GridRow } from '../../base/layout/Grid';
import { greyDividerColor, nodeBorderColor, nodeDerviedFromLabelColor, nodeSelectedColor } from '../../style/colors';
import { Caption } from '../../base/typography/textStyles';
import spacing from '../../style/spacing';
import IStyledProps from '../../base/IStyledProps';
import { useTranslation } from 'react-i18next';
import { Divider } from '../../base/layout/Divider';
import { Button } from '../../base/buttons/Button';
import { FlexContainer } from '../../base/layout/Flex';
import { PartNumber } from './PartNumber';
interface IStepBoxProps extends IStyledProps {}

const StepBox = ({ style = {} }: IStepBoxProps) => {
    const boxStyle = {
        borderRadius: `2px`,
        height: '24px',
        width: '24px',
        ...style,
    };

    return <div style={boxStyle}>&nbsp;</div>;
};

interface IStepExplanationProps {
    text: string;
    boxStyle: any;
}

const StepExplanation = ({ boxStyle = {}, text }: IStepExplanationProps) => {
    const explanationTextStyle = {
        marginLeft: spacing(2),
        maxWidth: '280px',
    };

    const explanationRowStyle = {
        width: '340px',
    };

    return (
        <GridRow noPadding={true} alignItems="flex-start" style={explanationRowStyle}>
            <StepBox style={boxStyle} />
            <Caption style={explanationTextStyle}>{text}</Caption>
        </GridRow>
    );
};
const FlowHeader = ({ part }: { part: Part }) => {
    const reactFlowInstance = useReactFlow();
    return (
        <FlexContainer alignItems="flex-end" style={{ marginBottom: spacing(3) }}>
            <PartNumber part={part} />

            <Button
                iconOnly={true}
                icon="search-plus-24"
                onClick={() => reactFlowInstance.zoomIn()}
                style={{ marginLeft: spacing(1) }}
            />
            <Button
                iconOnly={true}
                icon="search-minus-24"
                onClick={() => reactFlowInstance.zoomOut()}
                style={{ marginLeft: spacing(1) }}
            />
        </FlexContainer>
    );
};
const Legend = () => {
    const { t } = useTranslation();

    const style = {
        padding: spacing(2),
    };

    const fromLabelStyle = {
        border: `2px solid ${nodeDerviedFromLabelColor}`,
    };

    const fromDataStyle = {
        border: `2px solid ${nodeSelectedColor}`,
        backgroundColor: nodeSelectedColor,
    };

    const fromDataAndLabelStyle = {
        border: `2px solid ${nodeDerviedFromLabelColor}`,
        backgroundColor: nodeSelectedColor,
    };

    const notUsedStyle = {
        border: `1px solid  ${nodeBorderColor}`,
        padding: '2px',
    };
    const boughtComponentStyle = {
        border: `1px dashed  ${nodeBorderColor}`,
        padding: '2px',
    };

    return (
        <div style={style}>
            <GridRow noPadding={true} alignItems="flex-start">
                <StepExplanation text={t('partDetails.graphLegend.fromLabelExplanation')} boxStyle={fromLabelStyle} />
                <StepExplanation text={t('partDetails.graphLegend.fromDataExplanation')} boxStyle={fromDataStyle} />
                <StepExplanation
                    text={t('partDetails.graphLegend.fromDataAndLabelExplanation')}
                    boxStyle={fromDataAndLabelStyle}
                />
                <StepExplanation text={t('partDetails.graphLegend.notPassedExplanation')} boxStyle={notUsedStyle} />
                <StepExplanation text={t('partDetails.graphLegend.boughtComponent')} boxStyle={boughtComponentStyle} />
            </GridRow>
        </div>
    );
};

const PartProductionStepsDiagram = ({ part, partChildren }: { part: Part; partChildren: Part[] }) => {
    const { graph, loading: loadingGraph } = useAssemblyLineGraph(part.part_type);
    const onInit = (reactFlowInstance: { fitView: () => void }) => {
        reactFlowInstance.fitView();
    };
    const nodeTypes = {
        customizedNode: CustomizedNode,
    };

    const graphHeight = 500;

    if (graph && !loadingGraph) {
        return (
            <ReactFlowProvider>
                <FlowHeader part={part} />
                <div
                    style={{ border: `1px solid ${greyDividerColor}`, borderRadius: '5px ', backgroundColor: 'white' }}
                >
                    <Legend />
                    <Divider style={{ width: `calc(100% - ${spacing(6)})`, marginLeft: spacing(3) }} />
                    <div className="reactflow-wrapper" style={{ height: `${graphHeight}px` }}>
                        <ReactFlow
                            nodes={createFlowElements(graph, part, partChildren)}
                            edges={createFlowEdges(graph.edges)}
                            nodeTypes={nodeTypes}
                            onInit={onInit}
                            preventScrolling={false}
                            zoomOnScroll={false}
                        />
                    </div>
                </div>
            </ReactFlowProvider>
        );
    } else {
        return <FullPageLoader style={{ height: `${graphHeight}px` }} />;
    }
};

export default PartProductionStepsDiagram;
