import React, { useState } from 'react';
import { GridCol, GridRow } from '../../base/layout/Grid';
import spacing from '../../style/spacing';
import { Container } from '../../base/layout/Container';
import ISorting from '../../../domain/interfaces/ISorting';
import { PartsFiltersForm } from './PartsFiltersForm';
import { IPartsFilter } from '../../../domain/services/PartsService';
import { Part } from '../../../domain/models/Part';
import { PartsTable } from './PartsTable/PartsTable';
import { PartType } from '../../../domain/models/PartType';
import { Button } from '../../base/buttons/Button';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { Caption } from '../../base/typography/textStyles';
import { export_parts } from './index';
import { ArrowDownIcon, ArrowUpIcon } from '../../base/icons/icons';

export interface IProductsBrowserProps {
    allPartTypes: PartType[];
    partsPage: Part[];
    total: number;
    offset: number;
    limit: number;
    filters: IPartsFilter;
    isLoading?: boolean;
    sorting: ISorting | null;
    onPartClicked?: (part: Part) => void;
    onNextPageClicked?: () => void;
    onPrevPageClicked?: () => void;
    onSearchClicked?: (filters: IPartsFilter) => void;
    setSorting: (column: string) => void;
    setPage: (page: number) => void;
}

const PartsBrowser: React.FC<IProductsBrowserProps> = ({
    allPartTypes,
    partsPage,
    total,
    offset = 0,
    limit = 25,
    filters,
    isLoading = false,
    sorting,
    onPrevPageClicked = () => {},
    onNextPageClicked = () => {},
    onPartClicked = () => {},
    onSearchClicked = () => {},
    setSorting = () => {},
    setPage = () => {},
}: IProductsBrowserProps) => {
    const filtersProps = {
        filters,
        isLoading,
        onSearchClicked: onSearchClicked,
    };

    const tableProps = {
        isLoading,
        rows: partsPage,
        onNextPageClicked,
        onPrevPageClicked,
        total,
        limit,
        offset,
        sorting,
        onRowClick: (idx: number) => onPartClicked(partsPage[idx]),
        setSorting,
        setPage,
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [loading, setLoading] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleExportFile = async (export_type: string) => {
        setAnchorEl(null);
        setLoading(true);
        await export_parts(export_type, filters, offset, limit, sorting);
        setLoading(false);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <GridRow>
                <GridCol xs={3} xl={2} noPadding>
                    <PartsFiltersForm allPartTypes={allPartTypes} {...filtersProps} />
                </GridCol>
                <GridCol
                    xs={9}
                    xl={10}
                    style={{ paddingLeft: spacing(4), display: 'flex', flexDirection: 'column', paddingBottom: '0px' }}
                >
                    <GridRow style={{ marginBottom: spacing(1) }}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            disableElevation
                            onClick={handleClick}
                        >
                            {loading ? (
                                <CircularProgress size="1.5rem" color="inherit" />
                            ) : (
                                <GridRow>
                                    Export
                                    {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                </GridRow>
                            )}
                        </Button>
                    </GridRow>
                    <Menu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={(e) => handleExportFile('csv')} disableRipple>
                            <Caption>CSV</Caption>
                        </MenuItem>
                        <MenuItem onClick={(e) => handleExportFile('json')} disableRipple>
                            <Caption>JSON</Caption>
                        </MenuItem>
                        <MenuItem onClick={(e) => handleExportFile('xml')} disableRipple>
                            <Caption>XML</Caption>
                        </MenuItem>
                    </Menu>
                    <Container style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <PartsTable {...tableProps} isLoading={isLoading} />
                    </Container>
                </GridCol>
            </GridRow>
        </>
    );
};
export default PartsBrowser;
