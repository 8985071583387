import React from 'react';
import { ResponsiveChartContainer } from './ResponsiveChartContainer';
import IStyledProps from '../IStyledProps';
import Kapp, { Order, OrderAnalysis } from '../../../domain/models/Kapp';
import { greyDividerColor, primary } from '../../style/colors';
import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next'; // or var ReactECharts = require('echarts-for-react');

interface IEchartsMultipleBar extends IStyledProps {
    data: Kapp[];
    orderAnalysisIndex: number;
    isMeanChecked: boolean;
    isMaxChecked: boolean;
    style: {};
}

export const EChartsMultipleBar: React.FC<IEchartsMultipleBar> = ({
    data,
    orderAnalysisIndex,
    isMeanChecked,
    isMaxChecked,
    style,
}) => {
    const lineData: any[] = [];
    const { t } = useTranslation();

    data[0].order_analysis.forEach((orderAnalysis: OrderAnalysis) => {
        orderAnalysis.orders.forEach((order: Order) => {
            if (lineData.find((x) => x.name === order.nr * orderAnalysis.base_frequency)) {
                let test = lineData.find((x) => x.name === order.nr * orderAnalysis.base_frequency);
                const index = lineData.findIndex((x) => x.name === order.nr * orderAnalysis.base_frequency);
                lineData[index] = {
                    ...test,
                    [data[0].tid + '-' + orderAnalysis.channel + '-' + orderAnalysis.channel_type + '-max']: order.max,
                    [data[0].tid + '-' + orderAnalysis.channel + '-' + orderAnalysis.channel_type + '-mean']:
                        order.mean,
                };
            } else {
                lineData.push({
                    name: order.nr * orderAnalysis.base_frequency,
                    [data[0].tid + '-' + orderAnalysis.channel + '-' + orderAnalysis.channel_type + '-max']: order.max,
                    [data[0].tid + '-' + orderAnalysis.channel + '-' + orderAnalysis.channel_type + '-mean']:
                        order.mean,
                });
            }
        });
    });
    let max = `${data[0].tid}-${data[0].order_analysis[orderAnalysisIndex].channel}-${data[0].order_analysis[orderAnalysisIndex].channel_type}-max`;
    let mean = `${data[0].tid}-${data[0].order_analysis[orderAnalysisIndex].channel}-${data[0].order_analysis[orderAnalysisIndex].channel_type}-mean`;

    let maxLine = {
        data: isMaxChecked ? lineData.map((data) => data[max]) : [],
        type: 'bar',
        smooth: false,
        symbol: 'none',
        lineStyle: {
            color: primary,
        },
        color: '#BDC88A',
        name: t('machineDetails.analysis.max_amplitude_tooltip'),
    };

    let meanLine = {
        data: isMeanChecked ? lineData.map((data) => data[mean]) : [],
        type: 'bar',
        smooth: false,
        lineStyle: {
            opacity: 0,
        },
        areaStyle: {
            color: greyDividerColor,
        },
        stack: 'confidence-band',
        symbol: 'none',
        color: '#80B0C8',
        name: t('machineDetails.analysis.mean_amplitude_tooltip'),
    };

    let tooltipFormatter = (lines: any[]): string => {
        let tooltip = '';
        lines.length > 0
            ? (tooltip = t('machineDetails.analysis.frequency_first_order') + ` : ${lines[0].name} Hz <br>`)
            : (tooltip = '');
        tooltip =
            tooltip +
            lines
                .map((line) => {
                    let seriesName = line.seriesName + ': ';
                    return seriesName + '<b>' + line.data + ' g </b>';
                })
                .join('<br>');

        return tooltip;
    };

    const options = {
        grid: { top: 8, right: 55, bottom: 90, left: 62 },
        xAxis: [
            {
                type: 'category',
                data: lineData.map((data) => data.name),
                splitLine: {
                    show: true,
                },
                name: 'Frequency (Hz)',
                nameLocation: 'middle',
                nameGap: 30,
                axisLabel: {
                    formatter: (value: string, index: number) => {
                        return parseInt(value) - (parseInt(value) % 50);
                    },
                },
            },
        ],
        yAxis: [
            {
                showGrid: false,
                splitLine: {
                    show: true,
                },
                type: 'value',
                name: 'Amplitude (mg)',
                nameLocation: 'middle',
                nameGap: 50,
            },
        ],
        series: [meanLine, maxLine],
        tooltip: {
            trigger: 'axis',
            formatter: (params: any) => {
                return tooltipFormatter(params);
            },
        },
        dataZoom: [
            {
                type: 'inside',
            },
            {
                type: 'inside',
                disabled: true,
            },
        ],
    };
    return (
        <ResponsiveChartContainer style={style}>
            <ReactECharts option={options} />
        </ResponsiveChartContainer>
    );
};
