import { IEolVerificationResponse, IError } from '../services/EolVerificationsService';

export default class EOLVerification {
    constructor(
        public id: string,
        public numberOfTests: number,
        public pid: string,
        public productType: string,
        public station: string,
        public date: Date,
        public errors: Error[],
        public status: number
    ) {}

    static readonly STATUS_PASSED = 1;
    static readonly STATUS_NOT_PASSED = 0;
    static readonly STATUS_UNKNOWN = -1;

    static fromJson(json: IEolVerificationResponse) {
        return new EOLVerification(
            json.id,
            json.number_of_tests,
            json.pid,
            json.product_type,
            json.station_name,
            new Date(json.status_timestamp + 'Z'), // + "Z" to indicate that the time is in UTC format
            json.errors.map((error) => Error.fromJson(error)),
            json.status
        );
    }
}

export class Error {
    constructor(
        public error_column_number: number,
        public error_sequence: number,
        public mode_name: string,
        public mode_id: number,
        public channel_name: string,
        public location_name: string,
        public location_id: number,
        public instrument_name: string,

        public instrument_id: number,
        public parameter_name: string,
        public parameter_id: number,
        public error_value: number,
        public error_limit: number,

        public error_position: number,
        public unit_name: string,
        public unit_id: number,
        public source: string,
        public etl_timestamp: Date,
        public error_type_id: number,

        public error_type_number_specific: number,
        public error_type_text: string,
        public error_type_source: string,
        public error_type_timestamp: Date
    ) {}

    static fromJson(json: IError) {
        return new Error(
            json.error_column_number,
            json.error_sequence,
            json.mode_name,
            json.mode_id,
            json.channel_name,
            json.location_name,
            json.location_id,
            json.instrument_name,
            json.instrument_id,
            json.parameter_name,
            json.parameter_id,
            json.error_value,
            json.error_limit,
            json.error_position,
            json.unit_name,
            json.unit_id,
            json.source,
            new Date(json.etl_timestamp),
            json.error_type_id,
            json.error_type_number_specific,
            json.error_type_text,
            json.error_type_source,
            new Date(json.error_type_timestamp)
        );
    }
}
