import React, { useState } from 'react';
import { ConfidentialityLevel } from '../../../domain/config/confidentiality';
import spacing from '../../style/spacing';
import { SettingsIcon } from '../icons/icons';
import { GroupuiHeader } from '@group-ui/group-ui-react';
import SettingsMenu from './SettingsMenu';
import { TopBarButton } from './TopBarButton';
import { ConfidentialIcon, InternalIcon, PublicIcon, SecretIcon } from './ConfidentialityLevelIcons';
import { ProductLogo } from './ProductLogo';

export interface ITopBarProps {
    title: string;
    loading?: boolean;
    confidentialityLevel?: ConfidentialityLevel;
}

const TopBar: React.FC<ITopBarProps> = ({
    title,
    loading = false,
    confidentialityLevel = ConfidentialityLevel.Internal,
    ...props
}) => {
    const [optionsMenuIsOpen, setOptionsMenuIsOpen] = useState(false);

    const getConfidentialityLevelIcon = (confidentialityLevel: ConfidentialityLevel): JSX.Element => {
        const style = { height: '55px', width: '100px', marginTop: spacing(0), marginLeft: spacing(2) };
        if (confidentialityLevel === ConfidentialityLevel.Confidential) return <ConfidentialIcon style={style} />;
        if (confidentialityLevel === ConfidentialityLevel.Public) return <PublicIcon style={style} />;
        if (confidentialityLevel === ConfidentialityLevel.Secret) return <SecretIcon style={style} />;
        return <InternalIcon />;
    };

    const settings = {
        id: 'menu',
        onClick: () => setOptionsMenuIsOpen(!optionsMenuIsOpen),
        iconComponent: () => <SettingsIcon />,
    };

    const actions = [settings];

    return (
        <>
            <GroupuiHeader>
                <ProductLogo />
                <div style={{ flexGrow: 1 }} />
                <div style={{ marginRight: spacing(2) }}>{getConfidentialityLevelIcon(confidentialityLevel)}</div>
                {actions.map((action, i) => (
                    <TopBarButton {...action} key={`top-bar-button-${i}`} />
                ))}
            </GroupuiHeader>
            <SettingsMenu optionsMenuIsOpen={optionsMenuIsOpen} setOptionsMenuIsOpen={setOptionsMenuIsOpen} />
        </>
    );
};
export default TopBar;
