import { EOLVerificationsStats } from '../../../../domain/models/stats/EOLVerificationsStats';
import { GridRow } from '../../../base/layout/Grid';
import Translation from '../../../base/translation/Translation';
import { Divider } from '../../../base/layout/Divider';
import spacing from '../../../style/spacing';
import React from 'react';
import { H4, H5 } from '../../../base/typography/textStyles';
import Tag from '../../../base/tag';
import { negative, positive } from '../../../style/colors';

export const EOLVerificationsCount = ({ stats }: { stats: EOLVerificationsStats }) => (
    <>
        <GridRow justifyContent="space-between">
            <H4 weight="bold">
                <Translation i18nKey="home.EOLVerificationEolErrors.title" />
            </H4>
        </GridRow>

        <GridRow alignItems="center" style={{ marginBottom: spacing(2) }}>
            <H5 noGutter={true}>
                <Translation i18nKey={'home.EOLVerificationEolErrors.total'} />
            </H5>
            <Tag
                dataTestid={'Home.EOLVerificationEolErrors.total'}
                severity="warning"
                style={{ marginLeft: spacing(1), background: 'black' }}
            >
                {stats.total}
            </Tag>

            <H5 noGutter={true} style={{ marginLeft: spacing(2) }}>
                <Translation i18nKey={'home.EOLVerificationEolErrors.passed'} />
            </H5>
            <Tag
                dataTestid={'Home.EOLVerificationEolErrors.passed'}
                severity="success"
                style={{ marginLeft: spacing(1), background: positive }}
            >
                {stats.passed}
            </Tag>

            <H5 noGutter={true} style={{ marginLeft: spacing(2) }}>
                <Translation i18nKey={'home.EOLVerificationEolErrors.notPassed'} />
            </H5>
            <Tag
                dataTestid={'Home.EOLVerificationEolErrors.notPassed'}
                severity={'warning'}
                style={{ marginLeft: spacing(1), background: negative }}
            >
                {stats.notPassed}
            </Tag>
        </GridRow>

        <Divider style={{ marginBottom: spacing(2) }} />
    </>
);
