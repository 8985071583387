import { GroupuiItem } from '@group-ui/group-ui-react';
import React from 'react';
import IOthersProps from '../IOthersProps';
import IStyledProps from '../IStyledProps';

export enum Border {
    both = 'both',
    bottom = 'bottom',
    top = 'top',
    none = 'none',
}

export enum Size {
    s = 's',
    m = 'm',
    l = 'l',
}

interface IGroupuiItemProxyProps {
    border?: Border;
    size?: Size;
}

interface IListItemProps extends IGroupuiItemProxyProps, IOthersProps, IStyledProps {
    noPadding?: boolean;
}

export const ListItem: React.FC<IListItemProps> = ({ style, noPadding, ...props }: IListItemProps) => (
    <GroupuiItem
        style={{
            cursor: props.onClick !== undefined ? undefined : 'default',
            paddingLeft: noPadding ? '0px' : undefined,
            ...style,
        }}
        {...props}
    />
);
