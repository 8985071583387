import React from 'react';
import { TimeFrame, toTimeFrames } from '../../../domain/utils/timeFrames';
import { Container } from '../../base/layout/Container';
import DateIntervalFiltersGroup from '../../base/filtering/filtersGroups/DateIntervalFiltersGroup';
import ProductTypesFiltersGroup from '../../base/filtering/filtersGroups/ProductTypesFiltersGroup';
import { Stats } from '../../../domain/models/stats/Stats';
import { useTranslation } from 'react-i18next';
import {
    EOLVerificationsProductDistributionStats,
    EOLVerificationsTestBenchDistributionStats,
} from '../../../domain/models/stats/EOLVerificationsStats';
import TestBenchFiltersGroup from '../../base/filtering/filtersGroups/TestBenchFiltersGroup';
import moment from 'moment';
import { loadTimezoneFromAppSettings } from '../../base/utils';

export const HomeFilters = ({
    timeFrame,
    onTimeFrameChange,
    productDistributionStats,
    selectedProductTypes,
    onSelectedProductTypesChanged,
    testBenchDistributionStats,
    selectedTestBenches,
    onSelectedTestBenchesChanged,
    stats,
}: {
    timeFrame: TimeFrame;
    onTimeFrameChange: (timeFrame: TimeFrame) => void;
    productDistributionStats: EOLVerificationsProductDistributionStats | null;
    selectedProductTypes: string[];
    onSelectedProductTypesChanged: (selectedProductTypes: string[]) => void;
    testBenchDistributionStats: EOLVerificationsTestBenchDistributionStats | null;
    selectedTestBenches: string[];
    onSelectedTestBenchesChanged: (selectedTestBenches: string[]) => void;
    stats?: Stats | null;
}) => {
    const { t } = useTranslation();
    const now = moment.tz(new Date(), loadTimezoneFromAppSettings().value);
    const timeFrames: any = toTimeFrames(now);

    return (
        <>
            <Container>
                <DateIntervalFiltersGroup
                    label={t('productsFilter.subTitle1')}
                    onTimeFrameChange={onTimeFrameChange}
                    timeFrame={timeFrame}
                    timeFrames={timeFrames}
                    hasCustomTime={true}
                />
                <ProductTypesFiltersGroup
                    productDistributionStats={productDistributionStats}
                    selectedProductTypes={selectedProductTypes}
                    onSelectedProductTypesChanged={onSelectedProductTypesChanged}
                />
                <TestBenchFiltersGroup
                    testBenchDistributionStats={testBenchDistributionStats}
                    selectedTestBenches={selectedTestBenches}
                    onSelectedTestBenchesChanged={onSelectedTestBenchesChanged}
                />
            </Container>
        </>
    );
};
