import React from 'react';
import { TableCell, TableRow } from '../../../base/table/Table';
import Translation from '../../../base/translation/Translation';
import { toDateStringGerman, toTimeString } from '../../../base/utils';
import ISorting from '../../../../domain/interfaces/ISorting';
import { primary } from '../../../style/colors';
import { MachineProductionStep } from '../../../../domain/services/ProductionPlantsService';
import { EOLStatus } from '../../eolVerifications/EolVerificationsTable/EolVerificationsTableCells';
import { GroupuiRadioButton } from '@group-ui/group-ui-react';

export const ProductionStepsRow = ({
    productionStep,
    onClick,
    dataTestid,
    checked,
    onTidClicked,
}: {
    productionStep: MachineProductionStep;
    onClick: () => void;
    dataTestid?: string;
    checked: boolean;
    onTidClicked: (arg: string) => void;
}) => {
    const handleClick = () => {
        let text: string | undefined;
        text = window.getSelection()?.toString();
        if (text !== undefined && text.length > 0) {
            return;
        }
        onClick();
    };

    return (
        <TableRow dataTestid={dataTestid} onClick={handleClick} style={{ cursor: 'pointer' }}>
            {/* <TableCell> */}
            {/* <Checkbox key={Math.random()} checked={checked} onValueChange={onTidClicked}> */}
            {/* Math.Random(ist important to update the checkbox, wihtout it does not work 
                    https://stackoverflow.com/questions/43476729/react-checkbox-does-not-update
                ) */}
            {/* </Checkbox> */}
            {/* </TableCell> */}
            <TableCell>
                <GroupuiRadioButton
                    checked={checked}
                    value={productionStep.partTid + '_' + productionStep.productionDate.toISOString()}
                    onClick={(event: any) => onTidClicked(event.target.value)}
                ></GroupuiRadioButton>
            </TableCell>
            <TableCell>{productionStep.partTid}</TableCell>
            <TableCell>{productionStep.spindel}</TableCell>
            <TableCell>{productionStep.partType}</TableCell>
            <TableCell>{toDateStringGerman(productionStep.productionDate)}</TableCell>
            <TableCell>{toTimeString(productionStep.productionDate)}</TableCell>
            <TableCell alignment="center">
                <EOLStatus status={productionStep.hasKappData ? 1 : 0} tooltipPrefix="machineDetails" />
            </TableCell>
        </TableRow>
    );
};
export const ProductionStepsHeader = ({
    sorting,
    setSorting,
}: {
    sorting: ISorting | null;
    setSorting: (column: string) => void;
}) => {
    const getColumnOrder = (column: String) => {
        if (sorting === null || column !== sorting.column) {
            return 'unsorted';
        }
        return sorting.direction === 'asc' ? 'up' : 'down';
    };
    return (
        <>
            <TableRow header style={{ color: primary }}>
                <TableCell></TableCell>
                <TableCell sortable={true} order={getColumnOrder('tid')} onClick={() => setSorting('tid')}>
                    <Translation i18nKey="machineDetails.partsTable_tableHeader_tidColumn" />
                </TableCell>
                <TableCell
                    sortable={true}
                    order={getColumnOrder('machine_section')}
                    onClick={() => setSorting('machine_section')}
                >
                    <Translation i18nKey="machineDetails.partsTable_tableHeader_spindel" />
                </TableCell>
                <TableCell sortable={false} order={getColumnOrder('part_type')} onClick={() => setSorting('part_type')}>
                    <Translation i18nKey="machineDetails.partsTable_tableHeader_partType" />
                </TableCell>
                <TableCell
                    sortable={true}
                    order={getColumnOrder('tid_timestamp')}
                    onClick={() => setSorting('tid_timestamp')}
                >
                    <Translation i18nKey="machineDetails.partsTable_tableHeader_productionDate" />
                </TableCell>
                <TableCell
                    sortable={true}
                    order={getColumnOrder('tid_timestamp')}
                    onClick={() => setSorting('tid_timestamp')}
                >
                    <Translation i18nKey="machineDetails.partsTable_tableHeader_productionTime" />
                </TableCell>
                <TableCell alignment="center">
                    <Translation i18nKey="machineDetails.partsTable_tableHeader_analyzable" />
                </TableCell>
            </TableRow>
        </>
    );
};
