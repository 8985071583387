import React, { useEffect, useState } from 'react';

import Page from '../../base/layout/Page';
import { MachineDetails } from './MachineDetails';
import { useMachine } from '../../../domain/hooks/useMachine';
import { defaultPlantId } from '../../../domain/config/plantInfo';
import FullPageLoader from '../../base/loaders/FullPageLoader';

import { useTranslation } from 'react-i18next';
import { ISorting, useMachineProductionSteps } from '../../../domain/hooks/useMachineProductionSteps';
import { IMachineDetailsFilters, MachineProductionStep } from '../../../domain/services/ProductionPlantsService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getQueryString } from '../../utils/URLService';
import { useKapp } from '../../../domain/hooks/useKapp';
import { usePartTypes } from '../../../domain/hooks/usePartTypes';
import { loadLastTimeFrame } from '../../../domain/utils/getDates';

interface IQueryMachineDetailsFilter {
    date_from?: string;
    date_to?: string;
    sort?: string;
    limit?: string;
    offset?: string;
    tid?: string[];
    parttype?: string[];
    pid?: string[];
    sorting?: ISorting;
    machine_section?: string[];
}

export const dataObjectToQueryObject = (
    filters: IMachineDetailsFilters,
    sorting: ISorting | null,
    limit?: number,
    offset?: number
): IQueryMachineDetailsFilter => {
    return {
        date_from: filters.fromDate ? moment(filters.fromDate).format() : '',
        date_to: filters.toDate ? moment(filters.toDate).format() : '',
        sort: sorting ? `${sorting.column}:${sorting.direction}` : '',
        limit: limit?.toString(),
        offset: offset?.toString(),
        tid: filters.tids.map(String),
        machine_section: filters.machineSections.map(String),
    };
};

interface IMachineDetailsPageProps {
    machineNumber: string;
    searchParams: IQueryMachineDetailsFilter;
}

const MachineDetailsPage: React.FC<IMachineDetailsPageProps> = ({ machineNumber, searchParams }) => {
    const { t } = useTranslation();
    const { loading: isLoadingMachine, machine } = useMachine(defaultPlantId, machineNumber);
    let navigate = useNavigate();

    const queryObjectDataObject = (searchParams: IQueryMachineDetailsFilter): IMachineDetailsFilters => {
        return {
            fromDate: searchParams.date_from ? new Date(searchParams.date_from) : loadLastTimeFrame().fromDate,
            toDate: searchParams.date_to ? new Date(searchParams.date_to) : loadLastTimeFrame().toDate,
            tids: searchParams.tid ? searchParams.tid.map(String) : [],
            selectedTids: [],
            machineSections: searchParams.machine_section ? searchParams.machine_section.map(String) : [],
        };
    };

    const queryToSortingObject = (searchParams: IQueryMachineDetailsFilter): ISorting | null => {
        const currentSorting: ISorting | null = searchParams.sort
            ? {
                  column: searchParams.sort[0].substring(0, searchParams.sort[0].indexOf(':')),
                  direction: searchParams.sort[0].substring(searchParams.sort[0].indexOf(':') + 1),
              }
            : null;
        return currentSorting;
    };

    const {
        machineProductionSteps,
        filters,
        offset,
        limit,
        total,
        loading: isLoadingProductionSteps,
        sorting,
        getNextPage,
        getPreviousPage,
        setFilters,
        setSorting,
        setPage,
    } = useMachineProductionSteps(
        queryObjectDataObject(searchParams),
        queryToSortingObject(searchParams),
        defaultPlantId,
        machineNumber,
        searchParams.offset ? parseInt(searchParams.offset) : undefined,
        searchParams.limit ? parseInt(searchParams.limit) : undefined
    );
    const [machineProductionStepsState, setMachineProductionStepsState] = useState(machineProductionSteps);
    const {
        kappPage,
        setFilters: setKappFilters,
        filters: kappFilters,
        loading: isKappLoading,
    } = useKapp(filters, machineNumber);
    const { partTypes } = usePartTypes();

    useEffect(() => {
        if (machineProductionSteps && partTypes) {
            let tempSteps = [...machineProductionSteps?.productionSteps];
            tempSteps.forEach((item) => {
                const partTypeName = partTypes.find((partType) =>
                    item.partTid.includes(partType.partNumber)
                )?.partTypeName;
                item.partType = partTypeName ? partTypeName : '';
            });
            setMachineProductionStepsState({ ...machineProductionSteps, productionSteps: tempSteps });
        } else if (machineProductionSteps) {
            setMachineProductionStepsState(machineProductionSteps);
        }
    }, [partTypes, machineProductionSteps]);

    const onSortClicked = (column: string) => {
        let newSorting = null;
        if (sorting === null || sorting.column !== column) {
            newSorting = { column: column, direction: 'asc' };
        } else {
            newSorting = { column: column, direction: sorting.direction === 'asc' ? 'desc' : 'asc' };
        }

        setSorting(newSorting);
        navigate(`/machines/${machineNumber}?${getQueryString(dataObjectToQueryObject(filters, newSorting))}`);
    };

    const onKappFilterChange = (selectedTids: string[]) => {
        setKappFilters({ ...filters, selectedTids: selectedTids });
    };

    const onSearchClicked = (filters: IMachineDetailsFilters) => {
        setFilters(filters);
        // setKappFilters(filters);

        navigate(`/machines/${machineNumber}?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, 0))}`);
        // The offset value has to be 0, otherwise it can happen that when a user is on page 4 and add a new filter, where there is no longer a page 4,,
        // it does not show any results, because the offset did not reset itself
    };
    const onNextPageClicked = () => {
        navigate(
            `/machines/${machineNumber}?${getQueryString(
                dataObjectToQueryObject(filters, sorting, limit, offset + limit)
            )}`
        );
        getNextPage();
    };

    const onPrevPageClicked = () => {
        navigate(
            `/machines/${machineNumber}?${getQueryString(
                dataObjectToQueryObject(filters, sorting, limit, offset - limit)
            )}`
        );
        getPreviousPage();
    };
    const onSetPageClicked = (page: number) => {
        navigate(
            `/machines/${machineNumber}?${getQueryString(
                dataObjectToQueryObject(filters, sorting, limit, (page - 1) * limit)
            )}`
        );
        setPage(page);
    };

    const onProductionStepClicked = (productionStep: MachineProductionStep) => {};

    return (
        <Page title={t('machineDetails.title')} loading={isLoadingMachine || isLoadingProductionSteps}>
            {isLoadingMachine && <FullPageLoader />}
            {machine && !isLoadingMachine && (
                <MachineDetails
                    machine={machine}
                    isLoadingProductionSteps={isLoadingProductionSteps}
                    productionStepsPage={machineProductionStepsState}
                    filters={filters}
                    total={total}
                    offset={offset}
                    limit={limit}
                    sorting={sorting}
                    onSearchClicked={onSearchClicked}
                    onProductionStepClicked={onProductionStepClicked}
                    onPrevPageClicked={onPrevPageClicked}
                    onNextPageClicked={onNextPageClicked}
                    setSorting={onSortClicked}
                    setPage={onSetPageClicked}
                    kappPage={kappPage}
                    onKappFilterChange={onKappFilterChange}
                    kappFilters={kappFilters}
                    isKappLoading={isKappLoading}
                />
            )}
        </Page>
    );
};

export default MachineDetailsPage;
