import moment from 'moment';
import React, { useState } from 'react';
import { IEngineFilters } from '../../../domain/services/EngineService';
import { toTimeFramesWithoutShifts } from '../../../domain/utils/timeFrames';
import DateIntervalFiltersGroup from '../../base/filtering/filtersGroups/DateIntervalFiltersGroup';
import { DirectPassFiltersGroup } from '../../base/filtering/filtersGroups/DirectPassFilter';
import { NumberOfEOLVerificationsFilterGroup } from '../../base/filtering/filtersGroups/NumberOfEOLVerificationsFilterGroup';
import { Container } from '../../base/layout/Container';
import { useTranslation } from 'react-i18next';
import { IdentificationNumbersFiltersGroup } from '../../base/filtering/filtersGroups/IdentificationNumbersFiltersGroup';
import FiltersArea from '../../base/filtering/filtersArea/FiltersArea';
import { loadTimezoneFromAppSettings } from '../../base/utils';
import { loadLastTimeFrame, defaultDates } from '../../../domain/utils/getDates';
import { storeLastTimeFrame } from '../../../domain/utils/getDates';

export interface IEngineFiltersForm {
    isLoading: boolean;
    filterprops: IEngineFilters;
    onSearchClicked: (filters: IEngineFilters) => void;
}

export const EngineFiltersForm = ({ filterprops, onSearchClicked, isLoading = false }: IEngineFiltersForm) => {
    const timeFrames = toTimeFramesWithoutShifts(moment.tz(new Date(), loadTimezoneFromAppSettings().value));
    const [innerFilters, setInnerFilters] = useState({
        selectedTimeFrame: loadLastTimeFrame(defaultDates.timeFrame, true),
        ...filterprops,
    });
    const { t } = useTranslation();

    const onFieldValueChange = (fieldName: string) => {
        return (value: any) => {
            const newInnerFilters: any = { ...innerFilters };
            newInnerFilters[fieldName] = value;
            setInnerFilters(newInnerFilters);
            if (fieldName === 'selectedTimeFrame') {
                storeLastTimeFrame(value);
            }
        };
    };

    const getNewFilters = (innerFilters: any) => {
        const newFilters: IEngineFilters = {
            ...innerFilters,
            fromDate: innerFilters.selectedTimeFrame.fromDate,
            toDate: innerFilters.selectedTimeFrame.toDate,
            fromDateUTC: innerFilters.selectedTimeFrame.fromDateUTC,
            toDateUTC: innerFilters.selectedTimeFrame.toDateUTC,
            directPassStatus: innerFilters.directPassStatus,
        };
        return newFilters;
    };

    const onSubmit = () => {
        onSearchClicked(getNewFilters(innerFilters));
    };

    const filtersChanged = JSON.stringify(getNewFilters(innerFilters)) !== JSON.stringify(filterprops);
    const isTimeFrameValid = () => {
        const fromDate = getNewFilters(innerFilters).fromDate;
        const toDate = getNewFilters(innerFilters).toDate;
        return (fromDate !== null && toDate !== null && fromDate <= toDate) || false;
    };

    return (
        <FiltersArea
            title="enginesFilter.title"
            onSubmit={onSubmit}
            searchDisabled={isLoading || !filtersChanged || !isTimeFrameValid()}
        >
            <Container>
                <DateIntervalFiltersGroup
                    label={t('productsFilter.date.title')}
                    key={'datetime'}
                    onTimeFrameChange={onFieldValueChange('selectedTimeFrame')}
                    timeFrame={innerFilters.selectedTimeFrame}
                    timeFrames={timeFrames}
                    hasCustomTime={false}
                />
                <IdentificationNumbersFiltersGroup
                    productIds={innerFilters.pids}
                    onPidsChange={onFieldValueChange('pids')}
                />
                <DirectPassFiltersGroup
                    directPassStatus={innerFilters.directPassStatus}
                    onCheckBoxChanged={onFieldValueChange('directPassStatus')}
                />
                <NumberOfEOLVerificationsFilterGroup
                    numberOfEOLVerificationsSpread={innerFilters.numberOfEOLVerificationsSpread}
                    onNumberOfEOLVerificationsChange={onFieldValueChange('numberOfEOLVerificationsSpread')}
                />
            </Container>
        </FiltersArea>
    );
};
