import { Part } from '../../../domain/models/Part';
import { Body1, Caption, H4 } from '../../base/typography/textStyles';
import Translation from '../../base/translation/Translation';
import { List } from '../../base/lists/List';
import React from 'react';
import { Border, ListItem, Size } from '../../base/lists/ListItem';
import { toDateString, toTimeString } from '../../base/utils';
import Tag from '../../base/tag';
import spacing from '../../style/spacing';
import { LinkIcon } from '../../base/icons/icons';
import { Button } from '../../base/buttons/Button';
import { FlexContainer } from '../../base/layout/Flex';
import { Link } from 'react-router-dom';

const ProductListItem = ({
    assemblyDate,
    pid,
    isCurrent = false,
    redirectUrl,
}: {
    assemblyDate: Date;
    pid: string;
    isCurrent?: boolean;
    redirectUrl: string;
}) => {
    return (
        <>
            <ListItem border={Border.none} size={Size.s}>
                <FlexContainer justifyContent="space-between" alignItems="center">
                    <div>
                        <Body1 noGutter>
                            <Translation i18nKey="partDetails.pid" values={{ pid: pid }} />
                        </Body1>
                        <Caption>
                            <Translation
                                i18nKey="productDetails.assemblyDate"
                                values={{ date: toDateString(assemblyDate), time: toTimeString(assemblyDate) }}
                            />
                        </Caption>

                        <div style={{ marginTop: spacing(1) }}>
                            {isCurrent ? (
                                <Tag variant="primary" square={false}>
                                    <Translation i18nKey="partDetails.current" />
                                </Tag>
                            ) : (
                                <Tag variant="secondary" square={false}>
                                    <Translation i18nKey="partDetails.replaced" />
                                </Tag>
                            )}
                        </div>
                    </div>
                    <div>
                        <Link to={redirectUrl}>
                            <Button iconOnly={true}>
                                <LinkIcon />
                            </Button>
                        </Link>
                    </div>
                </FlexContainer>
            </ListItem>
        </>
    );
};
export const ProductHistory = ({ part }: { part: Part }) => {
    const getPartUrl = (pid: string) => {
        return `/products/${pid}`;
    };

    return (
        <>
            <H4 noGutter={true}>
                <Translation i18nKey="partDetails.productHistory" />
            </H4>
            <List>
                {part.current_pid === null ? (
                    <>
                        <Translation i18nKey="partDetails.noPid" />
                    </>
                ) : null}
                {part.productHistory
                    .sort((a, b) => (a.assembledAt < b.assembledAt ? 1 : b.assembledAt < a.assembledAt ? -1 : 0))
                    .map((productHistory) => {
                        return (
                            <ProductListItem
                                assemblyDate={productHistory.assembledAt}
                                pid={productHistory.pid}
                                key={productHistory.pid}
                                redirectUrl={getPartUrl(productHistory.pid)}
                                isCurrent={productHistory.pid === part.current_pid}
                            />
                        );
                    })}
            </List>
        </>
    );
};
