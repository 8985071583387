import { useEffect, useState } from 'react';
import { Part } from '../models/Part';
import PartsService from '../services/PartsService';

export interface UsePartChildrenState {
    partChildren: Part[] | null;
    loading: boolean;
    error: Error | null;
}

/**
 * Custom hook
 * Downloads a list of part's children for a given partId
 * @param partId
 */
export const usePartChildren: (partId: string) => UsePartChildrenState = (partId: string) => {
    const [partChildren, setPartChildren] = useState<Part[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        const service = new PartsService();
        service
            .getPartChildren(partId)
            .then((response) => {
                setPartChildren(response);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    }, [partId]);

    return {
        partChildren,
        loading,
        error,
    };
};
