import moment from 'moment';
import 'moment-timezone';

// default timezone is Europe/Berlin
export const defaultTimezone = {
    abbrev: 'CEST',
    altName: 'Central European Summer Time',
    label: '(UTC+2:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    offset: '2',
    value: 'Europe/Berlin',
};

export const toDateString = (date: moment.Moment | Date | null) => {
    if (date === null) return '';
    try {
        if (date instanceof Date) return moment.tz(date, loadTimezoneFromAppSettings().value).format('YYYY-MM-DD');
        else return date.format('YYYY-MM-DD');
    } catch (e) {
        return '';
    }
};

export const toDateStringGerman = (date: moment.Moment | Date | null) => {
    if (date === null) return '';
    try {
        if (date instanceof Date) return moment.tz(date, loadTimezoneFromAppSettings().value).format('DD.MM.YYYY');
        else return date.format('DD.MM.YYYY');
    } catch (e) {
        return '';
    }
};

export const toTimeString = (date: moment.Moment | Date | null) => {
    if (date === null) return '';
    try {
        if (date instanceof Date) return moment.tz(date, loadTimezoneFromAppSettings().value).format('HH:mm');
        else return date.format('HH:mm');
    } catch (e) {
        return '';
    }
};
export const loadTimezoneFromAppSettings = () => {
    try {
        // read the timezone from app settings
        const timezone = localStorage.getItem('settings-timezone');
        // if nothing is set -> default
        if (timezone == null) {
            return defaultTimezone;
        }
        return JSON.parse(timezone);
    } catch (e) {
        // if parsing failes -> default
        return defaultTimezone;
    }
};

const toSettingsTimezone = (date: Date | null, desiredFormat: string | null) => {
    if (date === null) return '';
    try {
        let utc_date = moment.utc(date.toISOString());
        let newDate = utc_date.tz(loadTimezoneFromAppSettings().value);
        if (desiredFormat !== null) return newDate.format(desiredFormat);
        else return newDate.format();
    } catch (e) {
        return '';
    }
};
export const toSettingsTimezoneTime = (date: Date | null) => {
    return toSettingsTimezone(date, 'HH:mm:ss');
};

export const toSettingsTimezoneDate = (date: Date | null) => {
    return toSettingsTimezone(date, 'DD.MM.YYYY');
};

export const toDateTimeString = (date: moment.Moment | Date | null) => {
    if (date === null) return '';
    return `${toDateString(date)} ${toTimeString(date)}`;
};

export const toDateTimeStringGerman = (date: moment.Moment | Date | null) => {
    if (date === null) return '';
    return `${toDateStringGerman(date)} ${toTimeString(date)}`;
};

export const formatDate = (dateString: string, timeString: string) => {
    const dateTimeStamp = Date.parse(dateString);
    const splittedTimeString = timeString !== null ? timeString.split(':') : [];

    if (splittedTimeString.length > 0) {
        const [hoursString, minutesString] = splittedTimeString;
        const [hours, minutes] = [parseInt(hoursString), parseInt(minutesString)];
        if (!isNaN(dateTimeStamp) && !isNaN(hours) && !isNaN(minutes)) {
            const dateDate = new Date(dateString);
            dateDate.setHours(hours);
            dateDate.setMinutes(minutes);
            dateDate.setSeconds(0);
            dateDate.setMilliseconds(0);
            return dateDate;
        }
    }

    return null;
};

export const pad = (num: number | string, length: number) => {
    num = num.toString();
    while (num.length < length) num = '0' + num;
    return num;
};

export const emptyCallback = (value: any) => {};
