import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISorting, useEngines } from '../../../domain/hooks/useEngines';
import { Engine } from '../../../domain/models/Engine';
import { IEngineFilters } from '../../../domain/services/EngineService';
import Page from '../../base/layout/Page';
import { getQueryString } from '../../utils/URLService';
import EnginesBrowser from './EnginesBrowser';
import { defaultDates, loadLastTimeFrame } from '../../../domain/utils/getDates';

interface IQueryEngineFilters {
    date_from?: string;
    date_to?: string;
    dp_status?: string[];
    number_eol_v_min?: string;
    number_eol_v_max?: string;
    pid?: string[];
    sort?: string;
    limit?: string;
    offset?: string;
}
export const dataObjectToQueryObject = (
    filters: IEngineFilters,
    sorting: ISorting | null,
    limit?: number,
    offset?: number
): IQueryEngineFilters => {
    return {
        date_from: filters.fromDate ? moment(filters.fromDate).format() : '',
        date_to: filters.toDate ? moment(filters.toDate).format() : '',
        dp_status: filters.directPassStatus.map(String),
        number_eol_v_min: filters.numberOfEOLVerificationsSpread.min?.toString(),
        number_eol_v_max: filters.numberOfEOLVerificationsSpread.max?.toString(),
        pid: filters.pids.map(String),
        sort: sorting ? `${sorting.column}:${sorting.direction}` : '',
        limit: limit?.toString(),
        offset: offset?.toString(),
    };
};

const EnginesPage = ({ searchParams }: { searchParams: IQueryEngineFilters }) => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const queryObjectDataObject = (searchParams: IQueryEngineFilters): IEngineFilters => {
        return {
            fromDate: searchParams.date_from
                ? new Date(searchParams.date_from)
                : loadLastTimeFrame(defaultDates.timeFrame, true).fromDate,
            toDate: searchParams.date_to
                ? new Date(searchParams.date_to)
                : loadLastTimeFrame(defaultDates.timeFrame, true).toDate,
            fromDateUTC: searchParams.date_from
                ? new Date(searchParams.date_from)
                : loadLastTimeFrame(defaultDates.timeFrame, true).fromDate,
            toDateUTC: searchParams.date_to
                ? new Date(searchParams.date_to)
                : loadLastTimeFrame(defaultDates.timeFrame, true).toDate,
            directPassStatus: searchParams.dp_status ? searchParams.dp_status.map(Number) : [],
            numberOfEOLVerificationsSpread: {
                min: searchParams.number_eol_v_min ? parseInt(searchParams.number_eol_v_min) : null,
                max: searchParams.number_eol_v_max ? parseInt(searchParams.number_eol_v_max) : null,
            },
            pids: searchParams.pid ? searchParams.pid.map(String) : [],
        };
    };
    const onEngineClicked = (engine: Engine) => {
        navigate(`/products/${engine.pid}`);
    };

    const queryToSortingObject = (searchParams: IQueryEngineFilters): ISorting | null => {
        const currentSorting: ISorting | null = searchParams.sort
            ? {
                  column: searchParams.sort[0].substring(0, searchParams.sort[0].indexOf(':')),
                  direction: searchParams.sort[0].substring(searchParams.sort[0].indexOf(':') + 1),
              }
            : null;
        return currentSorting;
    };

    const {
        engines,
        filters,
        offset,
        limit,
        total,
        loading,
        sorting,
        getNextPage,
        getPreviousPage,
        setFilters,
        setSorting,
        setPage,
    } = useEngines({
        initialFilters: queryObjectDataObject(searchParams),
        currentSorting: queryToSortingObject(searchParams),
        initialOffset: searchParams.offset ? parseInt(searchParams.offset) : undefined,
        limit: searchParams.limit ? parseInt(searchParams.limit) : undefined,
    });

    const onSortClicked = (column: string) => {
        let newSorting = null;
        if (sorting === null || sorting.column !== column) {
            newSorting = { column: column, direction: 'asc' };
        } else {
            newSorting = { column: column, direction: sorting.direction === 'asc' ? 'desc' : 'asc' };
        }

        setSorting(newSorting);
        navigate(`/engines?${getQueryString(dataObjectToQueryObject(filters, newSorting))}`);
    };

    const onSearchClicked = (filters: IEngineFilters) => {
        setFilters(filters);
        navigate(`/engines?${getQueryString(dataObjectToQueryObject(filters, sorting))}`);
    };
    const onNextPageClicked = () => {
        navigate(`/engines?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, offset + limit))}`);
        getNextPage();
    };

    const onPrevPageClicked = () => {
        navigate(`/engines?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, offset - limit))}`);
        getPreviousPage();
    };

    const onSetPageClicked = (page: number) => {
        navigate(`/engines?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, (page - 1) * limit))}`);
        setPage(page);
    };

    return (
        <Page title={t('engines.title')} loading={loading}>
            <EnginesBrowser
                total={total}
                offset={offset}
                limit={limit}
                filterprops={filters}
                sorting={sorting}
                engines={engines || []}
                onSearchClicked={onSearchClicked}
                isLoading={loading}
                onEngineClicked={onEngineClicked}
                onPrevPageClicked={onPrevPageClicked}
                onNextPageClicked={onNextPageClicked}
                setSorting={onSortClicked}
                setPage={onSetPageClicked}
            />
        </Page>
    );
};

export default EnginesPage;
