import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISorting } from '../../../domain/hooks/useEngines';
import { useParts } from '../../../domain/hooks/useParts';
import { usePartTypes } from '../../../domain/hooks/usePartTypes';
import { Part } from '../../../domain/models/Part';
import PartsService, { IPartsFilter } from '../../../domain/services/PartsService';
import Page from '../../base/layout/Page';
import { getQueryString } from '../../utils/URLService';
import PartsBrowser from './PartsBrowser';
import { defaultDates, loadLastTimeFrame } from '../../../domain/utils/getDates';
import { SZ_ONLINE_URL } from '../../../domain/config/baseUrls';

interface IQueryPartsFilter {
    date_from?: string;
    date_to?: string;
    sort?: string;
    limit?: string;
    offset?: string;
    tid?: string[];
    parttype?: string[];
    pid?: string[];
    sorting?: ISorting;
}
export const dataObjectToQueryObject = (
    filters: IPartsFilter,
    sorting: ISorting | null,
    limit?: number,
    offset?: number
): IQueryPartsFilter => {
    return {
        date_from: filters.fromDate ? moment(filters.fromDate).format() : '',
        date_to: filters.toDate ? moment(filters.toDate).format() : '',
        sort: sorting ? `${sorting.column}:${sorting.direction}` : '',
        limit: limit?.toString(),
        offset: offset?.toString(),
        tid: filters.tids.map(String),
        parttype: filters.parttypes.map(String),
        pid: filters.pids.map(String),
    };
};

export async function export_parts(
    export_type: string,
    filters: IPartsFilter,
    offset: number,
    limit: number,
    sorting: ISorting | null
): Promise<void> {
    const partsService = new PartsService();
    limit = 5000;

    await partsService.exportParts(export_type, filters, offset, limit, sorting).then(async (response) => {
        // create a download link and download the file
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(await response.data);
        downloadLink.setAttribute('download', 'parts_export_' + new Date().valueOf());
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
        response.data as File;
    });
}
const PartsPage = ({ searchParams }: { searchParams: IQueryPartsFilter }) => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    /**
     * Converts the parameters from the URL of type String into an IPartsFilter Object
     * Returns the IPartsFilter Object.
     * @param searchParams from type IQueryPartsFilter
     */
    const queryObjectDataObject = (searchParams: IQueryPartsFilter): IPartsFilter => {
        return {
            fromDate: searchParams.date_from
                ? new Date(searchParams.date_from)
                : loadLastTimeFrame(defaultDates.timeFrame, true).fromDate,
            toDate: searchParams.date_to
                ? new Date(searchParams.date_to)
                : loadLastTimeFrame(defaultDates.timeFrame, true).toDate,
            tids: searchParams.tid ? searchParams.tid.map(String) : [],
            parttypes: searchParams.parttype ? searchParams.parttype.map(String) : [],
            pids: searchParams.pid ? searchParams.pid.map(String) : [],
        };
    };
    const queryToSortingObject = (searchParams: IQueryPartsFilter): ISorting | null => {
        const currentSorting: ISorting | null = searchParams.sort
            ? {
                  column: searchParams.sort[0].substring(0, searchParams.sort[0].indexOf(':')),
                  direction: searchParams.sort[0].substring(searchParams.sort[0].indexOf(':') + 1),
              }
            : null;
        return currentSorting;
    };
    const onPartClicked = (part: Part) => {
        if (['Rotor', 'Stator'].includes(part.part_type_name)) {
            window.open(`${SZ_ONLINE_URL}/part/${encodeURIComponent(part.tid)}`, '_blank');
        } else {
            navigate(`/products/${part.current_pid}/parts/${part.tid}`);
        }
    };
    const {
        parts,
        filters,
        offset,
        limit,
        total,
        loading,
        sorting,
        getNextPage,
        getPreviousPage,
        setFilters,
        setSorting,
        setPage,
    } = useParts(
        queryObjectDataObject(searchParams),
        queryToSortingObject(searchParams),
        searchParams.offset ? parseInt(searchParams.offset) : undefined,
        searchParams.limit ? parseInt(searchParams.limit) : undefined
    );

    const onSortClicked = (column: string) => {
        let newSorting = null;
        if (sorting === null || sorting.column !== column) {
            newSorting = { column: column, direction: 'asc' };
        } else {
            newSorting = { column: column, direction: sorting.direction === 'asc' ? 'desc' : 'asc' };
        }

        setSorting(newSorting);
        navigate(`/parts?${getQueryString(dataObjectToQueryObject(filters, newSorting, limit, offset))}`);
    };

    const onSearchClicked = (filters: IPartsFilter) => {
        setFilters(filters);
        navigate(`/parts?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, 0))}`);
        // The offset value has to be 0, otherwise it can happen that when a user is on page 4 and add a new filter, where there is no longer a page 4,,
        // it does not show any results, because the offset did not reset itself
    };
    const onNextPageClicked = () => {
        navigate(`/parts?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, offset + limit))}`);
        getNextPage();
    };

    const onPrevPageClicked = () => {
        navigate(`/parts?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, offset - limit))}`);
        getPreviousPage();
    };
    const onSetPageClicked = (page: number) => {
        navigate(`/parts?${getQueryString(dataObjectToQueryObject(filters, sorting, limit, (page - 1) * limit))}`);
        setPage(page);
    };

    const { partTypes } = usePartTypes();

    return (
        <Page title={t('parts.title')} loading={loading}>
            <PartsBrowser
                allPartTypes={partTypes}
                total={total}
                offset={offset}
                limit={limit}
                filters={filters}
                sorting={sorting}
                partsPage={parts || []}
                onSearchClicked={onSearchClicked}
                isLoading={loading}
                onPartClicked={onPartClicked}
                onPrevPageClicked={onPrevPageClicked}
                onNextPageClicked={onNextPageClicked}
                setSorting={onSortClicked}
                setPage={onSetPageClicked}
            />
        </Page>
    );
};

export default PartsPage;
