import React, { useState } from 'react';
import EolVerificationsBrowser from './EolVerificationsBrowser';

import { useNavigate } from 'react-router-dom';
import Page from '../../base/layout/Page';

import { useTranslation } from 'react-i18next';
import { useEolVerifications } from '../../../domain/hooks/useEolVerifications';
import { defaultFilters, IEolVerificationsFilter } from '../../../domain/services/EolVerificationsService';
import EOLVerification from '../../../domain/models/EOLVerification';
import ISorting from '../../../domain/interfaces/ISorting';
import { useEolErrors } from '../../../domain/hooks/useEolError';
import { EolVerificationExportService } from '../../../domain/services/EolVerificationsExportService';
import { loadLastTimeFrame } from '../../../domain/utils/getDates';

const getNewFiltersFromUrlParams = (
    filters: any,
    eolError?: number[] | null,
    fromDate?: Date | null,
    toDate?: Date | null,
    notPassed?: boolean | null,
    passed?: boolean | null,
    productType?: string[] | null,
    eolStations?: string[] | null,
    eolPids?: string[] | null
) => {
    const eolErrors = eolError ? eolError : filters.eolErrors;
    fromDate = fromDate ? fromDate : loadLastTimeFrame().fromDate;
    toDate = toDate ? toDate : loadLastTimeFrame().toDate;
    const eolNotPassed = notPassed !== null ? notPassed : filters.eolNotPassed;
    const eolPassed = passed !== null ? passed : filters.eolPassed;
    const motorType = productType !== null ? productType : filters.motorType;
    const stations = eolStations !== null ? eolStations : filters.stations;
    const pids = eolPids !== null ? eolPids : filters.pids;
    return { ...filters, eolErrors, fromDate, toDate, eolNotPassed, eolPassed, motorType, stations, pids };
};

const EolVerificationsPage = ({
    eolError = null,
    fromDate,
    toDate,
    notPassed = null,
    passed = null,
    productType = null,
    stations = null,
    pid_in = null,
    currentSorting = null,
    urlLimit = null,
    urlOffset = null,
}: {
    eolError?: number[] | null;
    fromDate?: Date;
    toDate?: Date;
    notPassed?: boolean | null;
    passed?: boolean | null;
    productType?: string[] | null;
    stations?: string[] | null;
    pid_in?: string[] | null;
    currentSorting?: ISorting | null;
    urlLimit?: number | null;
    urlOffset?: number | null;
}) => {
    const {
        eolVerificationsPage,
        filters,
        offset,
        limit,
        total,
        loading,
        sorting,
        getNextPage,
        getPreviousPage,
        setFilters,
        setSorting,
        setPage,
    } = useEolVerifications(
        getNewFiltersFromUrlParams(
            defaultFilters,
            eolError,
            fromDate,
            toDate,
            notPassed,
            passed,
            productType,
            stations,
            pid_in
        ),
        currentSorting,
        urlOffset ? urlOffset : 0,
        urlLimit ? urlLimit : 20
    );
    const { data } = useEolErrors();

    const [isLoadingDataToExport, setIsLoadingDataToExport] = useState<boolean>(false);

    const { t } = useTranslation();
    let navigate = useNavigate();

    const onNextPageClicked = () => {
        navigate(`/eolVerifications?${queryBuilder(filters, sorting, limit, offset + limit)}`);
        getNextPage();
    };

    const onPrevPageClicked = () => {
        navigate(`/eolVerifications?${queryBuilder(filters, sorting, limit, offset - limit)}`);
        getPreviousPage();
    };

    const onSetPageClicked = (page: number) => {
        navigate(`/eolVerifications?${queryBuilder(filters, sorting, limit, (page - 1) * limit)}`);
        setPage(page);
    };

    const queryBuilder = (filters: IEolVerificationsFilter, sort: ISorting | null, limit: number, offset: number) => {
        const query = new URLSearchParams();
        if (filters.fromDate) {
            query.append('fromDate', filters.fromDate.toISOString());
        }
        if (filters.toDate) {
            query.append('toDate', filters.toDate.toISOString());
        }
        filters.pids.forEach((pid) => query.append('pid_in', pid));
        filters.stations.forEach((station) => query.append('station', station)); // filter not yet implemented in the Backend
        filters.motorType.forEach((type) => query.append('productType', type));
        query.append('passed', filters.eolPassed ? '1' : '0');
        query.append('notPassed', filters.eolNotPassed ? '1' : '0');
        filters.eolErrors.forEach((eolError) => query.append('eolErrorCode', eolError.toString()));
        if (sort) query.append('sort', `${sort.column}:${sort.direction}`);
        if (limit) query.append('limit', limit.toString());
        if (offset) query.append('offset', offset.toString());

        return query;
    };

    const onSearchClicked = (filters: IEolVerificationsFilter) => {
        setFilters(filters);
        navigate(`/eolVerifications?${queryBuilder(filters, sorting, limit, offset)}`);
    };

    const onVerificationClicked = (verification: EOLVerification) => {
        navigate(`/products/${verification.pid}/verification/${verification.id}`);
    };

    const onSortClicked = (column: string) => {
        let newSorting;
        if (sorting === null || sorting.column !== column) {
            newSorting = { column: column, direction: 'asc' };
        } else {
            newSorting = { column: column, direction: sorting.direction === 'asc' ? 'desc' : 'asc' };
        }

        setSorting(newSorting);
        navigate(`/eolVerifications?${queryBuilder(filters, newSorting, limit, offset)}`);
    };

    return (
        <Page title={t('eolVerifications.title')} loading={loading}>
            <EolVerificationsBrowser
                allEolErrors={data}
                total={total}
                offset={offset}
                limit={limit}
                filters={filters}
                sorting={sorting}
                eolVerificationsPage={eolVerificationsPage || []}
                isLoading={loading}
                onEolVerificationClicked={onVerificationClicked}
                onSearchClicked={onSearchClicked}
                onPrevPageClicked={onPrevPageClicked}
                onNextPageClicked={onNextPageClicked}
                setSorting={onSortClicked}
                setPage={onSetPageClicked}
                isLoadingDataToExport={isLoadingDataToExport}
                setIsLoadingDataToExport={setIsLoadingDataToExport}
            />
        </Page>
    );
};
export async function exportVerifications(
    export_type: string,
    filters: IEolVerificationsFilter,
    offset: number,
    limit: number,
    sorting: ISorting | null
): Promise<void> {
    const verificationService = new EolVerificationExportService();
    await verificationService
        .exportVerifications(export_type, filters, limit, offset, sorting)
        .then(async (response) => {
            // create a download link and download the file
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(await response.data);
            downloadLink.setAttribute('download', 'verification_export_' + new Date().valueOf());
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
            response.data as File;
        });
}
export default EolVerificationsPage;
