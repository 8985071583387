import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    applyPolyfills,
    defineCustomElements,
} from '@group-ui/group-ui-react/node_modules/@group-ui/group-ui/dist/loader';
import '@group-ui/group-ui-react/node_modules/@group-ui/group-ui/dist/group-ui/assets/themes/vwag/vwag.css';
import i18nPromise from './i18n';
import { Auth } from 'aws-amplify';
import {
    COGNITO_APP_CLIENT_ID,
    COGNITO_OAUTH_DOMAIN,
    COGNITO_OAUTH_SIGN_IN_REDIRECT_URL,
    COGNITO_OAUTH_SIGN_OUT_REDIRECT_URL,
    COGNITO_REGION,
    COGNITO_USER_POOL_ID,
} from './domain/config/auth';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

applyPolyfills().then(() => {
    defineCustomElements();
});

Auth.configure({
    userPoolId: COGNITO_USER_POOL_ID,
    region: COGNITO_REGION,
    userPoolWebClientId: COGNITO_APP_CLIENT_ID,
    oauth: {
        domain: COGNITO_OAUTH_DOMAIN,
        scope: ['openid'],
        redirectSignIn: COGNITO_OAUTH_SIGN_IN_REDIRECT_URL,
        redirectSignOut: COGNITO_OAUTH_SIGN_OUT_REDIRECT_URL,
        responseType: 'code',
    },
    storage: window.sessionStorage,
});

const container = document.getElementById('root');
const root = createRoot(container!);

// We make sure that i18n is initialized before rendering the first  component
i18nPromise.then(() => {
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
