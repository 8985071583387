import React, { FC } from 'react';
import {
    iconDark,
    primary,
    iconBright,
    nodeSelectedColor,
    nodeDerviedFromLabelColor,
    grey800,
    nodeBorderColor,
    grey600,
} from '../../../components/style/colors';
import { Node } from './Node';
import { Handle, Node as FlowNode, NodeProps, Position } from 'react-flow-renderer';
import { Machine } from '../Machine';
import { Box } from '@mui/material';
import spacing from '../../../components/style/spacing';
import { Caption } from '../../../components/base/typography/textStyles';

interface Idata {
    machine: Machine;
    type: string;
    selected: boolean;
    derivedFromLabel: number;
    numberOfSections: number[];
    activeSectionNumber: number;
}

const getNodeStyle = (selected: boolean, derivedFromLabel: number, hasSections: boolean, type?: string) => {
    let style = {};
    style = {
        ...style,
        borderStyle: 'solid',
        borderColor: nodeBorderColor,
        borderWidth: '1px',
        boxSizing: 'border-box',
        padding: spacing(1),
        textAlign: 'center',
        width: '130px',
        background: '#fff',
    };
    if (selected) {
        style = {
            ...style,
            background: nodeSelectedColor,
            borderColor: nodeSelectedColor,
        };
    }
    if (type === 'partNode') {
        const circleSize: string = '60px';
        style = {
            ...style,
            background: primary,
            borderColor: primary,
            borderRadius: circleSize,
            height: circleSize,
            lineHeight: circleSize,
            padding: '0px',
            width: circleSize,
        };
    }
    if (type === 'stationNode') {
        style = {
            alignItems: 'center',
            background: grey600,
            borderColor: grey600,
            display: 'flex',
            height: '70px',
            justifyContent: 'center',
            width: '130px',
        };
    }
    if (type) {
        if (type === 'extraOutsidePart') {
            style = {
                ...style,
                borderStyle: 'dashed',
                height: '68px',
            };
        }
    }
    if (derivedFromLabel === 1) {
        style = {
            ...style,
            boxShadow: `inset 0px 0px 0px 2px ${nodeDerviedFromLabelColor}`,
            borderColor: nodeDerviedFromLabelColor,
        };
    }
    if (hasSections) {
        style = {
            ...style,
            borderRadius: '3px 0px 0px 3px',
        };
    }
    return style;
};

const getNodeLabel = (type: string, machine: Machine) => {
    if (type === 'extraOutsidePart' || type === 'partNode') {
        return (
            <Caption style={{ marginTop: spacing(2), color: type === 'partNode' ? 'white' : grey800 }}>
                {machine?.manufacturer}
            </Caption>
        );
    } else if (type === 'selectorNode') {
        return (
            <div style={{ textAlign: 'left' }}>
                <Caption style={{ marginBottom: spacing(0.5) }}>{machine?.workStep}</Caption>
                <Caption style={{ marginBottom: spacing(0.5) }}>{machine?.manufacturer}</Caption>
                <Caption style={{ marginBottom: spacing(0.5), fontWeight: 'bold', color: 'primary' }}>
                    {machine?.nr}
                </Caption>
            </div>
        );
    } else if (type === 'stationNode') {
        return (
            <div style={{ textAlign: 'center' }}>
                <Caption style={{ color: 'white', fontSize: '12px' }}>{machine?.manufacturer}</Caption>
            </div>
        );
    }
};

export const createReactFlowNode = (
    node: Node,
    xValue: number,
    yValue: number,
    selected: boolean,
    nodeType: string | null,
    derivedFromLabel: number,
    numberOfSections: number[],
    activeSectionNumber: number
): FlowNode<any> => {
    let typeOfNode = '';
    switch (nodeType) {
        case 'END':
        case 'START':
            typeOfNode = 'partNode';
            break;
        case 'STATION':
            typeOfNode = 'stationNode';
            break;
        case 'EXTRA':
            typeOfNode = 'extraOutsidePart';
            break;
        default:
            typeOfNode = 'selectorNode';
    }
    const reactFlowNode = {
        id: node.id,
        data: {
            machine: node.machine,
            type: typeOfNode,
            selected: selected,
            derivedFromLabel: derivedFromLabel,
            numberOfSections: numberOfSections,
            activeSectionNumber: activeSectionNumber,
        },
        type: 'customizedNode',
        position: { x: xValue, y: yValue },
        selectable: false,
        draggable: false,
    };
    return reactFlowNode;
};

const SectionIcon = ({ selected, numberOfSection }: { selected: boolean; numberOfSection: number }) => {
    const ballStyle = {
        background: selected ? iconDark : iconBright,
        borderRadius: `12px`,
        height: `24px`,
        width: `31px`,
        display: 'flex',
        justifyContent: 'center',
    };
    return (
        <div style={ballStyle}>
            <Caption noGutter={true} style={{ color: 'white', marginTop: '3px', fontWeight: 'bold' }}>
                {numberOfSection}
            </Caption>
        </div>
    );
};

export const CustomizedNode: FC<NodeProps> = ({ data }: { data: Idata }) => {
    if (data.numberOfSections.length >= 2) {
        return (
            <Box sx={{ display: 'flex' }}>
                <Box style={getNodeStyle(data.selected, data.derivedFromLabel, true, data.type)}>
                    <Handle
                        type="target"
                        position={Position.Top}
                        style={{ background: '#00000000', borderWidth: '0px' }} // to not show the targetPoint
                    />

                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1}>{getNodeLabel(data.type, data.machine)}</Box>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                gap: spacing(2),
                            }}
                        >
                            {data.numberOfSections.map((sectionNumber) => {
                                const shownSectionNumber = sectionNumber % 2 === 0 ? 2 : 1;
                                return (
                                    <SectionIcon
                                        selected={sectionNumber === data.activeSectionNumber}
                                        numberOfSection={shownSectionNumber}
                                    />
                                );
                            })}
                        </Box>
                    </Box>

                    <Handle
                        type="source"
                        position={Position.Bottom}
                        style={{ background: '#00000000', borderWidth: '0px' }} // to not show the sourcePoint
                    />
                </Box>
            </Box>
        );
    }

    return (
        <div style={getNodeStyle(data.selected, data.derivedFromLabel, false, data.type)}>
            <Handle
                type="target"
                position={Position.Top}
                style={{ background: '#00000000', borderWidth: '0px' }} // to not show the targetPoint
            />
            {getNodeLabel(data.type, data.machine)}
            <Handle
                type="source"
                position={Position.Bottom}
                style={{ background: '#00000000', borderWidth: '0px' }} // to not show the sourcePoint
            />
        </div>
    );
};
