import { FILE_EXPORT_URL } from '../config/baseUrls';
import ISorting from '../interfaces/ISorting';
import authorizedFetch from '../security/authorizedFetch';
import { IEolVerificationsFilter, queryBuilder } from './EolVerificationsService';

export interface ExportResponse {
    data: Blob;
}
export class EolVerificationExportService {
    async exportVerifications(
        exportType: string,
        filters: IEolVerificationsFilter,
        limit: number,
        offset: number,
        sorting: ISorting | null = null
    ): Promise<ExportResponse> {
        return await new Promise((resolve, reject) => {
            authorizedFetch(
                FILE_EXPORT_URL + '?export_type=' + exportType + '&' + queryBuilder(filters, limit, offset, sorting)
            )
                .then((response) => response.blob())
                .then((data) => {
                    resolve({
                        data: data,
                    });
                });
        });
    }
}
