import React, { useEffect, useRef, useState } from 'react';
import { EolVerificationsFiltersForm } from './EolVerificationsFiltersForm';
import { EolVerificationsTable } from './EolVerificationsTable/EolVerificationsTable';
import { GridCol, GridRow } from '../../base/layout/Grid';
import spacing from '../../style/spacing';
import { IEolVerificationsFilter } from '../../../domain/services/EolVerificationsService';
import EOLVerification from '../../../domain/models/EOLVerification';
import { Container } from '../../base/layout/Container';
import ISorting from '../../../domain/interfaces/ISorting';
import { EolErrorResponse } from '../../../domain/services/EolErrorService';
import { CSVLink } from 'react-csv';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { Button } from '../../base/buttons/Button';
import { Caption } from '../../base/typography/textStyles';
import { exportVerifications } from './index';
import { ArrowDownIcon, ArrowUpIcon } from '../../base/icons/icons';

export interface IProductsBrowserProps {
    eolVerificationsPage: EOLVerification[];
    total: number;
    offset: number;
    limit: number;
    filters: IEolVerificationsFilter;
    isLoading?: boolean;
    sorting: ISorting | null;
    allEolErrors: EolErrorResponse[];
    onEolVerificationClicked?: (verification: EOLVerification) => void;
    onNextPageClicked?: () => void;
    onPrevPageClicked?: () => void;
    onSearchClicked?: (filters: IEolVerificationsFilter) => void;
    setSorting: (column: string) => void;
    setPage: (page: number) => void;
    isLoadingDataToExport: boolean;
    setIsLoadingDataToExport: (loading: boolean) => void;
}

const EolVerificationsBrowser: React.FC<IProductsBrowserProps> = ({
    eolVerificationsPage,
    total,
    offset = 0,
    limit = 25,
    filters,
    isLoading = false,
    sorting,
    allEolErrors,
    onPrevPageClicked = () => {},
    onNextPageClicked = () => {},
    onEolVerificationClicked = () => {},
    onSearchClicked = () => {},
    setSorting = () => {},
    setPage = () => {},
    isLoadingDataToExport,
    setIsLoadingDataToExport = () => {},
}: IProductsBrowserProps) => {
    const filtersProps = {
        filters,
        isLoading,
        onSearchClicked: onSearchClicked,
    };
    const dataToExport: any = [];
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [loading, setLoading] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleExportFile = async (export_type: string) => {
        setAnchorEl(null);
        setLoading(true);
        await exportVerifications(export_type, filters, offset, (limit = 2500), sorting);
        setLoading(false);
    };

    const tableProps = {
        isLoading,
        rows: eolVerificationsPage,
        onNextPageClicked,
        onPrevPageClicked,
        total,
        limit,
        offset,
        sorting,
        onRowClick: (idx: number) => onEolVerificationClicked(eolVerificationsPage[idx]),
        setSorting,
        setPage,
    };
    const csvLink = useRef<any>();
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    const filename = `verifications-${timestamp}.csv`;

    useEffect(() => {
        if (dataToExport.length !== 0) {
            csvLink.current.link.click();
        }
        setIsLoadingDataToExport(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataToExport]);

    return (
        <>
            <GridRow>
                <GridCol xs={3} xl={2} noPadding>
                    <EolVerificationsFiltersForm allEolErrors={allEolErrors} {...filtersProps} />
                </GridCol>
                <GridCol
                    xs={9}
                    xl={10}
                    style={{ paddingLeft: spacing(4), display: 'flex', flexDirection: 'column', paddingBottom: '0px' }}
                >
                    <GridRow style={{ marginBottom: spacing(1) }}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            disableElevation
                            onClick={handleClick}
                        >
                            {loading ? (
                                <CircularProgress size="1.5rem" color="inherit" />
                            ) : (
                                <GridRow>
                                    Export
                                    {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                </GridRow>
                            )}
                        </Button>
                    </GridRow>
                    <Menu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={(e) => handleExportFile('csv')} disableRipple>
                            <Caption>CSV</Caption>
                        </MenuItem>
                        <MenuItem onClick={(e) => handleExportFile('json')} disableRipple>
                            <Caption>JSON</Caption>
                        </MenuItem>
                        <MenuItem onClick={(e) => handleExportFile('xml')} disableRipple>
                            <Caption>XML</Caption>
                        </MenuItem>
                    </Menu>
                    <CSVLink data={dataToExport} filename={filename} className="hidden" ref={csvLink} target="_blank" />

                    <Container style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <EolVerificationsTable {...tableProps} />
                    </Container>
                </GridCol>
            </GridRow>
        </>
    );
};

export default EolVerificationsBrowser;
