import React, { useState } from 'react';
import { IdentificationNumbersFiltersGroup } from '../../base/filtering/filtersGroups/IdentificationNumbersFiltersGroup';
import { QualityChecksFiltersGroup } from '../../base/filtering/filtersGroups/EOLErrorsFiltersGroup';
import { IEolVerificationsFilter } from '../../../domain/services/EolVerificationsService';
import { toTimeFrames } from '../../../domain/utils/timeFrames';
import DateIntervalFiltersGroup from '../../base/filtering/filtersGroups/DateIntervalFiltersGroup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FiltersArea from '../../base/filtering/filtersArea/FiltersArea';
import { EolErrorResponse } from '../../../domain/services/EolErrorService';
import { loadTimezoneFromAppSettings } from '../../base/utils';
import { loadLastTimeFrame, storeLastTimeFrame } from '../../../domain/utils/getDates';
import { defaultDates } from '../../../domain/utils/getDates';

export interface IEolVerificationsFiltersFormProps {
    allEolErrors: EolErrorResponse[];
    filters: IEolVerificationsFilter;
    isLoading?: boolean;
    onSearchFiltersChange?: (filters: IEolVerificationsFilter) => void;
    onSearchClicked?: (filters: IEolVerificationsFilter) => void;
}

export const EolVerificationsFiltersForm: React.FC<IEolVerificationsFiltersFormProps> = ({
    allEolErrors,
    filters,
    isLoading = false,
    onSearchFiltersChange = () => {},
    onSearchClicked = () => {},
}) => {
    const { t } = useTranslation();
    const [innerFilters, setInnerFilters] = useState({
        selectedTimeFrame: loadLastTimeFrame(defaultDates.timeFrame),
        ...filters,
    });

    const baseDate = moment.tz(new Date(), loadTimezoneFromAppSettings().value);
    const timeFrames = toTimeFrames(baseDate);

    const { pids, stations, motorType, eolPassed, eolNotPassed } = innerFilters;

    const onFieldValueChange = (fieldName: string) => {
        return (value: any) => {
            const newInnerFilters: any = { ...innerFilters };
            newInnerFilters[fieldName] = value;
            setInnerFilters(newInnerFilters);
            if (fieldName === 'selectedTimeFrame') {
                storeLastTimeFrame(value);
            }
            onSearchFiltersChange(getNewFilters(newInnerFilters));
        };
    };

    const getNewFilters = (innerFilters: any) => {
        const { selectedTimeFrame, fromDate, toDate, fromDateUTC, toDateUTC, ...other } = innerFilters;
        const newFilters = {
            fromDate: selectedTimeFrame.fromDate,
            toDate: selectedTimeFrame.toDate,
            ...other,
        };
        return newFilters;
    };

    const onSubmit = () => {
        onSearchClicked(getNewFilters(innerFilters));
    };

    const filtersChanged = JSON.stringify(getNewFilters(innerFilters)) !== JSON.stringify(filters);
    const isTimeFrameValid = getNewFilters(innerFilters).fromDate <= getNewFilters(innerFilters).toDate;

    return (
        <FiltersArea
            title="productsFilter.title"
            onSubmit={onSubmit}
            searchDisabled={isLoading || !filtersChanged || !isTimeFrameValid}
        >
            <DateIntervalFiltersGroup
                label={t('productsFilter.subTitle1')}
                key={'datetime'}
                onTimeFrameChange={onFieldValueChange('selectedTimeFrame')}
                timeFrame={innerFilters.selectedTimeFrame}
                timeFrames={timeFrames}
                hasCustomTime={true}
            />

            <IdentificationNumbersFiltersGroup
                productIds={pids}
                onPidsChange={onFieldValueChange('pids')}
                stations={stations}
                onStationsChange={onFieldValueChange('stations')}
                motorTypes={motorType}
                onMotorTypesChange={onFieldValueChange('motorType')}
            />

            <QualityChecksFiltersGroup
                allEolErrors={allEolErrors}
                eolErrors={innerFilters.eolErrors}
                eolPassed={eolPassed}
                eolNotPassed={eolNotPassed}
                onEolPassedChanged={onFieldValueChange('eolPassed')}
                onEolNotPassedChanged={onFieldValueChange('eolNotPassed')}
                onEolErrorChanged={onFieldValueChange('eolErrors')}
            />
        </FiltersArea>
    );
};
