import React from 'react';
import { Machine } from '../../../domain/models/Machine';
import { Container } from '../../base/layout/Container';
import { Body1, H3 } from '../../base/typography/textStyles';
import Translation from '../../base/translation/Translation';
import spacing from '../../style/spacing';
import { FlexContainer } from '../../base/layout/Flex';
import { GetMachineProductionStepsResponse } from '../../../domain/services/ProductionPlantsService';
import { Paginator } from '../../base/paginator/Paginator';
import { grey800 } from '../../style/colors';

interface IMachineInfoProps {
    machine: Machine;
    productionStepsPage: GetMachineProductionStepsResponse | null;
    onNextPageClicked: () => void;
    onPrevPageClicked: () => void;
    setPage: (page: number) => void;
}

export const MachineInfo: React.FC<IMachineInfoProps> = ({
    machine,
    productionStepsPage,
    onNextPageClicked,
    onPrevPageClicked,
    setPage,
}) => (
    <Container>
        <FlexContainer justifyContent="space-between" fullWidth={true}>
            <div>
                <FlexContainer alignItems="baseline">
                    <H3 noGutter={true}>
                        <Translation i18nKey="machineDetails.machineNumber" values={{ machineNumber: machine.nr }} />
                    </H3>
                    <Body1 noGutter={true} style={{ marginLeft: spacing(2), color: grey800 }}>
                        <Translation
                            i18nKey="machineDetails.machineAssociation"
                            values={{
                                workStep: machine.workStep.toUpperCase(),
                                manufacturer: machine.workStep.toUpperCase(),
                            }}
                        />
                    </Body1>

                    <Body1 noGutter={true} style={{ marginLeft: spacing(2), color: grey800 }}>
                        <Translation i18nKey="machineDetails.partsProcessedBy" values={{ machineNumber: machine.nr }} />
                    </Body1>
                </FlexContainer>
            </div>
            <div>
                {productionStepsPage && (
                    <Paginator
                        setPage={setPage}
                        total={productionStepsPage.total}
                        offset={productionStepsPage.offset}
                        limit={productionStepsPage.limit}
                        onNextPageClicked={onNextPageClicked}
                        onPrevPageClicked={onPrevPageClicked}
                    />
                )}
            </div>
        </FlexContainer>
    </Container>
);
