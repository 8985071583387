import { GroupuiTag } from '@group-ui/group-ui-react';
import IChildrenProps from '../IChildrenProps';
import IStyledProps from '../IStyledProps';
import { IDataTestProps } from '../IDataTestProps';

type TagSeverity = 'none' | 'warning' | 'danger' | 'success';
type TagVariant = 'primary' | 'secondary';

interface ITagProps extends IChildrenProps, IStyledProps, IDataTestProps {
    severity?: TagSeverity;
    variant?: TagVariant;
    square?: boolean;
}

const Tag = (props: ITagProps) => <GroupuiTag data-testid={props.dataTestid} {...props} />;

export default Tag;
