import React, { useState } from 'react';
import { IInputProps } from './Input';
import { GroupuiCheckbox } from '@group-ui/group-ui-react';
import { emptyCallback } from '../utils';
import IClickableProps from '../IClickableProps';
import spacing from '../../style/spacing';
import { IDataTestProps } from '../IDataTestProps';

export interface ICheckboxProps extends IInputProps, IClickableProps, IDataTestProps {
    /**
     * If true the checkbox is checked otherwise no
     */
    checked?: boolean;

    /**
     * disabled status of the checkbox
     */
    disabled?: boolean;

    /**
     * checked status of the checkbox<br> ❗❗ applicable only to: VWAG, Audi
     */
    indeterminate?: boolean;

    noGutter?: boolean;

    children?: any;
}

export const Checkbox: React.FC<ICheckboxProps> = ({
    onValueChange = emptyCallback,
    onClick = emptyCallback,
    checked = false,
    dataTestid = '',
    noGutter = false,
    ...props
}) => {
    const [innerChecked, setInnerChecked] = useState<boolean>(checked);

    return (
        <GroupuiCheckbox
            style={{ marginBottom: noGutter ? undefined : spacing(1) }}
            checked={innerChecked}
            data-testid={dataTestid}
            {...props}
            onClick={(evt) => {
                setInnerChecked(!innerChecked);
                onValueChange(!innerChecked);
                onClick(evt);
            }}
        />
    );
};
