import React from 'react';
import IStyledProps from '../IStyledProps';
import { GroupuiIcon } from '@group-ui/group-ui-react';

interface IIconProps extends IStyledProps {
    alt?: string;
    height?: number;
    width?: number;
}

export const ArrowBackIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="chevron-left-24" {...props} />;

export const ArrowDownIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="chevron-down-24" {...props} />;

export const ArrowForwardIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="chevron-right-24" {...props} />;

export const ArrowUpIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="chevron-up-24" {...props} />;

export const BatteryIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="battery-polarity-32" {...props} />;

export const CarIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="car-24" {...props} />;

export const CheckIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="check-32" {...props} />;

export const CheckListIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="checklist-24" {...props} />;

export const ClockIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="clock-24" {...props} />;

export const CloseIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="close-24" {...props} />;

export const CopyIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="copy-24" {...props} />;

export const CorrectIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="success-24" {...props} />;

export const ErrorIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="circle-close-24" {...props} />;

export const FilledInfoIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="info-filled-24" {...props} />;

export const GearsIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="gears-24" {...props} />;

export const HomeIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="home-24" {...props} />;

export const InfoIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="info-24" {...props} />;

export const LinkIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="link-24" {...props} />;

export const MenuIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="dots-vertical-filled-24" {...props} />;

export const SearchIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="search-24" {...props} />;

export const SettingsIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="settings-32" {...props} />;

export const UndercarriageIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="drivetrain-24" {...props} />;

export const WarningIcon: React.FC<IIconProps> = (props) => <GroupuiIcon name="error-24" {...props} />;
