import { hoverColorOpaque20 } from '../../style/colors';
import React, { useState } from 'react';
import { ButtonBase } from '@mui/material';
import { FlexContainer } from '../layout/Flex';
import spacing from '../../style/spacing';

export const TopBarButton = ({
    iconComponent,
    onClick = () => {},
    backgroundColor = hoverColorOpaque20,
}: {
    iconComponent: (props: any) => JSX.Element;
    onClick?: () => void;
    backgroundColor?: string;
}) => {
    const [isHover, setHover] = useState(false);

    // Invoke the component function
    const icon = iconComponent({
        color: 'white',
        size: 'large',
    });

    return (
        <ButtonBase
            onClick={onClick}
            style={{
                borderRadius: '30px',
                backgroundColor: isHover ? backgroundColor : 'transparent',
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <FlexContainer
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    cursor: 'pointer',
                    margin: spacing(0.5),
                }}
            >
                {icon}
            </FlexContainer>
        </ButtonBase>
    );
};
