import moment from 'moment';
import React from 'react';
import { Part } from '../../../domain/models/Part';
import { FlexContainer } from '../../base/layout/Flex';
import Translation from '../../base/translation/Translation';
import { Body2, H4 } from '../../base/typography/textStyles';
import spacing from '../../style/spacing';

export const PartNumber = ({ part }: { part: Part }) => {
    const marginBetweenPartsOfTID = spacing(2.5);
    return (
        <FlexContainer alignItems="baseline">
            <H4 noGutter style={{ marginRight: marginBetweenPartsOfTID }}>
                {`${part.part_type_name} TID: ${part.tid}`}
            </H4>
            <Body2 noGutter style={{ marginRight: marginBetweenPartsOfTID }}>
                <Translation i18nKey="partDetails.duns" values={{ duns: part.duns ? part.duns : '--' }} />
            </Body2>
            <Body2 noGutter style={{ marginRight: marginBetweenPartsOfTID }}>
                <Translation
                    i18nKey="partDetails.productionDate"
                    values={{ date: part.production_date ? moment(part.production_date).format('YYMMDD') : '--' }}
                />
            </Body2>
            <Body2 noGutter style={{ marginRight: marginBetweenPartsOfTID }}>
                <Translation
                    i18nKey="partDetails.runningNumber"
                    values={{ serial: part.serial ? part.serial : '--' }}
                />
            </Body2>
            <Body2 noGutter style={{ marginRight: marginBetweenPartsOfTID }}>
                <Translation
                    i18nKey="partDetails.lineInfo"
                    values={{
                        info: part.part_label && part?.part_label.length === 5 ? part.part_label.substring(0, 1) : '--',
                    }}
                />
            </Body2>
        </FlexContainer>
    );
};
