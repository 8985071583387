import { PartTypecol } from '../PartType';
import { Node } from './Node';

export class Edge {
    constructor(
        public id: string,
        public sourceNode: Node,
        public targetNode: Node,
        public partType: PartTypecol | undefined // public sourcePartType: PartType, // public targetPartType: PartType,
    ) {}

    static fromJson(json: any): Edge {
        return new Edge(
            json.id,
            json.sourceNode,
            json.targetNode,
            json.partType
            // json.targetPartType,
        );
    }
}
