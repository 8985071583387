import { useEffect, useState } from 'react';
import { Machine } from '../models/Machine';
import ProductionPlantsService from '../services/ProductionPlantsService';

export const useMachine = (plantId: string, machineNumber: string) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [machine, setMachine] = useState<Machine | null>(null);

    useEffect(() => {
        setLoading(true);
        const productionPlantsService = new ProductionPlantsService();
        productionPlantsService.getMachine(plantId, machineNumber).then((machine) => {
            setMachine(machine);
            setLoading(false);
        });
    }, [plantId, machineNumber, setLoading, setMachine]);

    return { loading, machine };
};
