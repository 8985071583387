import { Node as FlowNode, Edge as FlowEdge } from 'react-flow-renderer';
import { Edge } from '../models/graph/Edge';
import { Graph } from '../models/graph/Graph';
import { Node } from '../models/graph/Node';
import { createReactFlowNode } from '../models/graph/ReactFlowNode';
import { Part } from '../models/Part';
import { ProductionStep } from '../models/ProductionStep';
import { Grid } from '../models/graph/Grid';

const nodeCoordinatesToPosition = (grid: Grid, nodeType: string): { xPosition: number; yPosition: number } => {
    const { column, row } = grid;
    const startingY = 120;
    if (nodeType === 'START') {
        return { xPosition: 250, yPosition: startingY * row };
    }
    let xPosition = column * 170;
    let yPosition = row * startingY;
    return { xPosition, yPosition };
};

export const createFlowNodes = (graph: Graph, part: Part, partChildren: Part[]): Array<FlowNode<any>> => {
    let flowNodes: Array<FlowNode<any>> = [];
    let allTargetNodes: Node[] = graph.edges.map((edge) => edge.targetNode);
    let allSourceNodes: Node[] = graph.edges.map((edge) => edge.sourceNode);
    const allNodesDuplicated = allTargetNodes.concat(allSourceNodes);
    const allNodes = allNodesDuplicated.filter((node, i) => allNodesDuplicated.indexOf(node) === i);
    let allStepsFromPartAndChildren: ProductionStep[] = [
        ...partChildren.flatMap((child) => child.steps),
        ...part.steps,
    ];
    allNodes.forEach((node) => {
        const nodeType = node.machine?.workStep || '';
        const { xPosition, yPosition } = nodeCoordinatesToPosition(node.coordinates, nodeType);
        let isMachineUsed = allStepsFromPartAndChildren
            .filter((step) => step.machine.nr === node.machine?.nr)
            .some((filteredSteps) => filteredSteps.derivedFromLabel === 0);
        let derivedFromLabel = allStepsFromPartAndChildren
            .filter((step) => step.machine.nr === node.machine?.nr)
            .some((filteredSteps) => filteredSteps.derivedFromLabel === 1)
            ? 1
            : 0;
        let machineSection = Math.max.apply(
            null,
            allStepsFromPartAndChildren
                .filter((step) => step.machine.nr === node.machine?.nr)
                .map((step) => step.machineSection)
        );
        let flowNode = createReactFlowNode(
            node,
            xPosition,
            yPosition,
            isMachineUsed,
            node.machine?.workStep || '',
            derivedFromLabel,
            node.machine?.numberOfSections || [],
            machineSection
        );

        flowNodes.push(flowNode);
    });
    return flowNodes;
};

export const createFlowElements = (graph: Graph, part: Part, partChildren: Part[]): Array<FlowNode<any>> => {
    return [...createFlowNodes(graph, part, partChildren)];
};

export const createFlowEdges = (edges: Edge[]): Array<FlowEdge<any>> => {
    return edges.map((edge) => {
        return {
            id: edge.id,
            source: edge.sourceNode.id,
            target: edge.targetNode.id,
            type: 'smoothstep',
        };
    });
};
