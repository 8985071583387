import { Product } from '../models/Product';
import EOLVerification from '../models/EOLVerification';
import { PRODUCTS_URL } from '../config/baseUrls';
import authorizedFetch from '../security/authorizedFetch';
import { IEolVerificationResponse } from './EolVerificationsService';
import { ProductType } from '../models/ProductType';

export default class ProductsService {
    getProduct(productId: string): Promise<Product> {
        return new Promise((resolve, reject) => {
            authorizedFetch(`${PRODUCTS_URL}/${productId}`)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function (responseAsJson) {
                    resolve(Product.fromJson(responseAsJson));
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    getProductVerifications(productId: string): Promise<EOLVerification[]> {
        return new Promise((resolve, reject) => {
            authorizedFetch(`${PRODUCTS_URL}/${productId}/verifications`)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function (responseAsJson) {
                    resolve(
                        responseAsJson.verifications.map((verification: IEolVerificationResponse) =>
                            EOLVerification.fromJson(verification)
                        )
                    );
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    getProductTypes(): Promise<ProductType[]> {
        return new Promise((resolve, reject) => {
            authorizedFetch(`${PRODUCTS_URL}/types`)
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(function (responseAsJson) {
                    resolve(responseAsJson.product_types.map((p_type: any) => ProductType.fromJson(p_type)));
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }
}
