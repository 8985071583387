import React from 'react';
import { FlexContainer } from '../layout/Flex';
import { Body1 } from '../typography/textStyles';
import { useTranslation } from 'react-i18next';

interface IConfidentialityLevelIconProps {
    confidentialityLabelDe: string;
    confidentialityLabelEn: string;
}

const ConfidentialityLevelIcon: React.FC<IConfidentialityLevelIconProps> = ({
    confidentialityLabelDe,
    confidentialityLabelEn,
}) => (
    <FlexContainer
        style={{
            border: '1px solid white',
            boxSizing: 'border-box',
            borderRadius: '2px',
            width: '100px',
            height: '36px',
        }}
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
    >
        <Body1
            noGutter={true}
            style={{
                fontSize: '12px',
                lineHeight: '12px',
                color: 'white',
            }}
        >
            {confidentialityLabelEn.toUpperCase()}
        </Body1>
        <div
            style={{
                background: 'white',
                height: '1px',
                width: '24px',
                margin: '3px 0 3px 0',
            }}
        />
        <Body1
            style={{
                fontSize: '12px',
                lineHeight: '12px',
                color: 'white',
            }}
            noGutter={true}
        >
            {confidentialityLabelDe.toUpperCase()}
        </Body1>
    </FlexContainer>
);

export const ConfidentialIcon: React.FC<any> = () => {
    const { t } = useTranslation();
    return (
        <ConfidentialityLevelIcon
            confidentialityLabelDe={t('confidentialityLevels.confidential.de')}
            confidentialityLabelEn={t('confidentialityLevels.confidential.en')}
        />
    );
};

export const InternalIcon: React.FC<any> = () => {
    const { t } = useTranslation();
    return (
        <ConfidentialityLevelIcon
            confidentialityLabelDe={t('confidentialityLevels.internal.de')}
            confidentialityLabelEn={t('confidentialityLevels.internal.en')}
        />
    );
};

export const PublicIcon: React.FC<any> = () => {
    const { t } = useTranslation();
    return (
        <ConfidentialityLevelIcon
            confidentialityLabelDe={t('confidentialityLevels.public.de')}
            confidentialityLabelEn={t('confidentialityLevels.public.en')}
        />
    );
};

export const SecretIcon: React.FC<any> = () => {
    const { t } = useTranslation();
    return (
        <ConfidentialityLevelIcon
            confidentialityLabelDe={t('confidentialityLevels.secret.de')}
            confidentialityLabelEn={t('confidentialityLevels.secret.en')}
        />
    );
};
