import React from 'react';
import { Button } from '../../buttons/Button';
import Card from '../../Card';
import IChildrenProps from '../../IChildrenProps';
import { SearchIcon } from '../../icons/icons';
import { Container } from '../../layout/Container';
import { ITitleBarProps, TitleBar } from '../../layout/TitleBar';
import IStyledProps from '../../IStyledProps';

export interface IFiltersAreaProps extends IChildrenProps, ITitleBarProps, IStyledProps {
    onSubmit?: () => void;
    searchDisabled?: boolean;
    hasAction?: boolean;
}

const FiltersArea: React.FC<IFiltersAreaProps> = ({
    children,
    title,
    onSubmit,
    searchDisabled,
    hasAction = true,
    style,
}) => {
    return (
        <Card style={style} height="100%">
            <Container>
                <TitleBar
                    style={{ paddingBottom: '16px' }}
                    title={title}
                    actions={
                        hasAction ? (
                            <Button
                                iconOnly={true}
                                disabled={searchDisabled}
                                onClick={onSubmit}
                                dataTestid="filter-area-search-button"
                            >
                                <SearchIcon />
                            </Button>
                        ) : (
                            <></>
                        )
                    }
                />
                {children}
            </Container>
        </Card>
    );
};

export default FiltersArea;
