import packageJson from '../../../../package.json';
import { Box, Drawer } from '@mui/material';
import { GroupuiButton } from '@group-ui/group-ui-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HELP_WIKI_URL } from '../../../domain/config/baseUrls';
import AuthService from '../../../domain/security/AuthService';
import { UserContext } from '../../pages/login/WithAuthorization';
import { hoverColor, primary, primaryOpaque80 } from '../../style/colors';
import spacing from '../../style/spacing';
import { CloseIcon } from '../icons/icons';
import Translation from '../translation/Translation';
import { Caption, H3 } from '../typography/textStyles';
import { loadTimezoneFromAppSettings, toDateTimeString } from '../utils';
import { Divider } from '../layout/Divider';
import { FlexContainer } from '../layout/Flex';
import { TopBarButton } from './TopBarButton';
import TimezoneSelect from 'react-timezone-select';
// import * as moment from "moment-timezone";
import 'moment-timezone';

export interface ISettingsMenu {
    optionsMenuIsOpen: boolean;
    setOptionsMenuIsOpen: (arg0: boolean) => void;
}

const SettingsMenu: React.FC<ISettingsMenu> = ({ optionsMenuIsOpen, setOptionsMenuIsOpen }) => {
    const { i18n, t } = useTranslation();
    const authService = new AuthService();
    const changeLanguage = () => {
        i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en');
    };

    const logOut = () => {
        authService.oAuthSignOut();
    };

    const onHelpClicked = () => {
        window.open(HELP_WIKI_URL);
    };

    const closeIcon = () => <CloseIcon />;

    const iconClose = {
        id: 'close',
        onClick: () => setOptionsMenuIsOpen(!optionsMenuIsOpen),
        iconComponent: closeIcon,
        backgroundColor: hoverColor,
    };
    const [selectedTimezone, setSelectedTimezone] = useState('Europe/Berlin');

    useEffect(() => {
        // get the timezone
        const timezone = loadTimezoneFromAppSettings();
        // set the timezone in UI
        setSelectedTimezone(timezone);
        // store the timezone in the app settings
        storeLastTimezone(timezone);
    }, []);

    const storeLastTimezone = (newValue: any) => {
        try {
            localStorage.setItem(`settings-timezone`, JSON.stringify(newValue, null, 2));
        } catch (e) {
            localStorage.removeItem(`settings-timezone`);
        }
    };

    const onSelectChange = (evt: any) => {
        setSelectedTimezone(evt);
        storeLastTimezone(evt);
    };

    return (
        <Drawer
            anchor="right"
            PaperProps={{
                style: {
                    padding: '0px',
                    width: '300px',
                },
            }}
            open={optionsMenuIsOpen}
            onClose={() => setOptionsMenuIsOpen(false)}
        >
            <Box
                sx={{
                    width: 300,
                    height: '100%',
                    flexDirection: 'column',
                    display: 'flex',
                }}
            >
                <FlexContainer justifyContent="center" alignItems="center" style={{ height: '68px' }}>
                    <H3 style={{ flexGrow: 1, paddingLeft: spacing(2), color: primary }}>{t('global.settings')}</H3>

                    <TopBarButton {...iconClose} />
                </FlexContainer>
                <Divider />

                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{ padding: spacing(2) }}>
                    <Box>
                        <Caption style={{ color: primaryOpaque80, paddingBottom: spacing(5) }}>
                            {t('global.language')}
                        </Caption>
                    </Box>
                    <Box sx={{ flexDirection: 'row' }}>
                        <GroupuiButton
                            variant={i18n.language === 'de' ? 'primary' : 'secondary'}
                            onClick={changeLanguage}
                        >
                            {i18n.language === 'de' ? 'Deutsch' : 'German'}
                        </GroupuiButton>
                        <GroupuiButton
                            variant={i18n.language === 'en' ? 'primary' : 'secondary'}
                            onClick={changeLanguage}
                        >
                            {i18n.language === 'en' ? 'English' : 'Englisch'}
                        </GroupuiButton>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} style={{ padding: spacing(2) }}>
                    <Box>
                        <Caption style={{ color: primaryOpaque80, paddingBottom: spacing(5) }}>
                            {t('global.timezone')}
                        </Caption>
                    </Box>
                    <Box sx={{ flexDirection: 'row' }}>
                        <TimezoneSelect
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontFamily: 'VWAG TheSans',
                                }),
                            }}
                            value={selectedTimezone}
                            onChange={onSelectChange}
                            displayValue={'UTC'}
                        />
                    </Box>
                </Box>

                <Box sx={{ flexDirection: 'column' }} style={{ padding: spacing(2) }}>
                    <Box>
                        <Caption style={{ color: primaryOpaque80, paddingBottom: spacing(1) }}>
                            {t('global.general')}
                        </Caption>
                    </Box>
                    <GroupuiButton onClick={onHelpClicked} variant="secondary">
                        {t('global.help')}
                    </GroupuiButton>
                </Box>
                <Box style={{ padding: spacing(2) }}>
                    <Caption style={{ color: primaryOpaque80, paddingBottom: spacing(1) }}>
                        {t('global.account')}
                    </Caption>
                    <GroupuiButton onClick={logOut} fullwidth={true} variant="secondary">
                        {' '}
                        {t('global.logOut')}
                    </GroupuiButton>
                </Box>
                <Box style={{ padding: spacing(2) }}>
                    <Caption style={{ color: primaryOpaque80, paddingBottom: spacing(1) }}>
                        {t('global.version')} {packageJson.version}
                    </Caption>
                    {process.env.REACT_APP_HASH_FROM_PULL_REQUEST && (
                        <Caption style={{ color: primaryOpaque80, paddingBottom: spacing(1) }}>
                            {t('global.hash')} {process.env.REACT_APP_HASH_FROM_PULL_REQUEST}
                        </Caption>
                    )}
                </Box>

                <Box flexGrow={1}></Box>
                <Box style={{ paddingLeft: spacing(2), paddingTop: spacing(4) }}>
                    <UserContext.Consumer>
                        {(user) => (
                            <Caption style={{ color: primaryOpaque80 }}>
                                {user?.getAuthTime ? (
                                    <Translation
                                        i18nKey="global.lastSuccessLogin"
                                        values={{ date: toDateTimeString(user?.getAuthTime) }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Caption>
                        )}
                    </UserContext.Consumer>
                </Box>
            </Box>
        </Drawer>
    );
};
export default SettingsMenu;
