import { useEffect, useState } from 'react';
import StatsService, { IStatsFilters } from '../services/StatsService';
import { Stats } from '../models/stats/Stats';

export interface UseStatsState {
    stats: Stats | null;
    filters: IStatsFilters;
    loading: boolean;
    setFilters: (filters: IStatsFilters) => void;
}

/**
 * Custom hook
 * Downloads a page of products given the filters, offset and limit.
 * Returns handlers for navigating between the pages and updating the filters.
 * @param initialFilters
 * @param initialOffset
 * @param limit
 * @param contextId
 */
export const useStats: (initialFilters: IStatsFilters, contextId?: string) => UseStatsState = (
    initialFilters: IStatsFilters
) => {
    const [stats, setStats] = useState<Stats | null>(null);
    const [loading, setLoading] = useState(false);
    const [filters, innerSetFilters] = useState(initialFilters);

    useEffect(() => {
        setLoading(true);
        const statsService = new StatsService();
        statsService.getStats(filters).then((response) => {
            setStats(response);
            setLoading(false);
        });
    }, [filters]);

    const setFilters = (filters: IStatsFilters) => {
        innerSetFilters(filters);
    };

    return {
        stats,
        filters,
        loading,
        setFilters,
    };
};
